<template>
  <div class="container mx-auto p-2">
    <h2 class="text-2xl font-semibold mb-4">{{ title }}</h2>
    <div
      v-if="title == '2X Investor Profile Disclosures'"
      class="overflow-x-auto rounded-lg border border-gray-200 relative"
    >
      <table class="min-w-full bg-white" v-if="data.length > 0">
        <thead>
          <tr>
            <th
              v-for="header in disclosureHeaders"
              :key="header"
              :class="[
                'px-6 py-4 bg-gradient-to-b from-gray-100 to-gray-200 text-left text-xs font-bold text-gray-700 tracking-wider cursor-pointer hover:bg-gradient-to-b hover:from-gray-200 hover:to-gray-300 transition duration-150 ease-in-out border-b border-gray-300 whitespace-nowrap uppercase',
                {
                  'sticky left-0 z-10': header === '2XC Member',
                  'sticky right-0 z-10':
                    header === '2X Global Approved Investor Disclosures',
                },
              ]"
              @click="sortData(header)"
            >
              {{
                header === "2X Global Approved Investor Disclosures"
                  ? "2X APPROVAL"
                  : header
              }}
              <span
                v-if="header === '2X Global Approved Investor Disclosures'"
                class="ml-1"
              >
                <svg
                  v-if="sortOrder === 'ASC'"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 inline"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <svg
                  v-else-if="sortOrder === 'DESC'"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 inline"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, index) in tableDataset" :key="index">
            <td
              v-for="header in disclosureHeaders"
              :key="header"
              :class="[
                'px-6 py-4 text-sm text-gray-900 whitespace-nowrap cell-content',
                {
                  'sticky left-0 z-10 bg-white': header === '2XC Member',
                  'sticky right-0 z-10 bg-blue-50 p-2':
                    header === '2X Global Approved Investor Disclosures',
                },
              ]"
            >
              <template
                v-if="header == '2X Global Approved Investor Disclosures'"
              >
                <select
                  :value="row[header]"
                  @change="updateApprovalStatus(index, header, $event)"
                  class="form-select block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 !w-48"
                >
                  <option value="Approved">Approved</option>
                  <option value="Denied">Denied</option>
                </select>
              </template>
              <template v-else-if="header == 'Status Updated At'">
                {{
                  moment(row[header]).isValid()
                    ? moment(row[header]).format("DD MMMM, YYYY hh:mm A")
                    : ""
                }}
              </template>
              <template v-else-if="header == 'Updated By'">
                {{
                  row[header] && row[header].firstname && row[header].lastname
                    ? row[header].firstname + " " + row[header].lastname
                    : ""
                }}
              </template>
              <template v-else-if="header.includes('%')">
                {{ Math.round(row[header] * 100) }}
              </template>
              <template v-else>
                <div class="flex items-center">
                  <span class="truncate mr-2">{{
                    getCellContent(row[header])
                  }}</span>
                  <button
                    v-if="isContentLong(row[header])"
                    @click="openModal(row[header], header)"
                    class="text-blue-500 hover:text-blue-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-5 w-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                      />
                    </svg>
                  </button>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>No data available.</p>
      </div>
      <div
        v-if="showModal"
        class="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center"
      >
        <div class="bg-white p-6 rounded-lg max-w-2xl w-full">
          <h3 class="text-lg font-semibold mb-4">{{ modalTitle }}</h3>
          <div v-if="isValidUrl(selectedContent)" class="mb-4">
            <a
              :href="selectedContent"
              target="_blank"
              rel="noopener noreferrer"
              >{{ selectedContent }}</a
            >
          </div>
          <p v-else class="mb-4 whitespace-pre-wrap">{{ selectedContent }}</p>
          <button
            @click="closeModal"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 rounded-xl"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="title == '2X Deal Submissions'"
      class="overflow-x-auto rounded-lg border border-gray-200 relative"
    >
      <table class="min-w-full bg-white table-fixed" v-if="data.length > 0">
        <thead>
          <tr>
            <th
              v-for="(header, index) in dealsHeaders"
              :key="header"
              :class="[
                'px-6 py-4 bg-gradient-to-b from-gray-100 to-gray-200 text-left text-xs font-bold text-gray-700 tracking-wider cursor-pointer hover:bg-gradient-to-b hover:from-gray-200 hover:to-gray-300 transition duration-150 ease-in-out border-b border-gray-300 whitespace-nowrap uppercase',
                {
                  'sticky left-0 z-10': index === 0,
                  'sticky right-0 z-10': header === '2X Approval',
                },
              ]"
              @click="sortData(header)"
            >
              {{ header }}
              <span v-if="header === '2X Approval'" class="ml-1">
                <svg
                  v-if="sortOrder === 'ASC'"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 inline"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
                <svg
                  v-else-if="sortOrder === 'DESC'"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 inline"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(row, rowIndex) in tableDataset" :key="rowIndex">
            <td
              v-for="(header, colIndex) in dealsHeaders"
              :key="header"
              :class="[
                'px-6 py-4 text-sm text-gray-900 whitespace-nowrap cell-content',
                {
                  'sticky left-0 z-10 bg-white': colIndex === 0,
                  'sticky right-0 z-10 bg-blue-50 p-2':
                    header === '2X Approval',
                },
              ]"
            >
              <template v-if="header == '2X Approval'">
                <select
                  :value="row[header]"
                  @change="updateApprovalStatus(rowIndex, header, $event)"
                  class="form-select block mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 !w-48"
                >
                  <option value="" disabled>Review Pending</option>
                  <option value="Under Review">Under Review</option>
                  <option value="Approved">Approved</option>
                  <option value="Denied">Denied</option>
                </select>
              </template>
              <template v-else-if="header == 'Status Updated At'">
                {{
                  moment(row[header]).isValid()
                    ? moment(row[header]).format("DD MMMM, YYYY hh:mm A")
                    : ""
                }}
              </template>
              <template v-else-if="header == 'Updated By'">
                {{
                  row[header] && row[header].firstname && row[header].lastname
                    ? row[header].firstname + " " + row[header].lastname
                    : ""
                }}
              </template>
              <template v-else-if="header.includes('%')">
                {{
                  row[header] != "Undisclosed"
                    ? ((header == '% Women in Workforce' || header == '% Women in Senior Management' || header == '% Women on Board or IC'
                    || header == '% Women in Senior Management' || header == '% Women Founders/ Partners' || header == '% Women Ownership')
                    && (row[header].includes('threshold met') || row[header].includes('carry allocation')) ? row[header] : (row[header].includes('%') ? row[header] : Math.round(row[header] * 100)))
                    : ""
                }}
              </template>
              <template v-else-if="header == 'Shares owned by women' || header == '% Founding partners (women)'
              || header == 'Total women retail customers' || header == 'Total retail customers'">
                {{
                  Math.round(row[header] * 100)
                }}
              </template>
              <template v-else>
                <div class="flex items-center">
                  <span class="truncate mr-2">{{
                    getCellContent(row[header])
                  }}</span>
                  <button
                    v-if="isContentLong(row[header])"
                    @click="openModal(row[header], header)"
                    class="text-blue-500 hover:text-blue-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-5 w-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                      />
                    </svg>
                  </button>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>No data available.</p>
      </div>
      <div
        v-if="showModal"
        class="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center"
      >
        <div class="bg-white p-6 rounded-lg max-w-2xl w-full">
          <h3 class="text-lg font-semibold mb-4">{{ modalTitle }}</h3>
          <div v-if="isValidUrl(selectedContent)" class="mb-4">
            <a
              :href="selectedContent"
              target="_blank"
              rel="noopener noreferrer"
              >{{ selectedContent }}</a
            >
          </div>
          <p v-else class="mb-4 whitespace-pre-wrap">{{ selectedContent }}</p>
          <button
            @click="closeModal"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 rounded-xl"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <div class="text-sm text-gray-700">
      {{ Math.max((page - 1) * 10 + 1, 0) }} -
      {{ Math.min(page * 10, count) }} of {{ count }} Records
    </div>
  </div>
</template>

<script>
import env from "@/../env";
import Cookies from "@/helpers/cookies";
export default {
  name: "twoXDataTable",
  props: {
    data: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: "Data Table",
    },
    page: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      excludeColumns: ["_id", "id"],
      expectedFieldData: {
        // basic info
        "Investee/ Client": "investeeName",
        "2X Deal ID": "recordId",
        _id: "_id",
        // "Overarching indication of 2X Challenge eligibility/ qualification":
        //   "overarchingIndicationOf2XChallengeEligibilityQualification",
        "URL Link to 2X Challenge Assessment results":
          "twoXChallengeResultsUrl",
        // "Disclosure date": "disclosureDate",
        "2X Challenge member": "which2XChallengeMemberAreYou",
        "Investee Type": "investeeType",
        // "Sovereign or nonsovereign": "entityType",
        "Institution (Investor)": "nameOfInstitution",
        "Investment Identifier Number": "uniqueIdentifier",
        "Project Title": "projectTitle",
        "Project description": "projectDescription",
        // Region: "region",
        "Primary country of operation": "primaryCountry",
        "Other countries of operation": "countryOfOperations",
        // Sector: "sector",
        "Sector of Activity": "sectorOfActivity",
        // "Other sector": "otherSectorOfActivity",
        "Sub-Sector": "subSector",
        "Commitment date": "commitmentDate",
        "Investment instrument": "wayOfInvestment",
        "Investment value (US $)": "totalInvestmentCommitted",
        // "Total investment / Project costs (US $)": "totalInvestmentCost",
        "2X Challenge Co-investment": "isCoInvestorPresentInThisDeal",
        "2X Challenge Co-investor": "coInvestorInstitution",
        "2X Challenge Co-investor Lead": "leadInvestmentInstitution",
        "Opt-out of public disclosure": "optOutOfPublicDisclosure",
        "Justification for opt-out": "justification",
        "Climate Finance": "dealConsideredClimateFinanceByOrganization",
        // "Investment Currency": "investmentCurrency",
        // "Domicile of Investee": "domicileOfInvestee",
        // "Amount Disbursed to Date": "amountDisbursedToDate",
        // "Funding Source": "fundingSource",
        "OECD Dac Gender Marker Alignment":
          "doesInvestmentIncludeExplicitEffortToStrengthenGenderEquality",
        // "Repeated Investment": "isRepeatedInvestment",
        // "Concessional Investment": "concessionalInvestment",
        // "Reason for Concessional Finance": "reasonForconcessionalFinance",
        // "Private Finance Mobilised": "privateFinanceMobilised",
        // "DFI Finance Mobilised": "dfiFinanceMobilised",
        // "Share of Equity": "shareOfEquity",
        // "Interest Rate": "interestRate",
        // "Loan Tenor": "loanTenor",
        // "Length of Guarantee": "lengthOfGuarantee",
        // "Development Additionality": "doesIdentifyDevelopmentAdditionality",
        // "Financial Additionality": "doesIdentifyFinancialAdditionality",
        // "Plan to Conduct Evaluation": "doesPlanToConductEvaluation",

        // deal contact information
        // "Contact for Questions": "contactForQuestions",
        "Primary contact name of the investee/ client":
          "primaryContactNameOfInvestee",
        "Investee Email": "investeeEmail",
        // "Confirm the name of the primary contact for this deal.":
        //   "nameOfPrimaryContactForDeal",
        // "Confirm the primary email address for the main point of contact for this deal.":
        //   "emailOfPrimaryContactForDeal",
        // "Please provide information on the document you used for the investee to be qualified under the 2X Challenge.":
        //   "documentUsedForInvesteeToBeQualifiedUnder2XChallenge",
        // "What other type of document did you use to qualify the investee?":
        //   "otherDocumentUsedForInvesteeToBeQualifiedUnder2XChallenge",

        // disclosures
        "Documentation of investee clearance for public disclosure":
          "disclosureClearanceDocument",
        // "Publicly disclose data on website": "doesDiscloseData",
        // "Website for disclosure": "disclosureDataLocation",
        // "Disclose results indicators for this project":
        //   "doesDiscloseResultsIndicators",
        // "Disclose metrics, definitions and methodologies":
        //   "doesDiscloseMetricsDefinitionsMethodologies",
        // "Disclose baseline data for identified indicators":
        //   "doesDiscloseBaselineData",
        // "Disclose target value for the indicators": "doesDiscloseTargetValue",
        // "Disclose actual/current value for the indicators":
        //   "doesDiscloseActualValue",
        // "Baseline data": "discloseBaselineData",
        // "Year of data collection": "lastUpdateDate",
        // "Information on current values/ targets": "optionalCommentField",
        // "Optional comment field": "optionalCommentField",
        "Gender/sex disaggregated data collection": "doesCollectAndAnalyzeData",
        "GA Strategic Action": "gAStrategicAction",
        // "Other GA Strategic Action": "otherGAStrategicAction",
        "GA Management Systems": "gAManagementSystems",
        // "Other GA Management Systems": "otherGAManagementSystems",
        "GA Data": "gAData",

        "Workplace-focused diversity efforts": "diversityEffortsToIncreaseWomenInWorkforce",
        "Equal care efforts in suppliers": "supplyChainWorkerEqualCareEfforts",
        "GBVH  efforts in suppliers": "supplyChainWorkerViolenceEfforts",
        "P&S targeting women": "serviceTargetedForWomenCustomers",
        "P&S enhancing wellbeing/gender equity": "genderSpecificServicesForWomen",
        "Integrate gender considerations in P&S lifecycle": "genderSpecificConsiderationEffortsInLifeCycle",

        // 2X criteria - E&S
        "E&S Criteria Met": "eSCriteriaMet",
        // "E&S risk category": "environmentalAndSocialRiskCategory",
        "Qualifying Criteria": "qualifyingCriteria",
        // "Summary of ES Risks": "summaryOfESRisks",
        // "ES Standards Triggered": "areESStandardsTriggered",
        "ESG Practices Applied": "esgPracticesAppliedToDealTransaction",

        // 2X criteria - commitment
        "Commitment Criteria": "commitmentCriteria",
        "Time bound commitment target year": "timeBoundCommitmentTargetYear",
        // "Commitment Approval Date": "commitmentApprovalDate",

        // 2X criteria - entrepreneurship
        "% Women Ownership": "percentageWomenOwnership",
        "% Women Founders/ Partners": "percentageWomenFoundersPartners",
        "No of founding partners": ["activeTotalFundsFounders", "activeTotalCompanyFounders"],
        "No of women founding partners": ["activeTotalWomenFundsFounders", "activeTotalWomenCompanyFounders"],
        "% Founders with active role (women)":
          "percentageFoundersWithActiveRoleWomen",
        "% Founding partners (women)": "PercentageFoundingPartnersWomen",
        "Shares owned by women": "sharesOwnedByWomen",

        // 2X criteria - leadership
        "% Women in Senior Management": "percentageWomenSeniorManagement",
        "Total no of women at each senior management level":
          "totalWomenSeniorManagementNumber",
        "% Senior managers (women)": "percentageSeniorManagersWomen",
        "Total no of people at each senior management level":
          "totalSeniorManagementNumber",
        "% Managing partners and partners (women)":
          "percentageManagingPartnersAndPartnersWomen",
        "Total no of managers at each level": "totalManagers",
        "Total no of women managers at each level": "totalWomenManagers",
        "% Women on Board or IC": "percentageWomenOnBoardOrIC",
        "% Board members (women)": "percentageBoardMembersWomen",
        "Total no of Board members": "totalBoardMembers",
        "Total no of women Board members": "totalWomenBoardMembers",
        "% IC members (women)": "percentageICMembersWomen",
        "Total no of IC members": "totalInvestmentCommitteeMembers",
        "Total no of women IC members": "totalWomanInvestmentCommitteeMembers",

        // 2X criteria - employment
        "% Women in Workforce": "percentageWomenInWorkforce",
        "% of FTEs (women)": "percentageFTEsWomen",
        "Total no of FTEs": "totalDirectFTE",
        "Total no of women FTEs": "totalDirectWomenFTE",
        "% of 3rd party workers/ contractors (women)":
          "percentageOfThirdPartyWorkersContractorsWomen",
        "Total no of 3rd party workers": "totalContractedFTE",
        "Total no of women 3rd party workers": "totalContractedWomenFTE",
        "Employment Quality Indicator": "employmentQualityIndicator",
        "Equal pay efforts for workplace-focused efforts": "equalPayEfforts",
        "Equal care efforts for workplace-focused efforts": "equalCareEfforts",
        "Health and wellbeing": "healthEfforts",
        "GBVH efforts": "advancedEfforts",
        "Other Employment efforts for workplace-focused efforts":
          "otherEffortsEmployment",

        // 2X criteria - supply chain
        "Commitment to women in supply chain":
          "explicitCommitmentToSupportWomen",
        "Supply-chain quality efforts": "supplyChainQualityEfforts",
        "Increase women suppliers":
          "diversityEffortsToIncreaseWomenSuppliers",
        "Increase suppliers with gender equity focus":
          "diversityEffortsToIncreaseGenderEquitySuppliers",
        "Increase suppliers with worker diversity efforts":
          "supplyChainWorkerDiversityEfforts",
        "Equal pay efforts in suppliers":
          "supplyChainWorkerGenderPayGapEfforts",
        "Health and wellbeing efforts in suppliers":
          "supplyChainWorkerHealthEfforts",
        "Other supply chain-focused efforts": "otherSupplyChainEfforts",

        // 2X criteria - products and services
        "Gender Products or Services": "genderSpecificServicesForWomen",
        "Products or Services Quality Indicator":
          "productsOrServicesQualityIndicator",
        "Gender considerations in P&S safety":
          "isGenderSpecificConsiderationsInSafetyEfforts",
        "Other product or service": "otherProductOrService",
        "Commitment to women customers (FIs)": "commitmentToWomenCustomers",
        "% customers (women) meets 2X threshold":
          "percentageOfCustomersMeetingTwoXThreshold",
        // "Total retail customers": "totalWomenRetailCustomers",
        // "Total women retail customers": "totalWomenRetailCustomers",
        "Gender Lens Investment Thesis": "genderLensInvestmentThesis",

        // 2X criteria - portfolio
        "Funds portfolio committment":
          "investeePortfolioCommitment",
        "Investee Portfolio Commitment (FIs)": "investeePortfolioCommitmentFIs",
        // "No of portfolio companies": "totalPortfolioCompaniesInFund",
        // "Portfolio commitment (FIs) from Excel": "portfolioDiversityCommitment",

        // submission status
        "Status Updated At": "lastModified",
        "Updated By": "processedBy",
        "Last Modified": "lastModified",
        "Submitted by Form": "user",
        "Submission date": "createdAt",
        Created: "createdTime",
        "2X Approval": "2XApproval",
      },
      expectedDisclosureData: {
        "2XC Member": "member2xc",
        "Primary Contact Title": "primaryContactTitle",
        "Organization Type": "organizationType",
        "E&S Policies / Standards Disclosed": "esPolicyStandardsDisclosed",
        "Link to the E&S Policies / Standards": "linkToESPolicyStandards",
        "Exception List Disclosed": "exceptionListDisclosed",
        "Link to Exception List": "linkToExceptionList",
        "Gender Impact Method Disclosed": "genderImpactMethodDisclosed",
        "Link to Gender Impact Method": "linkToGenderImpactMethod",
        "Gender Indicators Disclosed": "genderIndicatorsDisclosed",
        "Link to Gender Indicators": "linkToGenderIndicators",
        "Gender or Gender Lens Investment Strategy Disclosed":
          "genderOrGenderLensInvestmentStrategyDisclosed",
        "Link to Gender or Gender Lens Investment Strategy":
          "linkToGenderOrGenderLensInvestmentStrategy",
        "If yes, please provide a relevant link.":
          "ifYesPleaseProvideARelevantLink",
        "Publish deals online publicly": "publishDealsOnlinePublicly",
        "Link to Published Deals": "linkToPublishedDeals",
        _id: "_id",
        "Status Updated At": "lastModifiedAt",
        "Updated By": "processedBy",
        "2X Global Approved Investor Disclosures":
          "twoXGlobalApprovedInvestorDisclosures",
      },
      showModal: false,
      selectedContent: "",
      modalTitle: "",
      sortOrder: "ASC",
    };
  },
  computed: {
    allHeaders() {
      const allKeys = new Set();
      this.data.forEach((item) => {
        Object.keys(item).forEach((key) => allKeys.add(key));
      });
      return Array.from(allKeys);
    },
    disclosureHeaders() {
      return Object.keys(this.expectedDisclosureData).filter(
        (header) => header !== "_id"
      );
    },
    dealsHeaders() {
      return Object.keys(this.expectedFieldData).filter(
        (header) => header !== "_id"
      );
    },
    tableDataset() {
      return this.createOrderedTableDataset(
        this.data,
        this.title === "2X Deal Submissions"
          ? this.expectedFieldData
          : this.expectedDisclosureData
      );
    },
  },
  methods: {
    isDate(value) {
      return (
        (value instanceof Date && !isNaN(value)) ||
        (typeof value === "string" && !isNaN(Date.parse(value)))
      );
    },
    formatDate(value) {
      const date = new Date(value);
      if (isNaN(date)) return "Invalid Date";
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleDateString(undefined, options);
    },
    formatCellValue(value) {
      if (value === null || value === undefined || value === "") return "";
      if (Array.isArray(value)) return value.join(", ");
      if (typeof value === "object") return JSON.stringify(value);
      if (this.isDate(value)) return this.formatDate(value);
      return String(value);
    },
    generateUniqueKey(item) {
      return Object.values(item).join("_");
    },
    createOrderedTableDataset(backendData, expectedFields) {
      return backendData.map((item) => {
        const entityType = item.investeeType;
        const rowData = {};
        Object.entries(expectedFields).forEach(
          ([displayName, backendField]) => {
            if(displayName == 'No of founding partners' || displayName == 'No of women founding partners') {
              backendField = entityType == 'Fund' ? backendField[0] : backendField[1]
            }
            rowData[displayName] = item[backendField] || "";
          }
        );
        return rowData;
      });
    },
    async updateApprovalStatus(rowIndex, header, event) {
      const newValue = event.target.value;
      const _id = this.tableDataset[rowIndex]["_id"];
      const payload = {
        id: _id,
        status: newValue,
      };
      this.tableDataset[rowIndex][header] = newValue;

      let urlPath;
      if (this.title === "2X Deal Submissions") {
        urlPath = "/twox-global/status/Deals";
      } else if (this.title === "2X Investor Profile Disclosures") {
        urlPath = "/twox-global/status/Investor-disclosure";
      }

      if (urlPath) {
        try {
          const response = await fetch(`${env.apiUrl}${urlPath}`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
          const data = await response.json();
          if (response.ok) {
            this.$swal.fire({
              icon: "success",
              text: data.data,
              showConfirmButton: true,
            });
          }
          if (!response.ok) {
            throw new Error(data.message || "Something went wrong!");
          }
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            text: error.message,
            showConfirmButton: true,
          });
          console.error("Error fetching data:", error);
        }
      }
    },
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    },
    // openModal(description) {
    //   this.selectedDescription = description;
    //   this.showModal = true;
    // },
    getCellContent(content) {
      if (Array.isArray(content)) {
        return content.join(", ");
      }
      return content;
    },
    isContentLong(content) {
      const testElement = document.createElement("span");
      testElement.style.visibility = "hidden";
      testElement.style.whiteSpace = "nowrap";
      testElement.innerText = this.getCellContent(content);
      document.body.appendChild(testElement);
      const width = testElement.offsetWidth;
      document.body.removeChild(testElement);
      return width > 500;
    },
    openModal(content, header) {
      this.selectedContent = this.getCellContent(content);
      this.modalTitle = header;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedContent = "";
      this.modalTitle = "";
    },
    sortData(header) {
      if (
        header === "2X Approval" ||
        header === "2X Global Approved Investor Disclosures"
      ) {
        this.sortOrder = this.sortOrder === "ASC" ? "DESC" : "ASC";
        this.$emit("sort-data", this.sortOrder);
      }
    },
  },
};
</script>

<style scoped>
td {
  border: 1px solid #ddd;
}
.cell-content {
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
}
.sticky {
  position: sticky;
  right: 0;
}
.overflow-x-auto {
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #cbd5e0;
}

.overflow-x-auto::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #cbd5e0;
  border-radius: 4px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
  border: 2px solid #cbd5e0;
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background-color: #2d3748;
}

.sticky.left-0.bg-white {
  border: none !important;
}

.sticky.left-0.bg-white::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background-color: #e0e0e0; /* Matches border-gray-300 */
  z-index: 20;
}
</style>
