<template>
  <div class="popup-wrapper" v-if="showPopup">
    <div class="popup relative" style="max-width: 32rem; max-height: 40rem; overflow-y: auto; top: 10%; height: fit-content;">
      <div class="popup-title text-with-icon h-full">
        <div class="text-center">
          <img src="@/assets/images/check.svg" alt="done" class="h-[3rem]">
        </div>
        <div class="text-[#201747]" v-html="text"></div>
        <div class="text-center">
          <button class="mt-5 mb-5" v-if="surveyId == 'investor-profile'" @click="closePopup">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    surveyId: {
      type: [String, Boolean, null],
      default: "",
    }
  },
  data() {
    return {
      showPopup: true
    }
  },
  methods: {
    closePopup() {
      this.showPopup = false;
      this.$emit("surveyCompleteFromPopup");
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  background-color: rgba(32, 23, 71, 0.8);
  z-index: 99;
}

.popup {
  background-color: white;
  border-radius: 5px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  color: white;
}

.popup-title {
  /* display: flex; */
  align-items: center;
  /* gap: 10px; */
}

.popup-title {
  font-size: 1rem;
}

.popup-title svg {
  fill: rgb(63, 126, 68);
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: rgb(63, 126, 68);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}
</style>