const ORGANIZATION_TYPES = [
  {
    name: "Investor making investments",
    code: "IVM",
  },
  {
    name: "Financial institution lending to businesses",
    code: "FILB"
  },
  {
    name: "Enterprise Support Organization",
    code: "ESO"
  },
  {
    name: "Professional in international development",
    code: "PID",
  },
  {
    name: "Humanitarian actor",
    code: "HA",
  },
  {
    name: "Associate of company producing goods services not targeting international development",
    code: "ACPGSNTID",
  },
  {
    name: "Academic / researcher",
    code: "AR",
  },
  {
    name: "Not sure / Something else",
    code: "BASIC",
  },

];

export { ORGANIZATION_TYPES };
