<template>
  <div>
    <div class="pt-8 pb-4">
      <h1 class="text-3xl text-[#21191D] font-bold">Insights at a Glance</h1>
      <div class="flex justify-between items-center pt-2">
        <div class="text-2xl text-[#21191D] font-bold">
          Number of companies invested in: {{ companies.length }}
        </div>
        <div class="flex items-center"></div>
      </div>
      <div class="flex justify-between items-center">
        <div class="flex gap-4">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Search organization"
            class="focus:outline-none w-72 p-4 rounded bg-white text-sm font-medium leading-none text-[#21191D] border border-[#E1E3E9] rounded-lg"
          />
          <select
            v-model="selectedDocumentType"
            class="focus:outline-none cursor-pointer w-72 p-4 rounded bg-white text-sm font-medium leading-none text-[#21191D] border border-[#E1E3E9] rounded-lg"
          >
            <option value="">Filter by header</option>
            <option value="ESG">ESG</option>
            <option value="GBVH">GBVH</option>
            <option value="Leadership">Leadership</option>
          </select>
          <button
            @click="clearFilters"
            class="px-6 py-3 text-[#21191D] border border-[#E1E3E9] rounded-lg hover:bg-gray-50"
          >
            Clear Filters
          </button>
        </div>
        <button
          class="focus:outline-none mx-1 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg"
          @click="$emit('invite-company')"
        >
          Invite new investee
        </button>
        <button
          v-if="selectedPortfolioType === 'Pipeline'"
          class="focus:outline-none mx-1 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg"
        >
          Add a new deal
        </button>
      </div>
    </div>
    <div class="border-2 rounded-large">
      <table class="min-w-full divide-y divide-[#E1E3E9]">
        <thead class="bg-[#F4F6F9] h-14">
          <tr class="divide-x divide-gray-200">
            <th
              v-for="column in columns"
              :key="column.accessor"
              class="px-4 py-3.5 text-left text-sm font-semibold leading-6 text-[#2C2E35]"
              :style="{
                maxWidth: `${20}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }"
            >
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody
          class="divide-y divide-[#E1E3E9] bg-white"
          v-for="(record, index) in paginatedRecords"
          :key="record._id"
        >
          <tr class="divide-x divide-[#E1E3E9]">
            <td
              v-for="column in columns"
              :key="column.accessor"
              class="p-4 text-base text-[#2C2E35] font-normal"
              :style="{
                maxWidth: `${120}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }"
            >
              <span v-if="column.accessor !== 'companies'">
                {{ record[column.accessor] }}
              </span>

              <router-link
                v-if="column.accessor === 'companies'"
                :to="`/imm/dashboard?id=${record._id}&teamId=${record.teamId}`"
                target="_blank"
              >
                <button
                  class="focus:outline-none mx-2 my-2 transition duration-150 ease-in-out font-bold rounded text-white px-6 py-3 text-base rounded-lg"
                  :class="
                    record.userId
                      ? 'bg-[#191B45] hover:opacity-75'
                      : 'bg-gray-400 cursor-not-allowed'
                  "
                  :disabled="!record.userId"
                >
                  {{ record[column.accessor] }}
                </button>
              </router-link>
            </td>
          </tr>
          <tr
            v-if="isSelected(record.id) && record.certificationStatus"
            :key="`details-${record.id}-${index}`"
            class="bg-[#F4F6F9] px-10 py-4 h-40"
          >
            <td :colspan="columns.length + 1">
              <div class="flex justify-start items-center">
                <div class="w-full pl-10 flex items-center">
                  <div
                    class="bg-white p-3 rounded-lg h-36 flex items-center w-full max-w-md flex-col"
                  >
                    <div class="flex items-center">
                      <div class="flex items-center">
                        <img class="w-11 mx-auto" />
                        <div class="ml-2">
                          <p class="font-semibold whitespace-nowrap">
                            {{ record.certificationStatus }}
                          </p>
                          <p class="font-normal text-[#717686]">
                            {{ record.certificationDate }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="bg-[#E8AD17] px-3 py-2 font-bold text-white rounded-[24px] w-full max-w-[195px] flex items-center ml-16"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5 text-white mr-1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                        {{ record.validationStatus }}
                      </div>
                    </div>
                    <div
                      class="border-2 border-[#191B45] px-3 py-2 font-bold text-[#191B45] rounded-lg w-full text-center mt-4"
                    >
                      {{ record.certificationRequest }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- add pagination controls after table -->
      <div
        class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
      >
        <div class="flex flex-1 justify-between sm:hidden">
          <!-- mobile pagination -->
          <button
            @click="handlePageChange(currentPage - 1)"
            :disabled="currentPage === 1"
            class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
          >
            Previous
          </button>
          <button
            @click="handlePageChange(currentPage + 1)"
            :disabled="currentPage === totalPages"
            class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
          >
            Next
          </button>
        </div>
        <div
          class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between"
        >
          <div>
            <p class="text-sm text-gray-700">
              Showing <span class="font-medium">{{ startIndex + 1 }}</span> to
              <span class="font-medium">{{ endIndex }}</span> of
              <span class="font-medium">{{ totalItems }}</span> results
            </p>
          </div>
          <div>
            <nav
              class="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <button
                @click="handlePageChange(currentPage - 1)"
                :disabled="currentPage === 1"
                class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              <button
                v-for="page in displayedPages"
                :key="page"
                @click="handlePageChange(page)"
                :class="[
                  page === currentPage
                    ? 'bg-[#191B45] text-white'
                    : 'text-gray-900',
                  'relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-700 focus:z-20 focus:outline-offset-0',
                ]"
              >
                {{ page }}
              </button>
              <button
                @click="handlePageChange(currentPage + 1)"
                :disabled="currentPage === totalPages"
                class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companies: {
      type: Array,
      required: true,
    },
  },
  emits: ["invite-company"],
  data() {
    return {
      columns: [
        { accessor: "companies", label: "Company Name" },
        { accessor: "sector", label: "Sector" },
        { accessor: "country", label: "Country" },
        { accessor: "businessDescription", label: "Business Description" },
        { accessor: "investmentDate", label: "Investment Date" },
        { accessor: "sweefOwnership", label: "SWEEF Ownership" },
        { accessor: "totalCommitment", label: "Total Commitment" },
        { accessor: "totalInvestment", label: "Total Investment" },
        { accessor: "unrealizedValue", label: "Unrealized Value (NAV)" },
        { accessor: "realizedValue", label: "Realized Value" },
        { accessor: "totalValue", label: "Total Value" },
        { accessor: "rvpi", label: "RVPI" },
        { accessor: "dpi", label: "DPI" },
        { accessor: "grossTvpi", label: "Gross TVPI" },
        { accessor: "grossIrr", label: "Gross IRR" },
      ],
      records: [],
      inviteCompanies: false,
      selectedRows: Array(0).fill(false),
      selectedPortfolioType: "",
      searchQuery: "",
      selectedDocumentType: "",
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  methods: {
    isSelected(recordId) {
      return this.selectedRows[
        this.records.findIndex((record) => record.id === recordId)
      ];
    },
    populateRecords(company) {
      return {
        userId: company.userId,
        email: company.email,
        teamId: company.teamId,
        companies: company.name,
        inviterId: company.inviterId,
        inviterOrgId: company.inviterOrgId,
        _id: company._id,
        leadership: "35%",
        GBVH: "35%",
        ESG: "35%",
        portfolio: "35%",
        entrepreneurshipOwnership: "35%",
        employment: "35%",
        productsServices: "35%",
        supplyChain: "35%",
        governanceAccountability: "35%",
        women: "35%",
        certificationImage: "@/assets/images/2x_certification_tier_good.svg",
        certificationStatus: "2X Certified",
        certificationDate: "05.05.2023",
        validationStatus: "Pending verification",
        certificationRequest:
          "Request to repeat 2X Certification Self-Assessment",
        portfolioType: company.portfolioType,
      };
    },
    clearFilters() {
      this.searchQuery = "";
      this.selectedDocumentType = "";
      this.selectedPortfolioType = "";
    },
    filterRecords(companies) {
      const filtered = companies
        .filter((company) => {
          const matchesSearch =
            this.searchQuery === "" ||
            company.name.toLowerCase().includes(this.searchQuery.toLowerCase());
          const matchesType =
            this.selectedPortfolioType === "" ||
            company.portfolioType === this.selectedPortfolioType;
          const matchesDocument =
            this.selectedDocumentType === "" ||
            company[this.selectedDocumentType.toLowerCase()] !== undefined;

          return matchesSearch && matchesType && matchesDocument;
        })
        .map(this.populateRecords);

      return filtered;
    },
    handlePageChange(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
  computed: {
    totalItems() {
      return this.records.length;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    endIndex() {
      return Math.min(this.startIndex + this.itemsPerPage, this.totalItems);
    },
    paginatedRecords() {
      return this.records.slice(this.startIndex, this.endIndex);
    },
    displayedPages() {
      // show up to 5 page numbers
      const pages = [];
      const maxPages = Math.min(5, this.totalPages);
      let start = Math.max(1, this.currentPage - 2);
      let end = Math.min(start + maxPages - 1, this.totalPages);

      if (end - start + 1 < maxPages) {
        start = Math.max(1, end - maxPages + 1);
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  watch: {
    companies: {
      handler(newVal) {
        if (Array.isArray(newVal)) {
          this.records = this.filterRecords(newVal);
        }
      },
      immediate: true,
      deep: true,
    },
    searchQuery() {
      this.records = this.filterRecords(this.companies);
    },
    selectedDocumentType() {
      this.records = this.filterRecords(this.companies);
    },
    selectedPortfolioType() {
      this.records = this.filterRecords(this.companies);
    },
  },
};
</script>
