<template>
  <div class="flex h-[98vh]">
    <div class="lg:hidden relative">
      <input
        type="checkbox"
        id="drawer-toggle"
        class="hidden relative sr-only peer"
        v-model="isOpen"
      />
      <label
        for="drawer-toggle"
        class="inline-block transition-all duration-500 bg-white rounded-lg peer-checked:rotate-180 peer-checked:left-64"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="h-16 w-16 sm:h-12 sm:w-12 p-3 rounded-lg bg-[#201747] text-white"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </label>

      <div
        class="fixed inset-0 z-10 bg-black opacity-0 transition-opacity duration-500 peer-checked:opacity-50"
        @click="isOpen = false"
        v-if="isOpen"
      ></div>
    </div>

    <div class="w-[18%] h-[100vh] bg-[#201747] hidden lg:block">
      <sideBar
        @selected-chat-history="handleViewChatHistory"
        @new-chat="openNewChat"
        ref="sideBarHistory"
      />
    </div>
    <div class="w-[100%] lg:w-[82%] mb-20">
      <div class="px-12 pt-4 mr-12 flex">
        <div
          class="flex border-2 border-black p-2 rounded-md w-[270px] h-[50px] m-5"
          v-for="(item, index) in filesNames" :key="index"
        >
          <h3 class="m-auto">
            {{ item.slice(0, 20) }}
          </h3>
        </div>

      </div>
      <div class="px-12 py-4 h-full relative">
        <!-- <chatMode /> -->
        <div
          class="w-[98%] px-6 h-[calc(100vh-20rem)] sm:h-[calc(100vh-16rem)] lg:h-[calc(100vh-14rem)] flex flex-col justify-end"
        >
          <chatSuggestion
            v-if="chatSuggestion"
            class="text-lg sm:text-sm"
            @selected-chat-suggestion="handleChatSuggestion"
          />
          <messageList
            class="pr-16 overflow-auto"
            :YOU="YOU"
            :messages="messages"
            :isLoadingChatResponse="isLoadingChatResponse"
            :expandedMessageIndex="expandedMessageIndex"
            :isFullScreen="true"
            @toggle-sources="toggleSources"
          />
        </div>
        <div class="w-[90%] flex bottom-0">
          <form
            class="w-full flex items-center"
            @submit.prevent="addMessage(true)"
          >
            <input
              v-model="message"
              :disabled="isLoadingChatResponse || viewChatHistory"
              type="text"
              :placeholder="
                viewChatHistory
                  ? 'Continue chatting...'
                  : helpChat
                  ? 'How can I help?'
                  : 'Type your question here...'
              "
              class="flex-1 p-3 text-lg sm:text-sm rounded-md w-[85%] focus:outline-none focus:ring-0 bg-gray-200 text-gray-700"
            />
            <button
              :disabled="viewChatHistory"
              type="submit"
              class="ml-5 p-2 text-white rounded-md bg-[#fe5000]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-7 h-7 sm:w-6 sm:h-6"
              >
                <path
                  d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"
                />
              </svg>
            </button>
          </form>
          <div>
            <div class="pl-5">
              <label
                for="file-upload"
                class="inline-block montserrat font-bold text-1xl text-white cursor-pointer hover:bg-blue-500 border-2 rounded-[16px] p-3"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path
                    d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                </svg>
              </label>
              <input
                ref="inputFileRef"
                class="hidden"
                id="file-upload"
                type="file"
                title="Upload"
                accept=".pdf"
                multiple
                v-on:change="uploadFiles"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sideBar from "@/components/chat/fullScreen/sideBar.vue";
import messageList from "@/components/chat/common/messageList.vue";
import chatSuggestion from "@/components/chat/fullScreen/chatSuggestion.vue";
import chat from "@/api/chat";
import { useChatStore } from "@/stores/chat";
import { mapState } from "pinia";
import axios from "axios";
import env from "../../../env";
import Cookies from "@/helpers/cookies";

export default {
  components: {
    sideBar,
    messageList,
    chatSuggestion
  },
  data() {
    return {
      YOU: "user",
      ASSISTANT: "assistant",
      sessionId: "",
      message: "",
      messages: [],
      isLoadingChatResponse: false,
      newIncomingMessage: "",
      expandedMessageIndex: -1,
      dropdownOpen: false,
      chatSuggestion: true,
      viewChatHistory: false,
      helpChat: false,
      isOpen: false,
      isNewConversation: false,
      files: [],
      conversationID: null,
      filesNames: []
    };
  },
  computed: {
    ...mapState(useChatStore, ["isChatOpen"])
  },
  methods: {
    async refreshUploadedFilesNames() {
      const response = await chat.listFiles(this.conversationID);
      this.filesNames = response.data;
    },
    async uploadFiles(e) {
      const files = e.target.files;
      const allowedExtensions = ["pdf"];

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > this.maxFileSize) {
          this.$swal.fire({
            icon: "error",
            title: "File Too Large",
            text: `${files[i].name} exceeds the maximum file size of 10 MB.`,
            showConfirmButton: true
          });
          this.isLoading = false;
          this.$refs.inputFileRef.value = null;
          return;
        }

        if (!allowedExtensions.includes(files[i].name.split(".")[1])) {
          this.$swal.fire({
            icon: "error",
            title: "File Extension not allowed",
            text: `This is the allowed file type: ${allowedExtensions.join(",")}.`,
            showConfirmButton: true
          });
          return;
        }
      }

      try {
        await chat.uploadFiles(files, this.isNewConversation);
        let fileNames = Array.from(files).map((f) => f.name);
        this.$swal.fire({
          icon: "success",
          title: "Files Uploaded Successfully!",
          text: "Uploaded Files:\n" + fileNames.join(", "),
          showConfirmButton: true
        });
        this.$refs.inputFileRef.value = null;
        await this.refreshUploadedFilesNames();
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: "error",
          title: "Something went Wrong!",
          showConfirmButton: true
        });
      }
    },
    async newChatMessage(_message) {
      this.isLoadingChatResponse = true;
      try {
        let response = await chat.newMessage(_message, this.isNewConversation);
        this.isLoadingChatResponse = false;
        this.messages.push({
          message: response.data.response,
          author: this.ASSISTANT
        });
        this.$refs.sideBarHistory.addNewMessageToHistory(_message, response.data);

      } catch (error) {
        this.messages.push({
          message: "Sorry I can not respond right now.",
          author: this.ASSISTANT
        });
      } finally {
        this.isLoadingChatResponse = false;
        this.isNewConversation = false;
      }
    },
    addMessage(user) {
      if (!this.message.trim()) return;
      if (user) {
        this.chatSuggestion = false;
        this.messages.push({
          message: this.message,
          author: this.YOU,
          sources: null
        });
        this.newChatMessage(this.message);
        this.message = "";
      }
    },
    toggleSources(index) {
      if (this.expandedMessageIndex === index) {
        this.expandedMessageIndex = -1;
      } else {
        this.expandedMessageIndex = index;
      }
    },
    handleViewChatHistory(historyData) {
      const [history, isActive] = historyData;
      this.isLoadingChatResponse = false;
      this.chatSuggestion = false;
      let storedMessages = [];
      for (let index in history.messages) {
        let msg = history.messages[index];
        storedMessages.push({
          message: msg.userMessage,
          author: this.YOU,
          sources: null
        });
        storedMessages.push({
          message: msg.chatbotResponse,
          author: this.ASSISTANT,
          sources: null
        });
      }
      this.messages = storedMessages;
      this.viewChatHistory = !isActive;
      this.message = "";
      this.conversationID = history._id;
      this.isNewConversation = false;
      this.refreshUploadedFilesNames();
    },
    openNewChat() {
      this.isLoadingChatResponse = false;
      this.viewChatHistory = false;
      this.chatSuggestion = true;
      this.messages = [];
      this.message = "";
      this.isNewConversation = true;
      this.filesNames = [];
    },
    handleChatSuggestion(suggestion) {
      this.message = suggestion.title;
      this.addMessage(true);
      this.chatSuggestion = false;
    },
    async logOutUser() {
      localStorage.removeItem("previousURL");
      localStorage.removeItem("lastActive");
      let sessionID = Cookies.get("session");
      let deviceID = localStorage.getItem("deviceToken");
      return axios
        .post(
          env.apiUrl + "/users/me/logout",
          { sessionId: sessionID, deviceId: deviceID },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          }
        )
        .then(() => {
          if (!localStorage.getItem("isLogout")) {
            localStorage.setItem("isLogout", true);
          }
          this.$store.commit("logout");
        });
    }
  }
};
</script>
