import { render, staticRenderFns } from "./ImpactMetrics.vue?vue&type=template&id=6d800b92&scoped=true"
import script from "./ImpactMetrics.vue?vue&type=script&lang=js"
export * from "./ImpactMetrics.vue?vue&type=script&lang=js"
import style0 from "./ImpactMetrics.vue?vue&type=style&index=0&id=6d800b92&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d800b92",
  null
  
)

export default component.exports