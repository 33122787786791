<template>
  <div class="flex flex-col gap-4 justify-between my-5">
    <!-- total ESG section -->
    <div class="flex flex-col gap-2">
      <div class="flex items-center justify-between gap-2">
        <span class="text-2xl font-bold">{{ totalEsg }}%</span>
        <span class="text-large font-semibold text-[#4dc9be]"
          >↗ {{ esgIncrease }}% increase</span
        >
      </div>
      <span class="text-sm font-semibold text-gray-500">Total ESG %</span>
    </div>

    <!-- ESG breakdown -->
    <div class="flex flex-col gap-2">
      <div class="flex flex-col gap-1">
        <div class="flex justify-between">
          <span class="text-sm">Environmental</span>
          <span class="font-semibold">{{ environmental }}%</span>
        </div>
        <div class="flex justify-between">
          <span class="text-sm">Social</span>
          <span class="font-semibold">{{ social }}%</span>
        </div>
        <div class="flex justify-between">
          <span class="text-sm">Governance</span>
          <span class="font-semibold">{{ governance }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ESGModuleCardContent",
  props: {
    totalEsg: {
      type: Number,
      default: 15,
    },
    esgIncrease: {
      type: Number,
      default: 5,
    },
    environmental: {
      type: Number,
      default: 25,
    },
    social: {
      type: Number,
      default: 10,
    },
    governance: {
      type: Number,
      default: 24,
    },
  },
};
</script>
