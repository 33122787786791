<template>
  <!-- Deals -->
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    @click.self="$emit('close')"
  >
    <div class="mt-8 bg-[#F8F9FC] p-4 rounded-md overflow-y-auto">
      <div class="flex justify-between items-center">
        <span class="font-['Arial'] text-xl font-bold leading-7 text-[#2C2E35]">
          {{ title }}
        </span>
        <button @click="$emit('close')">X</button>
      </div>

      <div
        v-for="(deal, index) in deals"
        :key="index"
        class="flex items-center gap-2 mt-4"
      >
        <input
          v-model="deals[index].name"
          @input="handleDealInput(index)"
          type="text"
          placeholder="Deal name"
          class="w-[616px] h-12 p-3 rounded-md border border-[#E1E3E9] placeholder:font-['Arial'] placeholder:text-base placeholder:text-[#9EA4BA]"
        />
        <button
          class="bg-red-500 text-white px-4 py-2 rounded-md font-semibold"
          @click="removeDeal(index)"
        >
          Remove
        </button>
      </div>
      <div class="flex items-center gap-2 mt-4">
        <input
          v-model="dealName"
          type="text"
          placeholder="Deal name"
          class="w-[616px] h-12 p-3 rounded-md border border-[#E1E3E9] placeholder:font-['Arial'] placeholder:text-base placeholder:text-[#9EA4BA]"
        />
        <button
          class="bg-[#191B45] text-white px-8 py-2 rounded-md font-semibold"
          :disabled="!dealName"
          :class="{ 'opacity-50': !dealName }"
          @click="addDeal"
        >
          Add
        </button>
      </div>
      <div class="mt-8 flex justify-end">
        <button
          class="bg-[#191B45] text-white px-8 py-2 rounded-md font-semibold"
          :class="{ 'opacity-50': deals.length === 0 }"
          :disabled="deals.length === 0"
          @click="completeSetup"
        >
          Finish adding deals
        </button>
      </div>
      <div v-if="error.error" class="mt-4 text-red-500">
        {{ error.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DealsModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    error: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      deals: [],
      dealName: "",
    };
  },
  methods: {
    completeSetup() {
      this.$emit("setup-complete", this.deals);
    },
    handleDealInput(index) {
      if (!this.deals[index].name) {
        this.removeDeal(index);
      }
    },
    addDeal() {
      if (this.dealName) {
        this.deals.push({ name: this.dealName });
        this.dealName = "";
      }
    },
    removeDeal(index) {
      this.deals.splice(index, 1);
    },
  },
};
</script>
