<template>
  <div class="static ecosystem-landing relative">
    <div class="container mx-auto -mt-20 px-4 lg:px-0">
      <div id="notLoaded" v-if="!endofcreated || isDownloadingPDF" key="loading">
        <page-loader></page-loader>
      </div>
      <!--Logo-->
      <div class="PoweredbyTitle_logo">
        <div class="PoweredbyContentWrepper">
          <img
            src="@/assets/images/2X-Global_logo_transparent.svg"
            alt="background-image"
            style="height: 50px"
          />
        </div>
        <div style="display: flex; justify-content: flex-end">
          <div class="Poweredby_logoImg mt-2">
            <div class="powered-text flex">
              <span>Powered by:</span>
            </div>
            <img src="@/assets/images/logo-navy.png" alt="background-image" />
          </div>
        </div>
      </div>
      <!-- Content Wrepper -->

      <div
        class="ContentWrapper w-12/12"
        v-if="
          endofcreated &&
          certificationScoreData &&
          certificationScoreData != '' &&
          certificationSurveyData?.length
        "
      >
        <div class="container mx-auto">
          <!-- If page is Self-Assessment-Results then we'll display 7 steps progress bar otherwise 5 steps progress bar -->
          <minSurvey-steps-component-twox
            v-if="$route.path == '/2X-Challenge/2X-Challenge-Self-Assessment-Results'"
            :currentStep="current_step"
            :userStatus="status2xCertification"
            :isAllFullSurveysCompleted="isAllFullSurveysCompleted"
            :certification_result="certification_result"
            :twoxEligible="twoxEligible"
          />
          <steps-component-twox
            v-else
            :currentStep="current_step"
            :userStatus="status2xCertification"
            :isAllFullSurveysCompleted="isAllFullSurveysCompleted"
          />
        </div>
        <div ref="pdfWrapper" class="container mx-auto">
        <div class="container flex items-center mb-2">
          <div class="flex w-full justify-between">
            <h1
              class="text-6xl tracking-tighter font-medium print:text-7xl"
              style="color: #19486a; max-width: 1200px"
            >
              <span style="font-family: Work Sans"
                >{{ certificationScoreData?.team_id?.name }}'s 2X
                {{
                  $route.path == '/2X-Challenge/2X-Challenge-Self-Assessment-Results' ? "Challenge Self-Assessment Results" : "Certification Self-Assessment Indicative Results"
                }}
                </span
              >
            </h1>
            <div class="items-center gap-2 isPDF container mx-auto grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 max-w-md mb-8">
              <EquiloButton
                v-if="isVisibleDownloadXLSX"
                text="Download XLSX"
                classes="[ h-12 ][ font-bold text-[16px] ][ hover:text-black hover:bg-transparent ]"
                :bg-color="isAllowedDownloadXLSX ? 'primary' : 'cancel'"
                @on-click="downloadXlsx()"
              />
              <EquiloButton
                v-if="!showDataProcessingScreen && $route.path == '/2X-Ecosystem/2X-Certification/IndicativeResults'"
                text="Download PDF"
                classes="[ h-12 ][ font-bold text-[16px] ][ hover:text-black hover:bg-transparent ]"
                :bg-color="isAllowedDownloadXLSX ? 'primary' : 'cancel'"
                :disabled="isDownloadingPDF"
                @on-click="handleExportToPDF()"
              />
              <button
                class="h-12 px-3 py-2 text-xs font-semibold border-2 border-[#19486A] gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                :class="{ disabled: 'opacity-40' }"
                v-if="!$store.state.certPageAccess.isUploadDocPage"
                @click="repeatAssesModal = true"
              >
                <span class="pr-1"> Repeat 2X Self-Assessment </span>
              </button>
            </div>
          </div>
        </div>
        <div class="mb-3 text-lg font-semibold text-[#666461]" v-if="dateOfSubmission">
          Submission Date: {{ moment(dateOfSubmission).format("DD MMMM, YYYY") }}
        </div>
        <div :class="{'mb-4': !showDataProcessingScreen}" v-if="!showDataProcessingScreen">
          <p>
            {{
              `Great job on completing the 2X Self-Assessment! Your ${
                $route.path === '/2X-Challenge/2X-Challenge-Self-Assessment-Results'
                  ? ""
                  : "indicative "
              }results are in. You may review these and take action below.`
            }}
          </p>
        </div>
        <div class="container flex gap-5 justify-between mb-8">
          <div class="flex">
            <div
              class="flex gap-2 items-center text-xs font-bold cursor-pointer"
              style="color: #2177c1"
              @click="see2XThresholdCriteria()"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.16675 4.83342H9.83342V6.50008H8.16675V4.83342ZM8.16675 8.16675H9.83342V13.1667H8.16675V8.16675ZM9.00008 0.666748C4.40008 0.666748 0.666748 4.40008 0.666748 9.00008C0.666748 13.6001 4.40008 17.3334 9.00008 17.3334C13.6001 17.3334 17.3334 13.6001 17.3334 9.00008C17.3334 4.40008 13.6001 0.666748 9.00008 0.666748ZM9.00008 15.6667C5.32508 15.6667 2.33341 12.6751 2.33341 9.00008C2.33341 5.32508 5.32508 2.33341 9.00008 2.33341C12.6751 2.33341 15.6667 5.32508 15.6667 9.00008C15.6667 12.6751 12.6751 15.6667 9.00008 15.6667Z"
                  fill="#2177C1"
                />
              </svg>
              <span>See 2X Threshold Criteria</span>
            </div>
          </div>
          <div
            v-if="
              this.$route.path.includes('2X-Certification-Self-Assessment')
            "
           >
            <a
              :href="entityChosen === 'fund-manager' ? 'https://storage.equilo.io/api-assets/2X_Certification_Detailed_indicator_questions.pdf' : 'https://storage.equilo.io/api-assets/2X%20Certification_Detailed%20indicator%20questions_vCompanies_April%202024.pdf'"
              :download="entityChosen === 'fund-manager' ? 'Certification_Detailed_Questions_Fund.pdf' : 'Certification_Detailed_indicator_questions.pdf'"
              class="text-lg tracking-tighter font-medium rounded-lg border-2 px-4 py-2"
              target="_blank"
            >
              <span>Download Questions</span>
            </a>
          </div>
          <div v-else>
            <a
              href="https://storage.equilo.io/api-assets/2X_Challenge_Self_Assessment_Questions.pdf"
              download="Challenge_Questions.pdf"
              class="text-lg tracking-tighter font-medium rounded-lg border-2 px-4 py-2"
              target="_blank"
            >
              <span>Download Questions</span>
            </a>
          </div>
        </div>
        <template v-if="showDataProcessingScreen">
          <div class="congrats-box-prelim p-8 mb-8 text-center relative">
            <div class="flex items-center justify-center">
              <div class="p-8 rounded-lg max-w-3xl w-full">
                <h1 class="text-2xl font-bold text-center mb-6" style="color: #19486A">
                  We're Crunching Your Numbers!
                </h1>
                <div class="mb-10 relative">
                  <svg class="w-full h-40" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <g class="grid">
                      <line x1="0" y1="25" x2="100" y2="25" stroke="#e5e7eb" stroke-width="0.5" />
                      <line x1="0" y1="50" x2="100" y2="50" stroke="#e5e7eb" stroke-width="0.5" />
                      <line x1="0" y1="75" x2="100" y2="75" stroke="#e5e7eb" stroke-width="0.5" />
                    </g>

                    <rect class="bar" x="5" y="50" width="10" height="50" fill="url(#barGradient)">
                      <animate attributeName="height" values="50;80;20;70;30;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                      <animate attributeName="y" values="50;20;80;30;70;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                    </rect>
                    <rect class="bar" x="25" y="50" width="10" height="50" fill="url(#barGradient)">
                      <animate attributeName="height" values="50;30;75;25;60;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                      <animate attributeName="y" values="50;70;25;75;40;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                    </rect>
                    <rect class="bar" x="45" y="50" width="10" height="50" fill="url(#barGradient)">
                      <animate attributeName="height" values="50;65;35;80;20;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                      <animate attributeName="y" values="50;35;65;20;80;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                    </rect>
                    <rect class="bar" x="65" y="50" width="10" height="50" fill="url(#barGradient)">
                      <animate attributeName="height" values="50;20;70;40;75;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                      <animate attributeName="y" values="50;80;30;60;25;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                    </rect>
                    <rect class="bar" x="85" y="50" width="10" height="50" fill="url(#barGradient)">
                      <animate attributeName="height" values="50;75;25;60;40;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                      <animate attributeName="y" values="50;25;75;40;60;50" dur="4s" repeatCount="indefinite" keyTimes="0;0.2;0.4;0.6;0.8;1" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" calcMode="spline" />
                    </rect>

                    <linearGradient id="barGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" stop-color="#2177C1" />
                      <stop offset="100%" stop-color="#93c5fd" />
                    </linearGradient>
                  </svg>
                </div>
                <p class="text-gray-600 text-center mb-4">
                  Hang tight—this might take a few minutes. We're calculating your 2X
                  results based on the survey data you submitted.
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="congrats-box-prelim p-8 mb-8 text-center relative">
            <div class="flex justify-between">
              <div class="flex gap-4 items-center">
                <h3 class="text-xl tracking-tighter font-bold">
                  Entity Type:
                </h3>
                <p class="text-lg font-medium">
                  {{ entityDisplay[entityChosen] }}
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <h3 class="text-xl tracking-tighter font-bold">
                  Country:
                </h3>
                <p class="text-lg font-medium">
                  {{ certificationScoreData?.GEN_General_11 }}
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <h3 class="text-xl tracking-tighter font-bold">
                  Sector:
                </h3>
                <p class="text-lg font-medium">
                  {{ certificationScoreData?.GEN_General_13 }}
                </p>
              </div>
            </div>
          </div>
          <div class="congrats-box-prelim-main">
            <div class="congrats-box-prelim px-8 pt-8 mb-8 text-center relative" style="border: none;">
              <div
                v-if="
                  $route.path == '/2X-Ecosystem/2X-Certification/IndicativeResults' && $store.state.certPageAccess.isFullSurveyFill &&
                  certification_result?.name
                "
              >
              <img
                v-if="certification_result?.name === 'Good'"
                class="mx-auto mb-3 h-48 w-48"
                :src="isDownloadingPDF ? require('@/assets/images/2x_certify/2x_certification_tier_good.png') : require('@/assets/images/2x_certify/2x_certification_tier_good.svg')"
                style="filter: grayscale(1)"
              />
              <img
                v-if="certification_result?.name === 'Advanced'"
                class="mx-auto mb-3 h-48 w-48"
                :src="isDownloadingPDF ? require('@/assets/images/2x_certify/2x_certification_tier_advanced.png') : require('@/assets/images/2x_certify/2x_certification_tier_advanced.svg')"
                style="filter: grayscale(1)"
              />
              <img
                v-if="certification_result?.name === 'Best in Class'"
                class="mx-auto mb-3 h-48 w-48"
                 :src="isDownloadingPDF ? require('@/assets/images/2x_certify/2x_certification_tier_bestinclass.png') : require('@/assets/images/2x_certify/2x_certification_tier_bestinclass.svg')"
                style="filter: grayscale(1)"
              />
                <div  v-if="!$store.state.certPageAccess.isVerificationRecommendsCertification && ($route.path.includes('IndicativeResults') || $route.path.includes('2X-Challenge-Self-Assessment-Results'))" class="absolute opacity-70 top-0 bottom-2 left-0 right-0 translate-y-8 container mx-auto">
                    <p class="text-gray-700 text-2xl font-extrabold max-w-sm  mx-auto mb-8">Pending Verification and Certification</p>
                </div>
              </div>
              <div v-else>
                <img
                  v-if="isTwoXEligible"
                  class="mx-auto mb-3"
                  src="@/assets/images/happy-smile.svg"
                />
                <img
                  v-else
                  class="mx-auto mb-3"
                  src="@/assets/images/grey-Sad.svg"
                />
              </div>
              <p
                class="font-bold text-xl"
                style="color: #2c2e35"
                v-if="
                  $route.path == '/2X-Ecosystem/2X-Certification/IndicativeResults' && $store.state.certPageAccess.isFullSurveyFill &&
                  certification_result?.name
                "
              >
                Congratulations! Your self-assessment appears to meet the minimum 2X
                requirements of the
                <span style="font-weight: bold">{{
                  certification_result?.name
                }}</span>
                Tier. However, in order to receive the 2X Certification, the
                information you provided must be verified by a third party. The next
                step is to upload your documents to
                <span style="font-weight: bold">begin verification</span> to be
                certified.
              </p>
              <div v-else>
                <p
                  class="font-bold text-xl"
                  style="color: #2c2e35"
                  v-if="
                    isTwoXEligible &&
                    $route.path === '/2X-Challenge/2X-Challenge-Self-Assessment-Results'
                  "
                >
                  Congratulations! Your 2X Challenge self-assessment appears to meet the minimum
                  2X requirements. You may now proceed to the 2X Certification
                  Self-Assessment to go even deeper into the dimensions and themes.
                </p>
                <p class="font-bold text-xl" style="color: #2c2e35" v-else>
                  Unfortunately, you do not meet the 2X Criteria minimum
                  requirements. Please review the
                  <span
                    class="cursor-pointer"
                    @click="see2XThresholdCriteria()"
                    style="color: #2177c1"
                    ><u>2X Threshold criteria</u></span
                  >
                  {{
                    `criteria and come back to repeat the  ${
                      $route.path == '/2X-Challenge/2X-Challenge-Self-Assessment-Results'
                        ? "2X Challenge Self-Assessment"
                        : "2X Certification Self-Assessment"
                    } once you are able to meet the minimum requirements.`
                  }}
                </p>
              </div>
              <div class="container flex justify-center items-center gap-3 mt-5 relative">
                <EquiloButton
                  v-if="isVisibleTakeFullAssessment"
                  text="Proceed to 2X Certification Self-Assessment"
                  classes="[ h-12 ][ font-bold text-[16px] ]"
                  @on-click="takeFullAssess()"
                />
                <EquiloButton
                  v-if="isVisibleProceedVerification"
                  text="Level Up your 2X Eligibility"
                  classes="[ h-12 ][ font-bold text-[16px] ]"
                  @on-click="proceedToVerification()"
                />
                <EquiloButton
                  v-if="isVisibleProceedVerification"
                  text="Proceed to Verification"
                  classes="[ h-12 ][ font-bold text-[16px] ]"
                  @on-click="proceedToVerification()"
                />
                <!-- Upload Documents -->
                <EquiloButton
                  v-if="$store.state.certPageAccess.isUploadDocPage && !$store.state.certPageAccess.isVerificationPage"
                  text="Upload Documents"
                  classes="[ h-12 ][ font-bold text-[16px] ]"
                  @on-click="navigateToUploadDocuments()"
                />

              </div>
            </div>
          </div>
          <div class="congrats-box-prelim p-8 my-8 text-center relative">
            <div class="flex flex-col">
              <p class="font-bold text-xl" style="color: #2c2e35">
                Discover Equilo's open-source tools designed to empower your journey towards gender equality and social inclusion.
              </p>
              <div class="container flex justify-center items-center gap-3 mt-5 relative">
                <button
                  class="standard-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                  @click="$router.push({ path: '/GenderSmartTools' })"
                >
                  <span class="pr-1">Gender smart tools</span>
                </button>
                <button
                  class="standard-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                  @click="
                    $router.push({ path: '/2X-Ecosystem/technical-service-list' })
                  "
                >
                  <span class="pr-1">Technical service provider</span>
                </button>
                <!-- Added Email in condition for testing on PROD -->
                <button v-if="$route.path.includes('/2X-Ecosystem/2X-Certification/IndicativeResults') && (user.email.includes('simformsolutions') || user.email.includes('equilo'))"
                  class="standard-button px-3 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center"
                  @click="isInsightsModalVisible = true"
                >
                  <span class="pr-1">Show Insights for Action</span>
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="theme-box p-5 mb-6">
            <h3 class="text-xl tracking-tighter font-bold">Theme Badges</h3>
            <div class="flex">
              <div class="theme-badge flex items-center gap-2" style="border-color: #ccc;">
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z" fill="#ccc"/>
                </svg>
                <div>
                  <p style="font-weight: bold; color: #999;">Best in class</p>
                  <p style="color: #999">GBVH</p>
                </div>
              </div>

              <div class="theme-badge flex items-center gap-2" style="border-color: #ccc;">
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z" fill="#ccc"/>
                </svg>
                <div>
                  <p style="font-weight: bold; color: #999">Better</p>
                  <p style="color: #999">Climate</p>
                </div>
              </div>
              <div class="theme-badge flex items-center gap-2" style="border-color: #ccc;">
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z" fill="#ccc"/>
                </svg>
                <div>
                  <p style="font-weight: bold; color: #999">Good</p>
                  <p style="color: #999;">Health & well-being</p>
                </div>
              </div>
              <div class="theme-badge flex items-center gap-2" style="border-color: #ccc;">
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0003 15.5539L4.23172 18.5868L5.33366 12.163L0.666992 7.61408L7.11602 6.67757L10.0003 0.833252L12.8846 6.67757L19.3337 7.61408L14.667 12.163L15.7689 18.5868L10.0003 15.5539Z" fill="#ccc"/>
                </svg>
                <div>
                  <p style="font-weight: bold; color: #999">Best in class</p>
                  <p style="color: #999">JEDI</p>
                </div>
              </div>
            </div>
          </div> -->
          <OverviewBoxCertification
            :domainChartData="domainChartData"
            :dimensions="dimensions"
            :entityChosen="entityChosen"
            :srManagerThreshold="srManagerThreshold"
            :workforceThreshold="workforceThreshold"
            :boardThreshold="boardThreshold"
            :certificationScoreData="certificationScoreData"
            :entrepreneurshipPercent="entrepreneurshipPercent"
            :leadershipPercent="leadershipPercent"
            :employmentPercent="employmentPercent"
            :productServicePercent="productServicePercent"
            :supplyChainPercent="supplyChainPercent"
            :portfolioPercent="portfolioPercent"
            :isPortfolioVisible="isPortfolioVisible"
            :govStrategicAction="govStrategicAction"
            :govManagementSystem="govManagementSystem"
            :govData="govData"
            :govChartData="govChartData"
            :assessmentYear="certificationScoreData.assessmentYear"
            :isEntOwnEligible="isEntOwnEligible"
            :isSupplyChainEligible="isSupplyChainEligible"
            :isProductsEligible="isProductsEligible"
            :isEmploymentEligible="isEmploymentEligible"
            :isLeadershipEligible="isLeadershipEligible"
            :isAllFullSurveysCompleted="isAllFullSurveysCompleted"
            :isAllowedViewEvaluationResults="isAllowedViewEvaluationResults"
            @on-click-evaluation-results-button="onClickEvaluationResults"
            :isCertificationAwarded="status2xCertification == 'Certification Awarded'"
        >
          </OverviewBoxCertification>
        </template>
        </div>
      </div>
    </div>
    <div
      class="bottom-button-section text-right"
      v-if="
        endofcreated &&
        certificationScoreData &&
        certificationSurveyData?.length
      "
    >
      <div class="flex justify-end">
        <button
          class="px-3 py-2 text-xs font-semibold border-2 border-[#19486A] gap-1 rounded-lg transition duration-200 flex-row items-center justify-center mr-2"
          v-if="!$store.state.certPageAccess.isUploadDocPage"
          @click="repeatAssesModal = true"
        >
          <span class="pr-1"> Repeat 2X Self-Assessment</span>
        </button>
        <button
          @click="
            $router.push({
              path: '/2X-Ecosystem/2X-Certification',
            })
          "
        >
          Back to Dashboard
        </button>
      </div>
    </div>
    <div
      class="container mx-auto breadcrumbs-main-container"
      v-if="endofcreated && !certificationSurveyData?.length"
    >
      <div class="flex flex-col w-6/12 lg:w-9/12">
        <h1
          class="text-5xl print:text-4xl tracking-tighter font-semibold lowercase capitalize"
          style="color: #19486a"
        >
          Hi {{ this.$store.state.user.firstname }},
        </h1>
        <p class="tracking-tight font-medium" style="color: #19486a">
          I apologize for the error, something didn't go as planned.
        </p>
      </div>
    </div>
    <Modal
      v-if="isOpenSelectFundManager"
      @on-close="isOpenSelectFundManager = false"
    >
      <TwoXFundManagerAUM
        @on-cancel="isOpenSelectFundManager = false"
        @on-continue="handleOnSelectFundManager"
      />
    </Modal>

    <Modal v-if="isPaywallOpen" @on-close="isPaywallOpen = false">
      <template>
        <clip-loader
          v-if="isLoadingProducts"
          :size="'80px'"
          :color="'#2BD4C4'"
        />
        <div
          v-if="!isLoadingProducts && errorProducts"
          class="[ text-center ]"
        >
          There was an error getting the products, please try it again later.
        </div>
        <Paywall
          v-if="!isLoadingProducts && !errorProducts"
          title="Continue your 2X Certification journey now!"
          subtitle="Take the next step towards certification and/or advance to the next level with our additional product offering."
          :items="products"
          :displayVerificationProduct="this.isTwoXEligible"
          @on-cancel="isPaywallOpen = false"
          @on-buy="handleOnBuyProducts"
        />
      </template>
    </Modal>

    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="repeatAssesModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <div
            class="Modal_Readmorestyle what-modal pr-2 justify-center relative"
          >
            <div class="ModalInnerStyle">
              <h3 class="font-semibold text-lg mb-1">Repeat Your Assessment</h3>
              <p class="font-semibold">
                You can repeat the 2X Challenge Self-Assessment or 2X Certification
                Self-Assessment by clicking on one of two buttons.
              </p>
              <div class="flex mt-10 justify-center gap-5">
                <button
                  style="background-color: #3f7e44; color: white"
                  class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mt-2"
                  :disabled="status2xCertification == 'Full Survey Submitted'"
                  :class="{
                    'pointer-events-none': status2xCertification == 'Full Survey Submitted',
                  }"
                  @click="
                    navigateAssessment(
                     '/2X-Challenge/2X-Challenge-Self-Assessment'
                    )
                  "
                >
                  2X Challenge Self-Assessment
                </button>
                <button
                  style="background-color: #19486a; color: white"
                  class="text-white px-3 py-2 text-lg gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mt-2"
                  :disabled="status2xCertification == 'Min Survey Submitted'"
                  :class="{
                    'pointer-events-none': status2xCertification == 'Min Survey Submitted',
                  }"
                  @click="
                    navigateAssessment(
                     '/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment'
                    )
                  "
                >
                 2X Certification Self-Assessment
                </button>
              </div>
            </div>
          </div>
          <svg
            @click="repeatAssesModal = false"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6 absolute top-1 right-1 text-[#19486A] cursor-pointer"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- Insights for Action Modal -->
    <insights-modal
      v-if="isInsightsModalVisible"
      :data="rawData"
      :certificationScoreData="certificationScoreData"
      :closeModal="() => (isInsightsModalVisible = false)"
    />
  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
import pageLoader from "@/components/page-loader.vue";
import OverviewBoxCertification from "@/components/overviewBoxCertification.vue";
import StepsComponentTwox from "@/components/StepsComponentTwox.vue";
import MinSurveyStepsComponentTwox from "@/components/MinSurveyStepsComponentTwox.vue";
import Paywall from "@/components/Paywall/index.vue";
import TwoXFundManagerAUM from "@/components/Paywall/2xFundManagerAUM.vue";
import Modal from "@/components/basics/Modal.vue";
import apiService from "@/helpers/apiServices.js";
import Cookies from "@/helpers/cookies";

import axios from "axios";
import { saveAs } from "file-saver";

import auth from "@/helpers/auth";
import env from "@/../env";
import EquiloButton from "@/components/basics/EquiloButton.vue";
import pdfExportHelpers from "../../helpers/pdfExportHelpers";
import SubscriptionAPIs from "@/api/SubscriptionAPIs";
import InsightsModal from "./my2xCertificationFullAssessmentResult/actions-insights/InsightsModal.vue";

export default {
  name: "twoxCertifyIndicativeResults",
  data: function () {
    return {
      current_step: 1,
      certification_result: {},
      cert_categories: {
        Good: {
          name: "Good",
          darkColor: "#FF3A21",
          lightColor: "#FFECE8",
        },
        Advanced: {
          name: "Advanced",
          darkColor: "#FCC30B",
          lightColor: "#FFF8E0",
        },
        "Best in Class": {
          name: "Best in Class",
          darkColor: "#4C9F38",
          lightColor: "#E5FAFA",
        },
      },
      dimensions: {
        Entrepreneurship: {
          color: "#395AFF",
          icon: "@/assets/images/2x_certify/entrepreneurship.svg",
          score: 0,
          threshold: 100,
        },
        Leadership: {
          color: "#3F7E44",
          icon: "@/assets/images/2x_certify/leadership.svg",
          score: 0,
          threshold: 100,
        },
        Employment: {
          color: "#DDA63A",
          icon: "@/assets/images/2x_certify/employment.svg",
          score: 0,
          threshold: 100,
        },
        Products: {
          color: "#A21942",
          icon: "@/assets/images/2x_certify/products.svg",
          score: 0,
          threshold: 100,
        },
        SupplyChain: {
          color: "#381B5B",
          icon: "@/assets/images/2x_certify/supplychain.svg",
          score: 0,
          threshold: 100,
        },
        Governance: {
          color: "#DDA63A",
          icon: "@/assets/images/2x_certify/governance.svg",
          score: 0,
          threshold: 100,
        },
        Portfolio: {
          color: "#8F210D",
          icon: "@/assets/images/2x_certify/portfolio.svg",
          score: 0,
          threshold: 100,
        },
      },
      srManagerThreshold: "",
      boardThreshold: "",
      workforceThreshold: "",
      thresholdsData: [],
      thresholds: [],
      industrySearchPlaceholder: "",
      selectedIndustries: [],
      selectedCountries: [],
      entityChosen: "",
      certificationSurveyData: [],
      certificationScoreData: [],
      certificationPricingData: [],
      badgeLevelColors: {
        Good: "#FFECE8",
        "Best in class": "#E5FAFA",
        Advanced: "#FFF8E0",
      },
      domainChartData: [],
      entrepreneurshipPercent: "",
      leadershipPercent: "",
      employmentPercent: "",
      productServicePercent: "",
      supplyChainPercent: "",
      portfolioPercent: "",
      govStrategicAction: "",
      govManagementSystem: "",
      govData: "",
      govChartData: [],
      endofcreated: false,
      user: [],
      isProceedToVerificationModalOpen: false,
      isSupplyChainEligible: "",
      isProductsEligible: "",
      isEmploymentEligible: "",
      isLeadershipEligible: "",
      isEntOwnEligible: "",
      isAllMinReqSurveysCompleted: false,
      isAllFullSurveysCompleted: false,
      status2xCertification: "",
      csvData: [],
      repeatAssesModal: false,
      entityDisplay: {
        'start-up': 'Start up',
        'corporate': 'Corporate',
        'sme': 'SME',
        'fund-manager': 'Fund Manager',
        'financial-institution': 'Financial Institution',
      },
      dateOfSubmission: null,
      showDataProcessingScreen: false,
      isPaywallOpen: false,
      isOpenSelectFundManager: false,
      isLoadingProducts: false,
      products: [],
      errorProducts: '',
      entityType2x: '',
      fundManagerOptions: [
        {value: 'Funds (AUM<$100M)', text: 'Funds (AUM < $100M)'},
        {value: 'Funds (AUM>$100M)', text: 'Funds (AUM > $100M)'},
      ],
      fundManagerType: '',
      isDownloadingPDF : false,
      isPortfolioVisible: true,
      rawData: [],
      isInsightsModalVisible: false,
      entityMap: {
        "financial-institution": "Financial Institution",
        "fund-manager": "Fund Manager",
        "start-up": "Start-up",
        sme: "SME",
        corporate: "Corporate",
      },
    };
  },
  components: {
    EquiloButton,
    Paywall,
    pageLoader,
    OverviewBoxCertification,
    StepsComponentTwox,
    MinSurveyStepsComponentTwox,
    Modal,
    ClipLoader,
    TwoXFundManagerAUM,
    InsightsModal
  },
  methods: {
    async getStructuredData() {
      const entity = this.entityMap[this.entityChosen] || this.entityChosen;

      try {
        const url = this.$route.query.teamId
          ? `${env.apiUrl}/twox-framework/fetch-data?teamId=${this.$route.query.teamId}`
          : `${env.apiUrl}/twox-framework/fetch-data`;
        const res = await axios.post(
          url,
          {
            page: "Full Assessment Summary Results",
            entity,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );

        if (res.status == 200) {
          this.twoXScoreOverall = this.certificationScoreData.level;
        }
        this.rawData = res.data;

      } catch (error) {
        console.error("Error fetching structured data:", error);
      }
    },
    tryPaywall() {
      if (this.entityType2x && this.entityType2x !== 'Fund Manager') {
        // User needs to do the payment
        this.getStripeProducts();
      } else if (this.entityType2x === 'Fund Manager') {
        // User needs to select Entity 'Funds (AUM<$100M)' | 'Funds (AUM>$100M)'
        this.isOpenSelectFundManager = true;
      } else {
        // TODO: financial-institution was selected, what to do here?
      }
    },
    async fetchCsvData() {
      let url = "";
      const _self = this;
      if (this.$route.query?.teamId) {
        url =
          "/certify2x/export-data?teamId=" +
          this.$route.query?.teamId +
          "&assessmentType=" +
          (this.$store.state.certPageAccess.isFullSurveyFill
            ? "Full"
            : "Min") +
          "&tag=" +
          this.entityChosen;
      } else {
        url =
          "/certify2x/export-data?teamId=" +
          this.user?.organization?._id +
          "&assessmentType=" +
          (this.$store.state.certPageAccess.isFullSurveyFill
            ? "Full"
            : "Min") +
          "&tag=" +
          this.entityChosen;
      }
      return axios
        .get(env.apiUrl + url, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then(function (res) {
          _self.csvData = res.data;
        }).catch(function (res) {
          console.error("Error fetching CSV data:", res);
        });
    },
    downloadXlsx() {
      if (this.isAllowedDownloadXLSX) {
        saveAs(
          this.csvData,
          this.certificationScoreData?.team_id?.name +
          "_2x" +
          " Indicative Assessment Results.XLSX"
        );
      } else {
        this.tryPaywall();
      }
    },
    async handleExportToPDF() {
      if (this.isAllowedDownloadXLSX) {
        const pdfName = `${this.certificationScoreData?.team_id?.name}'s 2X ${
          this.$route.path === "/2X-Challenge/2X-Challenge-Self-Assessment-Results"
            ? "Challenge Self-Assessment Results"
            : "Certification Self-Assessment Interim Results"
        }`;
        this.isDownloadingPDF = true;
        const element = this.$refs.pdfWrapper;
        try {
          await pdfExportHelpers.exportToPDF(element, pdfName, [".isPDF"]);
        } catch (error) {
          console.error("Error exporting to PDF:", error);
        } finally {
          this.isDownloadingPDF = false;
          [".isPDF"].forEach(selector => {
            const elementsToExclude = element.querySelectorAll(selector);
            elementsToExclude.forEach(el => {
              el.style.display = "inline-block";
              el.style.display = "flex";
            });
          });
        }
      } else {
        this.tryPaywall();
      }
    },
    navigateToUploadDocuments() {
      this.$router.push({ path: "/2X-Ecosystem/2X-Certification/UploadDocuments" });
    },
    isFullSurveyCompleted(surveyUrl) {
      return this?.certificationSurveyData?.some(
        (survey) =>
          survey.surveyId === surveyUrl &&
          survey?.metadata?.fullAssess &&
          survey.isComplete
      );
    },
    checkSurveysCompleted() {
      let minReqSurveyCount = 0;
      let fullSurveyCount = 0;

      this.certificationSurveyData.forEach((data) => {
        if (
          (data?.metadata?.minReq && data.isComplete) ||
          this.isFullSurveyCompleted("2x-intake") ||
          this.status2xCertification == "Min Survey Submitted"
        ) {
          minReqSurveyCount++;
        }
        if (data?.metadata?.fullAssess && data.isComplete) {
          fullSurveyCount++;
        }
      });
      // For min req surveys not considering ESg survey count
      if (
        (this.entityChosen == "fund-manager" ||
          this.entityChosen == "financial-institution" ||
          this.entityChosen == "Institutional investor") &&
        minReqSurveyCount == 10
      ) {
        this.isAllMinReqSurveysCompleted = true;
      }
      if (
        (this.entityChosen == "sme" ||
          this.entityChosen == "corporate" ||
          this.entityChosen == "start-up") &&
        minReqSurveyCount == 9
      ) {
        this.isAllMinReqSurveysCompleted = true;
      }
      if (
        (this.entityChosen == "fund-manager" ||
          this.entityChosen == "financial-institution" ||
          this.entityChosen == "Institutional investor") &&
        fullSurveyCount == 10
      ) {
        this.isAllFullSurveysCompleted = true;
      }
      if (
        (this.entityChosen == "sme" ||
          this.entityChosen == "corporate" ||
          this.entityChosen == "start-up") &&
        fullSurveyCount == 9
      ) {
        this.isAllFullSurveysCompleted = true;
      }
      if (this.$route.path.includes('2X-Certification-Self-Assessment')) {
        this.isAllAssessCompleted = this.isAllFullSurveysCompleted;
      } else {
        this.isAllAssessCompleted = this.isAllMinReqSurveysCompleted;
      }
    },
    takeFullAssess() {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment",
      });
    },
    sendVerificationRequest() {
      this.isPaywallOpen = false;

      axios
        .post(
          env.apiUrl + `/certify2x/2x-certification-verification-request`,
          { validate: true },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          this.isProceedToVerificationModalOpen = false;
          if (res.status == 200) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        });
    },
    calculateCertLevel() {
      if (this.entityChosen == "start-up") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "sme") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "corporate") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (
        this.entityChosen == "Fund Manager" ||
        this.entityChosen == "fund-manager"
      ) {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_403"];
      } else if (this.entityChosen == "financial-institution") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_404"];
      }
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.certificationScoreData?.ISO &&
        this?.certificationScoreData?.GEN_General_13
      ) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
              (item) =>
                item.industry == this.certificationScoreData?.GEN_General_13
            )?.[0]?.threshold;

      this.endofcreated = true;
    },
    async getThresholdsData(recordId, sector) {
      await apiService.fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
      });
    },
    async fetchCertificationData() {
      await apiService
        .fetch2xCertificationData(
          this.entityChosen,
          this.$route?.query?.teamId,
          this.$route.path == '/2X-Challenge/2X-Challenge-Self-Assessment-Results' ? "Min" : "Full"
        )
        .then(async (res) => {
          this.certificationScoreData = await res.data.data;
          await this.calculateCertLevel();
          await this.fetchPricingData();
          await this.calculateThreshold();
          if (this.certificationScoreData?.level == "Good") {
            this.certification_result = this.cert_categories.Good;
          } else if (
            this.certificationScoreData?.level == "Best in class" ||
            this.certificationScoreData?.level == "Best in Class"
          ) {
            this.certification_result = this.cert_categories["Best in Class"];
          } else if (this.certificationScoreData?.level == "Advanced") {
            this.certification_result = this.cert_categories.Advanced;
          }
          if (this.entityChosen == "fund-manager") {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_104 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.ENTOWN_2X_104);
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_104 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.LEAD_2X_104);
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_104 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.EMPLOY_2X_104);
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_104 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.PROD_2X_104);
            this.supplyChainPercent = this.certificationScoreData?.SUPCH_2X_104 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.SUPCH_2X_104);
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.PORT_Portfolio_1000);

            this.govStrategicAction =
              this.checkValueMoreThanHundred(this.certificationScoreData?.GOVACC_Strategic_action_103);
            this.govManagementSystem =
              this.checkValueMoreThanHundred(this.certificationScoreData?.GOVACC_Management_systems_re_gender_103);
            this.govData =
              this.checkValueMoreThanHundred(this.certificationScoreData?.GOVACC_Gender_disaggregated_data_114);
          } else {
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_101 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.ENTOWN_2X_101);
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_101 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.LEAD_2X_101);
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_101 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.EMPLOY_2X_101);
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_101 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.PROD_2X_101);
            this.supplyChainPercent = this.certificationScoreData?.SUPCH_2X_101 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.SUPCH_2X_101);
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == 'NA' ? 0 : this.checkValueMoreThanHundred(this.certificationScoreData?.PORT_Portfolio_1000);

            this.govStrategicAction =
              this.checkValueMoreThanHundred(this.certificationScoreData?.GOVACC_Strategic_action_102);
            this.govManagementSystem =
              this.checkValueMoreThanHundred(this.certificationScoreData?.GOVACC_Management_systems_re_gender_102);
            this.govData =
              this.checkValueMoreThanHundred(this.certificationScoreData?.GOVACC_Gender_disaggregated_data_113);
          }
          if (this.isPortfolioVisible && (this.entityChosen == "fund-manager" || this.entityChosen == "financial-institution")) {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
              this.portfolioPercent,
            ];
          } else {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
            ];
          }
          this.govChartData = [
            this.govStrategicAction,
            this.govManagementSystem,
            this.govData,
          ];
          if (this.entityChosen == "fund-manager") {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_304"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_304"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_304"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_304"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_304"];
          } else {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_300"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_300"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_300"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_300"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_300"];
          }
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    fetchPricingData() {
      let entity = this.entityChosen;
      if (this.entityChosen == "financial-institution") {
        entity = "Financial Institution";
      }
      if (this.entityChosen == "fund-manager") {
        entity = "Fund Manager";
      }
      if (this.entityChosen == "start-up") {
        entity = "Start-up";
      }
      if (this.entityChosen == "sme") {
        entity = "SME";
      }
      if (this.entityChosen == "corporate") {
        entity = "Corporate";
      }
      axios
        .get(
          env.apiUrl +
            "/certify2x/2x-certification-pricing?entityType=" +
            entity +
            "&twoXCertificationLevel=Good",
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          this.certificationPricingData = res.data.data;
        });
    },
    see2XThresholdCriteria() {
      let routeData = this.$router.resolve({
        path: "/2X-Ecosystem/Thresholds",
      });
      window.open(routeData.href, "_blank");
    },
    async getStripeProducts() {
      // Entity type is selected and, it's not "Fund Manager" or if it's the case
      //  the fundManagerType is not empty (>100M or <100M)
      if (
        this.entityType2x &&
        (this.entityType2x !== 'Fund Manager' || this.fundManagerType !== '')
      ) {
        this.isPaywallOpen = true;
        this.isLoadingProducts = true;
        this.errorProducts = '';

        try {
          const response = await axios.get(
            `${env.apiUrl}/subscriptions/twox-products?type=User&entityType=${this.entityType2x}`,
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("session")}`
              }
            }
          );
          const data = response.data.data;

          // This logic applies ONLY for FUND MANAGER, because the prices that depends on the AUM comes
          //  as part of the payload. So, we need to filter them first.
          let products = [];
          if (this.entityType2x === 'Fund Manager') {
            products = data.map((product) => {
              product.prices = product.prices.filter((price) => price.name.toLowerCase() === this.fundManagerType.toLowerCase());
              return product;
            });
          } else {
            products = data.map((product) => product);
          }

          // Checks if the user is TwoXEligible, if not, the "2X Certification" product is not included in the list of products
          if (!this.isTwoXEligible) {
            products = products.filter((product) => product.name !== "2X Certification");
          }

          this.products = products;
        } catch (error) {
          console.log(error);
          this.errorProducts = error;
          setTimeout(() => {
            this.errorProducts = "";
          }, 3000);
        } finally {
          this.isLoadingProducts = false;
        }
      }
    },
    proceedToVerification() {
      // User is able to proceed the verification
      if (this.isAbleToProceedVerification) {
        this.sendVerificationRequest();
      } else {
        this.tryPaywall();
      }
    },
    navigateAssessment(val) {
      const routes = {
        '/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment': {
          condition: this.status2xCertification == 'Full Survey Submitted' && !this.$store.state.certPageAccess.isUploadDocPage,
        },
        '/2X-Challenge/2X-Challenge-Self-Assessment': {
          condition: this.status2xCertification == 'Min Survey Submitted' && !this.$store.state.certPageAccess.isUploadDocPage,
        }
      };
      const route = routes[val];
      if (route) {
        const path = val;
        const query = route.condition ? { twoxRepeatAsses: 'true' } : undefined;

        this.$router.push({ path, query });
      }
    },
    checkValueMoreThanHundred(val) {
      if(val > 100 && this.status2xCertification == 'Min Survey Submitted') {
        return 100;
      } else {
        return val;
      }
    },
    /**
     * Method to get the Stripe link, to redirect the user to that paywall
     */
    async getStripeLink(priceIds) {
      if (!this.isAbleToProceedVerification) {
        try {
          this.isLoading = true;

          const certificationProduct = this.products
            .find((product) => product.name === "2X Certification");
          const levelUpProduct = this.products
            .find((product) => product.name === "Level-Up Package");
          const mlAddOnStripePriceId = levelUpProduct?.prices[0]?.addOnPrice?.stripePriceId ?? "";

          const indicativeResultsURL = `/2X-Ecosystem/2X-Certification/IndicativeResults`;
          const uploadDocumentsURL = `/2X-Ecosystem/2X-Certification/UploadDocuments`;
          const uploadDocumentsMLURL = `/2X-Ecosystem/upload-files`;
          const fullDetailedAssessmentURL = `/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results`;

          const hasCertificateCost = priceIds.includes(certificationProduct?.prices[0]?.stripePriceId);
          const hasLevelUp = priceIds.includes(levelUpProduct?.prices[0]?.stripePriceId);
          const hasMLAddOn = priceIds.includes(mlAddOnStripePriceId);

          // Check what page the user will reach. DO NOT alter this order.
          let landingURL = indicativeResultsURL;

          // Full detailed assessment page
          if (hasLevelUp) {
            landingURL = fullDetailedAssessmentURL;
          }

          // Go to Upload Documents ML page
          if (hasLevelUp && hasMLAddOn) {
            landingURL = uploadDocumentsMLURL;
          }

          // Upload document page
          if (hasCertificateCost) {
            landingURL = uploadDocumentsURL;
          }

          const encodedIndicativeURL = encodeURIComponent(landingURL);
          const redirectURL = `${window.location.origin}/#/check-session?type=User&teamId=${this.user?.organization?._id}&redirectURL=${encodedIndicativeURL}`

          const subscriptionAPI = new SubscriptionAPIs();
          const response = await subscriptionAPI.createCheckoutSession("Global", {
            type: "User",
            redirectUrl: redirectURL,
            priceIds: priceIds
          });
          window.location.href = response.data.data;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async handleOnBuyProducts(products) {
      const priceIds = products.map((product) => product.stripePriceId);
      await this.getStripeLink(priceIds);
    },
    onClickEvaluationResults() {
      if (this.isAllowedViewEvaluationResults) {
        this.$router.push({ path: '/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results' })
      } else {
        this.tryPaywall();
      }
    },
    checkDateOfSubmission() {
      const currentTime = new Date()
      const timeDiff = currentTime - new Date(this.dateOfSubmission);
      if (timeDiff <= 90000) { // 1.5 minutes in milliseconds
        this.showDataProcessingScreen = true;
      }
    },
    handleOnSelectFundManager(fundManagerType){
      this.isOpenSelectFundManager = false;
      this.fundManagerType = fundManagerType;
      this.getStripeProducts();
    }
  },
  async mounted() {
    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });
    await apiService.fetch2xUserSurvey("2x", this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id).then(async (response) => {
      this.certificationSurveyData = await response.data.data;
    });
    await apiService
    .fetch2xCertRequest(this.$route.query?.teamId ? this.$route.query?.teamId : this.user?.organization?._id)
    .then(async (response) => {
      this.dateOfSubmission = response.data.data?.fullSurveySubmittedDate || response.data.data?.minSurveySubmittedDate;
      this.status2xCertification = response?.data?.data?.status;
    });
    if(!this.certificationSurveyData) {
      this.$router.push({
        path: "/2X-Ecosystem/2X-Certification",
      });
    }
    this.checkDateOfSubmission();
    await this.$store.commit("setCertPageStatus", this.status2xCertification);
    if (
      !this.$store.state.certPageAccess.isFullSurveyFill &&
      !this.$store.state.certPageAccess.isMinSurveyFill
    ) {
      this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment" });
    }
    if (
      !this.$store.state.certPageAccess.isFullSurveyFill &&
      this.$store.state.certPageAccess.isMinSurveyFill &&
      this.$route.path == '/2X-Ecosystem/2X-Certification/IndicativeResults'
    ) {
      this.$router.push({ path: "/2X-Challenge/2X-Challenge-Self-Assessment-Results" });
    }
    if (this.certificationSurveyData) {
      this.certificationSurveyData?.forEach((data) => {
        if (data.surveyId === "2x-intake") {
          this.entityChosen = data.tag;
          this.entityType2x = this.entityMap[data.tag] ? this.entityMap[data.tag] : '';
        }
      });
    }

    await this.fetchCertificationData();
    this.isPortfolioVisible = !this.certificationScoreData?.['PORT_Portfolio_900']?.includes('none');
    if(this.entityChosen) {
      await this.fetchCsvData();
    }
    await this.checkSurveysCompleted();
    await this.getStructuredData();

    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    if(this.$route.path.includes("/2X-Ecosystem/2X-Certification/IndicativeResults")) {
      this.$store.commit("setBreadcrumbs", [
        {
          name: "2X Certification",
          url: "/2X-Ecosystem/2X-Certification",
        },
        {
          name: "Indicative Results",
          url: "/2X-Ecosystem/2X-Certification/IndicativeResults",
        },
      ]);
    } else {
      this.$store.commit("setBreadcrumbs", [
        {
          name: "2X Challenge",
          url: "/2X-Challenge",
        },
        {
          name: "2X Challenge Self-Assessment Results",
          url: "/2X-Ecosystem/2X-Certification/IndicativeResults",
        },
      ]);
    }
  },
  computed: {
    twoxEligible() {
      let val;
      if (this.entityChosen === "fund-manager") {
        val = this.certificationScoreData["2X_2X_Score_Overall_301"];
      } else if (this.entityChosen === "financial-institution") {
        val = this.certificationScoreData["2X_2X_Score_Overall_302"];
      } else if (
        this.entityChosen === "start-up" ||
        this.entityChosen === "sme" ||
        this.entityChosen === "corporate"
      ) {
        val = this.certificationScoreData["2X_2X_Score_Overall_300"];
      }
      return val;
    },
    isTwoXEligible() {
      return this.twoxEligible === 'Yes';
    },
    COM_Commitment() {
      let val;
      if (this.entityChosen === "fund-manager") {
        val = this.certificationScoreData.COM_Commitment_301;
      } else {
        val = this.certificationScoreData.COM_Commitment_300;
      }
      return val;
    },
    isVisibleUploadDocument() {
      return this.$store.getters.hasPurchasedCertificateCost;
    },
    isVisibleDownloadXLSX() {
      return this.$route.path === '/2X-Ecosystem/2X-Certification/IndicativeResults';
    },
    isAllowedDownloadXLSX() {
      return this.user?.featureFlags?.includes('2xDownload');
    },
    isAllowedViewEvaluationResults() {
      if (this.user?.isVerifier || this.user?.featureFlags?.includes('viewScore')) {
        return true;
      }
      return false;
    },
    isVisibleTakeFullAssessment() {
      return this.isAllMinReqSurveysCompleted &&
        this.status2xCertification === 'Min Survey Submitted' &&
        this.isTwoXEligible;
    },
    isVisibleProceedVerification() {
      return this.$route.path === '/2X-Ecosystem/2X-Certification/IndicativeResults' &&
        this.$store.state.certPageAccess.isFullSurveyFill &&
        this.status2xCertification === 'Full Survey Submitted' &&
        this.isTwoXEligible &&
        this.certification_result?.name;
    },
    isAbleToProceedVerification() {
      return this.user.featureFlags?.includes('proceedToVerification');
    },
  }
};
</script>

<style lang="scss" scoped>
.ContentWrapper {
  margin-top: 10px;

  .next-button {
    font-weight: bold;
    color: white;
    background: #19486a;
  }

  .standard-button {
    font-weight: bold;
    font-size: 16px;
    color: #19486a;
    border-style: solid;
    border-color: #19486a;
    border-width: 1px;
  }

  .certification-button {
    @apply bg-navy;
    font-weight: bold;
    color: white;
    font-size: 16px;
  }

  .disabled {
    @apply bg-grey-70;
  }

  p {
    color: #666461;
  }
  .congrats-box-prelim-main {
    border: 1px solid #ccc;
    border-radius: 15px;
  }
  .congrats-box-prelim {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding-right: 200px;
    padding-left: 200px;
  }

  .theme-box {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
  }

  .theme-badge {
    border: 2px solid;
    border-radius: 10px;
    margin: 20px 5px 0px;
    padding: 10px;
    height: 100%;
    font-size: 14px;
  }
}

h1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.learn_more_button {
  cursor: pointer;
}

.cross_Icon {
  position: absolute;
  right: 15px;
  top: 18px;
  cursor: pointer;
}

.grey-out {
  pointer-events: none;
  opacity: 0.7;
}

router-link {
  cursor: pointer;
}

.ecosystem-landing {
  background-color: #f4f1ed;
  color: #21191d !important;
  font-family: Work Sans !important;
  padding-top: 12em;

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .bg-text-style {
      margin-left: 9rem;
      position: relative;
      top: 20rem;
      color: #f4f1ed;
      width: 570px;
    }

    .PoweredbyTitleWrepper {
      max-width: 847px;
      display: flex;

      // align-items: center;
      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #19486a;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .bg-image {
    background-image: linear-gradient(
        201deg,
        rgba(0, 0, 0, 0.1) 55%,
        rgba(221, 166, 58, 0.8) 100%
      ),
      url("./../../assets/images/2x-challenge-bg.png");
    height: 700px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0;
    position: relative;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .Modal_Readmorestyle {
    position: relative;
    display: flex;

    .ModalInnerStyle {
      background: #fff;

      .heading-section {
        gap: 28px;
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #dda63a;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .textarea-section {
        width: 552px;
        gap: 12px;

        textarea {
          margin: 0;
          min-width: 576px;
          min-height: 120px;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 16px;
        }

        textarea::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .cancel-button {
        width: auto;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #dda63a;
        border: 1px solid #dda63a;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .save-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #19486a;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .congrats-box-doc {
        border: 1px solid #2177c1;
        border-radius: 16px;
        padding: 32px, 0px, 32px, 0px;
        background: #eaf6ff;
        border-style: dashed;
        height: 160px;
      }
    }
  }

  .what-modal {
    padding: 24px;

    .content {
    }

    .modal-link-button {
      font-weight: bold;
      color: #19486a;
      border-style: solid;
      border-color: #19486a;
      border-width: 1px;
      padding: 12px 24px 12px 24px;
      width: 250px;
      font-size: 16px;
    }

    .modal-send-button {
      font-weight: bold;
      color: white;
      background-color: #4c9f38;
      border-radius: 8px;
      border-width: 1px;
      font-size: 16px;
      width: 250px;
    }

    .center-span-modal {
      position: relative;
      top: 35px;
      line-height: 20px;
      font-family: arial;
      color: #19486a;
    }

    .upload-documents-button {
      font-weight: bold;
      color: white;
      background: #2177c1;
      // width: 150px;
      padding: 8px 16px 8px 16px;
    }

    .center-button {
      position: relative;
      top: 27px;
    }
  }
  .bottom-button-section {
    margin-top: 143px;
    height: 120px;
    padding: 36px 80px 36px 80px;
    border-top: 1px solid #e1e3e9;
    gap: 24px;
    box-shadow: 0px -2px 20px 0px #636e801a;

    button {
      // width: 252px;
      // height: 48px;
      gap: 20px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      border: 1px solid #19486a;
      color: #19486a;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
  .modal {
    display: block;
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;
      .eligible-level-section {
        margin: 28px 0;
        display: flex;
        padding: 16px;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 12px;
        background: #f4f6f9;
        .eligible-level-text {
          color: var(--grey-grey-100, #2c2e35);
          text-align: center;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        img {
          width: 80px;
        }
      }
      .certification-details {
        .inner-certification-details {
          display: flex;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          .image-div {
            height: 20px;
            img {
              padding: 0;
              height: 36px;
              width: 50px;
            }
          }
          img {
            display: flex;
            width: 36px;
            height: 36px;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 6px;
          }
          .write-color {
            background: var(--secondary-10, #eaf6ff);
          }
          .layer-color {
            background: var(--red-light, #ffece8);
          }
          .letter-color {
            background: var(--yellow-light, #fff8e0);
          }
          .check-color {
            background: var(--turquoise-light, #e5fafa);
          }
          .calendar-color {
            background: var(--secondary-10, #eaf6ff);
          }
          .text {
            color: var(--grey-grey-100, #2c2e35);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            height: 20px;
            line-height: 12px;
          }
          .text-for-promt {
            color: var(--grey-grey-100, #2c2e35);
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            height: 20px;
            line-height: 12px;
            align-items: center;
            display: flex;
          }
        }
      }
      .amount-button-section {
        padding: 16px 0 0 0;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid var(--grey-grey-20-line, #e1e3e9);
        .amount {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .amount-bold {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }
        .amount-details {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        .button-section {
          button {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--primary-100, #19486a);
            margin-right: 5px;
          }
          .cancel-button {
            color: var(--primary-100, #19486a);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 5px;
          }
          .save-button {
            color: var(--white, #fff);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            background: var(--primary-100, #19486a);
          }
        }
        .privacy-notice-disclosure {
          color: var(--grey-grey-50, #666461);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: right;
        }
        .inner-data-privacy {
          color: var(--grey-grey-50, #666461);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-decoration-line: underline;
        }
      }
    }
  }
}

.pointer-events-none {
  pointer-events: none;
  opacity: 0.7;
}

input {
  margin: 6px;
}

#SPAN_1:hover {
  color: #40a9ff;
}

.bar {
  filter: drop-shadow(0 4px 6px rgba(59, 130, 246, 0.1));
}
</style>
