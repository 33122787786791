<template>
  <div class="flex flex-row w-full bg-gray-100">
    <sidebar></sidebar>
    <div class="px-10 py-10 w-full center-items" style="padding-top: 100px">
      <div class="flex flex-row overflow-x-auto w-full">
        <div
          class="bg-white shadow overflow-hidden rounded-lg border border-gray-200mr-10"
          style="height: 320px; width: 320px"
        >
          <input
            @change="setProfilePicture"
            type="file"
            ref="file"
            style="display: none"
          />
          <div
            ref="avatarPreview"
            @click="$refs.file.click()"
            :style="userProfileImg"
            style="
              cursor: pointer;
              height: 320px;
              width: 320px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            "
          ></div>
        </div>
        <div class="sm:px-6 lg:px-8 w-7/12">
          <div class="bg-white shadow overflow-hidden sm:rounded-lg relative">
            <div
              class="absolute top-0 left-0 w-full h-full"
              style="z-index: 9; background-color: rgba(255, 255, 255, 0.6)"
              v-if="isLoading"
            >
              <clip-loader
                :color="env.brandingColors.teal"
                :size="'60px'"
                class="absolute top-50 left-50"
                style="transform: translate(-50%, -50%)"
              ></clip-loader>
            </div>
            <div class="px-4 py-5 pb-5 border-b border-gray-200 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Your account
              </h3>
              <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                Personal details.
              </p>
            </div>
            <div class="mt-5">
              <dl>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Your first name
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 w-full"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full notranslate"
                      placeholder="John"
                      v-model="user.firstname"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Your last name
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full notranslate"
                      placeholder="Doe"
                      v-model="user.lastname"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Your email address
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full notranslate"
                      placeholder="john@example.com"
                      v-model="user.email"
                    />
                  </dd>

                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Your language
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 notranslate"
                  >
                    <select
                      v-model="user.languagePreference"
                      @change="selectLanguage($event)"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      id="grid-state"
                    >
                      <option
                        v-for="(lang, i) in languageOptions"
                        :key="i"
                        :value="lang"
                      >
                        {{ lang }}
                      </option>
                    </select>
                  </dd>
                  <!-- <dt class="text-sm leading-5 font-medium text-gray-500">
                      Plan Details
                    </dt>
                    <dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 notranslate">
                        <div v-for="subscriptionInfo in subscriptionInfo?.productArr" v-bind:key="subscriptionInfo._id" >
                          <label for="">{{subscriptionInfo.subscription}}</label>
                        </div>
                    </dd> -->
                </div>
                <div
                  v-if="user.admin && typeof consentFlagValue == 'boolean'"
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    2XC Consent
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <div class="toggle-button-section">
                      <p>
                        <span
                          class="text-2xl tracking-tighter font-medium text-center print:text-7xl"
                        ></span>
                        <label class="toggleSwitch large">
                          <input
                            v-model="consentFlagValue"
                            type="checkbox"
                            id="allowOthersToViewProfile"
                            :checked="consentFlagValue == true ? true : false"
                          />
                          <span>
                            <span></span>
                            <span></span>
                          </span>
                          <a></a>
                        </label>
                        <span
                          class="text-2xl tracking-tighter font-medium text-center print:text-7xl ml-4"
                        ></span>
                      </p>
                    </div>
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Your Entity
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 w-full"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 bg-gray-100 mb-4 rounded-lg px-3 py-2 text-sm w-full notranslate"
                      placeholder="John"
                      :value="userEntityType"
                      disabled
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Your Subscription
                  </dt>
                  <dd
                    class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                      <input
                        v-if="subscriptionInfo?.productArr?.length == 0"
                        type="text"
                        class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                        value="Equilo Open"
                        readonly
                        style="color: black"
                      />

                      <span
                        v-else
                        v-for="subscriptionInfo in subscriptionInfo?.productArr"
                        v-bind:key="subscriptionInfo._id"
                      >
                        <input
                          type="text"
                          class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                          v-model="subscriptionInfo.subscription"
                          readonly
                          style="color: black"
                        />
                      </span>
                      <button
                        v-if="
                          user.admin &&
                          user.organization.stripeCustomerId &&
                          user.organization.subscriptionStatus == 'Subscribed'
                        "
                        @click="redirectforcustomerportal()"
                        style="
                          background-color: #fe5000;
                          color: white !important;
                          cursor: pointer;
                        "
                        class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                      >
                        Subscriptions
                      </button>
                      <button
                        v-else-if="
                          user.admin &&
                          !user.organization.stripeCustomerId &&
                          user.organization.subscriptionStatus != 'Subscribed'
                        "
                        @click="redirecttoupgrade()"
                        style="
                          background-color: #fe5000;
                          color: white !important;
                        "
                        class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4"
                      >
                        <span>Upgrade</span>
                      </button>
                      <button
                        v-else-if="
                          user.admin &&
                          !user.organization.stripeCustomerId &&
                          user.organization.subscriptionStatus == 'Subscribed'
                        "
                        @click="redirectToEquilo()"
                        style="
                          background-color: #fe5000;
                          color: white !important;
                        "
                        class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4"
                      >
                        <span>Upgrade/Manage Billing</span>
                      </button>
                    </p>
                  </dd>
                </div>
                <div
                  v-if="rewardfulAffliateLink"
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Affiliate Link
                  </dt>
                  <dd
                    class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                      <input
                        type="text"
                        class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                        :value="rewardfulAffliateLink"
                        readonly
                        style="color: black"
                      />
                    </p>
                  </dd>
                </div>
                <div
                  v-if="env.enviorment == 'prod'"
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  ></dt>
                  <dd
                    class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <span
                      v-if="user.rewardfulAffiliateId"
                      class="mt-1 max-w-2xl text-sm leading-5 text-gray-500 underline cursor-pointer inline-block"
                      style="color: #40a9ff"
                      @click="goToAffiliateDashboard(user.rewardfulAffiliateId)"
                    >
                      View ambassador dashboard
                    </span>
                    <span
                      v-else
                      class="mt-1 max-w-2xl text-sm leading-5 text-gray-500 underline cursor-pointer inline-block"
                      style="color: #40a9ff"
                      @click="becomeAffiliate()"
                    >
                      Become an ambassador
                    </span>
                  </dd>
                </div>
              </dl>
              <button
                @click="update"
                class="text-white my-5 mr-5 py-2 px-4 rounded-lg font-medium float-right text-sm hover:shadow-lg"
                style="background-color: rgb(32, 23, 71)"
              >
                Update account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import ClipLoader from "vue-spinner/src/ClipLoader";
import { useUserStore } from "@/stores/user";
import { ORGANIZATION_TYPES } from "@/helpers/constants";

export default {
  name: "Overview",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  data() {
    return {
      user: [],
      profilePic: "",
      userPicture: "",
      languageOptions: [],
      subscriptionInfo: [],
      consentFlagValue: "",
      rewardfulAffliateLink: "",
      env,
      isLoading: false,
      organizationTypeList: ORGANIZATION_TYPES,
    };
  },
  components: {
    sidebar,
    ClipLoader,
  },
  methods: {
    validEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    async getConsentValue() {
      try {
        const { data } = await axios.get(
          `${env.apiUrl}/assesment2x/get-consent-value`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        return data.data;
      } catch (err) {
        console.error("failed to get consent value:", err);
        throw new Error("Failed to get consent value");
      }
    },

    selectLanguage({ target: { options, selectedIndex } }) {
      if (selectedIndex > -1) {
        this.user.languagePreference = options[selectedIndex].text;
      }
    },

    async update() {
      try {
        if (!this.validEmail(this.user.email)) {
          throw new Error("Please enter valid email address");
        }

        const body = {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          email: this.user.email,
          picture: this.profilePic,
          languagePreference: this.user.languagePreference,
          ...(typeof this.consentFlagValue === "boolean" && {
            consent: this.consentFlagValue,
          }),
        };

        await axios.put(`${env.apiUrl}/users/me`, body, {
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
          },
        });

        alert("Your profile has been saved.");
        window.location.reload();
      } catch (err) {
        console.error("failed to update profile:", err);
        alert(err.message || "Failed to update profile. Please try again.");
      }
    },

    async getSubscriptions() {
      try {
        const { data } = await axios.get(
          `${env.apiUrl}/subscriptions/get-subscription-info`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        return data.data;
      } catch (err) {
        console.error("Failed to get subscriptions:", err);
        throw new Error("Failed to fetch subscription info");
      }
    },

    redirecttoupgrade() {
      this.$router.push("/upgrade");
    },

    async redirectforcustomerportal() {
      try {
        const { data } = await axios.post(
          `${env.apiUrl}/payments/create-customer-portal-session`,
          { stripeCustomerId: this.user.organization.stripeCustomerId },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );
        window.location.href = data.data.url;
      } catch (err) {
        console.error("Failed to create customer portal session:", err);
        throw new Error("Failed to access customer portal");
      }
    },

    redirectToEquilo() {
      window.open("https://www.equilo.io/general-contact", "_blank");
    },

    async setProfilePicture({ target: { files } }) {
      if (!files?.[0]) return;

      const formData = new FormData();
      formData.append("type", "profile");
      formData.append("files", files[0]);

      try {
        const { data } = await axios.post(
          `${env.apiUrl}/other/fileUpload`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );

        this.profilePic = data.data[0].url;
        this.$refs.avatarPreview.style.backgroundImage = `url("${this.profilePic}")`;
      } catch (err) {
        console.error("Failed to upload profile picture:", err);
        throw new Error("Failed to upload profile picture");
      }
    },

    async getRewardfulAffiliateLink(id) {
      try {
        const { data: rewardfulAffiliate } = await axios.get(
          `${env.apiUrl}/rewardful/affiliate/${id}`
        );
        return rewardfulAffiliate.links?.[0]?.url ?? "";
      } catch (err) {
        console.error("Failed to get affiliate link:", err);
        return "";
      }
    },

    async goToAffiliateDashboard(id) {
      this.isLoading = true;
      try {
        const { data: magicLink } = await axios.get(
          `${env.apiUrl}/rewardful/affiliate/${id}/sso`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );

        if (!magicLink.error) {
          window.open(magicLink, "_blank");
        }
      } catch (err) {
        console.error("Failed to access affiliate dashboard:", err);
      } finally {
        this.isLoading = false;
      }
    },

    async becomeAffiliate() {
      this.isLoading = true;
      try {
        const { data: rewardfulAffiliate } = await axios.post(
          `${env.apiUrl}/rewardful/affiliate`,
          {},
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("session")}`,
            },
          }
        );

        if (!rewardfulAffiliate.error) {
          this.user.rewardfulAffiliateId = rewardfulAffiliate.id;
        }
      } catch (err) {
        console.error("Failed to become affiliate:", err);
      } finally {
        this.isLoading = false;
      }
    },

    setBreadCrumbs() {
      const defaultCrumbs = [
        { name: "Settings", url: "/settings/account" },
        { name: "Account", url: "/settings/account" },
      ];

      const breadcrumbs = this.$store.getters.showChallengeInBreadcrumb
        ? [{ name: "2X Challenge", url: "/2X-Challenge" }, ...defaultCrumbs]
        : defaultCrumbs;

      this.$store.commit("setBreadcrumbs", breadcrumbs);
    },
  },

  async mounted() {
    try {
      this.setBreadCrumbs();
      this.user = this.userStore.user ?? (await this.userStore.fetchUser());
      this.subscriptionInfo = await this.getSubscriptions();

      const { consentFlag } = await this.getConsentValue();
      this.consentFlagValue =
        consentFlag === "Yes" ? true : consentFlag === "No" ? false : "";

      this.languageOptions = this.user.languagePreferenceList;
      this.$store.commit("isFreeState", true);
      this.$store.commit("downloadable", false);
      this.userPicture = this.user.picture.split(" ").join("%20");
    } catch (err) {
      console.error("Error during component mount:", err);
    }
  },

  computed: {
    userProfileImg() {
      return {
        backgroundImage: `url("${this.userPicture}")`,
      };
    },
    userEntityType() {
      return this.organizationTypeList.find(
        (type) => type.code === this.user.interest
      )?.name || "";
    }
  },
};
</script>
<style scoped>
/*  Toggle Switch  */
.toggleSwitch span span {
  display: none;
}
@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    cursor: pointer;
    width: 40px;
  }
  .toggleSwitch * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }
  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }
  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }
  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 50%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }
  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: 15%;
    top: -1px;
    opacity: 0;
  }
  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    left: 4px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .toggleSwitch > span span:first-of-type {
    color: #ccc;
    opacity: 1;
    left: 45%;
  }
  .toggleSwitch > span:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50px;
    top: -2px;
    background-color: #fafafa;
    border: 1px solid #ccc;
    border-radius: 30px;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }
  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }
  .toggleSwitch input:checked ~ span:before {
    border-color: #0097d1;
    box-shadow: inset 0 0 0 30px #0097d1;
  }
  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
  /* Switch Sizes */
  .toggleSwitch.large {
    width: 70px;
    height: 30px;
    top: 5px;
  }
  .toggleSwitch.large a {
    width: 17px;
    height: 17px;
    top: 1.8px;
  }
  .toggleSwitch.large > span {
    height: 25px;
    line-height: 35px;
  }
  .toggleSwitch.large input:checked ~ a {
    left: 36px;
  }
  .toggleSwitch.large > span span {
    font-size: 1.1em;
  }
  .toggleSwitch.large > span span:first-of-type {
    left: 50%;
  }
}
.team-label {
  padding-top: 20px;
}
/*  End Toggle Switch  */
</style>
