<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import Survey from "@/components/surveys/survey.vue";
import auth from "@/helpers/auth";
import SurveyAnswerAPI from "@/api/SurveyAnswerAPI";
import SurveyAPI from "@/api/SurveyAPI";
import { checkWhiteLabel } from "@/components/imm-components/white-label";
import localLoader from "@/components/local-loader.vue";

export default {
  name: "DealPipelineDetails",
  setup() {
    const surveyAPI = new SurveyAPI();
    const surveyAnswerAPI = new SurveyAnswerAPI();
    return { surveyAPI, surveyAnswerAPI };
  },
  components: { localLoader, Survey, BreadCrumbs },
  data() {
    return {
      surveyId: "",
      titlePage: "",
      logo: "",
      dealId: null,
      surveyJson: {},
      surveyData: null,
      id: null,
      userType: null,
      user: null,
      loading: false,
      isSweef: false,
      companyNameQuestionId: 'INTAKE_Deal_1',
    };
  },
  methods: {
    saveCurrentProgress() {
      this.$root.$emit("saveCurrentProgressFromParent");
    },
  },
  async mounted() {
    this.loading = true;
    this.dealId = this.$route.query.dealId;

    // Fetch user info
    try {
      this.user = await auth.me();
      const whiteLabel = checkWhiteLabel(this.user.featureFlags);
      this.surveyId = whiteLabel.deals.dealsSurveyId;
      this.titlePage = `Deal Overview: Loading...`;
      this.logo = whiteLabel.logo;
    } catch (error) {
      console.error("Error fetching user:", error);
    }

    // Determine if the survey is related with Sweef Capital
    this.isSweef = this.user.featureFlags.includes('imm-sweef');

    // Fetch all surveys by this user
    const response = await this.surveyAnswerAPI.getUserAnswersBySurveyType('imm');
    const surveyAnswers = response.data.data;
    if (surveyAnswers) {
      this.id = surveyAnswers.find((surveyAnswer) => surveyAnswer.user_id === this.dealId)?._id;
    }

    // Breadcrumb
    this.$store.commit("setNewBreadcrumbs", [
      { name: "IMM", url: "/imm/landing-page" },
      { name: "Dashboard", url: "/imm/dashboard" },
      { name: this.isSweef ? "Sweef Deal Pipeline" : "Deal Pipeline", url: "/imm/deal-pipeline" }
    ]);

    // Fetch survey questions
    try {
      const {
        data: { data },
        status
      } = await this.surveyAPI.getSurvey(this.surveyId, this.userType);
      if (status === 200) {
        this.surveyJson = data.json;
      }
    } catch (error) {
      console.error("Error fetching survey questions:", error);
    }

    // Get deal id
    if (this.dealId) {
      try {
        const response = await this.surveyAnswerAPI.getSurveyAnswer(this.surveyId, this.dealId);
        this.surveyData = response.data.data;
        if (this.surveyData) {
          let surveyDataObject = {};
          this.surveyData.surveyResponse.forEach((survey) => {
            surveyDataObject[survey.questionId] = survey.answer;

            if (survey.questionId === this.companyNameQuestionId) {
              this.titlePage = `Deal Overview: ${survey.answer || 'N/A'}`;
            }
          });
          this.surveyData = surveyDataObject;
        }
      } catch (e) {
        console.error("Error fetching survey answer:", e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<template>
  <div class="static">
    <div class="main-container pb-20 mx-20">
      <BreadCrumbs></BreadCrumbs>
      <div v-if="loading">
        <div class="flex flex-col items-center justify-center h-full">
          <local-loader />
        </div>
      </div>
      <template v-else>
        <div class="mt-12 mb-4 flex flex-row flex-start items-center gap-8">
          <!-- LOGO -->
          <img
            v-if="logo === 'sweef'"
            src="@/assets/images/roi/sweef-large.png"
            class="w-32 h-32 object-contain"
            alt="logo-image"
          />
          <!-- Title -->
          <h3 class="font-bold text-4xl">{{ titlePage }}</h3>
        </div>
        <div class="divider"></div>
        <div v-if="id && surveyData">
          <Survey
            :survey-data="surveyData"
            :survey-json="surveyJson"
            :user="user"
            :survey-id="surveyId"
            :answer-again="false"
            :id="id"
            :isComplete="true"
            :userType="userType"
            :display-page-tabs="true"
            :white-label="{logo: null}"
            :hide-navigation-buttons="true"
            :hide-header="true"
            :deal-id="dealId"
          />
          <div class="w-full relative">
            <button
              v-if="surveyData"
              class="focus:outline-none mx-1 my-2 bg-[#191B45] transition duration-150 ease-in-out
              font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg absolute top-[-8rem] right-[12rem]"
              @click="saveCurrentProgress"
            >
              Save
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.divider {
  border: none;
  height: 2px;
  background-color: #ccc;
  margin: 2rem 0;
  width: 100%;
}
</style>
