<template>
  <div class="flex flex-col gap-4 justify-between my-5">
    <!-- total SDGs tracked section -->
    <div class="flex flex-col gap-2">
      <div class="flex items-center justify-between gap-2">
        <span class="text-2xl font-bold">{{ totalSdgs }}%</span>
        <span class="text-sm font-semibold text-[#4dc9be]"
          >↗ {{ sdgIncrease }}% increase</span
        >
      </div>
      <span class="text-sm font-semibold text-gray-500">SDGs tracked</span>
    </div>

    <!-- SDGs breakdown -->
    <div class="flex flex-col gap-2">
      <div class="flex flex-col gap-3">
        <div class="flex flex-col">
          <span class="font-semibold">{{ sdgGenderEquality }}%</span>
          <span class="text-sm text-gray-500">SDG 5: Gender Equality</span>
        </div>
        <div class="flex flex-col">
          <span class="font-semibold">{{ sdgEducation }}%</span>
          <span class="text-sm text-gray-500">SDG 4: Education</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImpactModuleCardContent",
  props: {
    totalSdgs: {
      type: Number,
      default: 15,
    },
    sdgIncrease: {
      type: Number,
      default: 5,
    },
    sdgGenderEquality: {
      type: Number,
      default: 25,
    },
    sdgEducation: {
      type: Number,
      default: 10,
    },
  },
};
</script>
