var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 mt-16"},[_c('div',{staticClass:"max-w-7xl mx-auto"},[_c('div',{staticClass:"mb-8"},[_c('h1',{staticClass:"text-3xl font-bold text-gray-900"},[_vm._v(_vm._s(_vm.page))]),_c('p',{staticClass:"mt-2 text-xl text-gray-500"},[_vm._v("Dashboard overview and metrics")])]),(_vm.loading)?_c('div',{staticClass:"flex justify-center items-center h-64"},[_c('div',{staticClass:"animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"})]):_c('div',{staticClass:"bg-white shadow rounded-lg p-6"},[(_vm.dashboardData.length === 0)?_c('div',[_vm._v("No data available")]):_vm._e(),_vm._l((_vm.uniqueIndicators),function(indicator,indicatorIndex){return _c('div',{key:indicatorIndex,staticClass:"mb-8"},[_c('h2',{staticClass:"text-2xl font-bold text-gray-800 mb-4"},[_vm._v(" "+_vm._s(indicator[0])+" ")]),_c('table',{staticClass:"divide-y divide-gray-200"},[_c('thead',[_c('tr',{staticClass:"w-[100px]"},[_c('th',{staticClass:"px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"},[_vm._v(" Survey Question ")]),_vm._l((_vm.groupedData),function(period,periodIndex){return _c('th',{key:periodIndex,staticClass:"px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"},[_vm._v(" "+_vm._s(period.date)+" ")])})],2)]),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200"},_vm._l((_vm.getQuestionsForIndicator(
                indicator
              )),function(question,questionIndex){return _c('tr',{key:questionIndex},[_c('td',{staticClass:"px-6 py-4 text-sm font-medium text-gray-900"},[_vm._v(" "+_vm._s(question)+" "),(_vm.getChartForQuestion(question, indicator))?_c('div',{staticClass:"mt-4"},[(
                      _vm.getChartForQuestion(question, indicator).chartType ===
                      'Bar'
                    )?_c('PlotBarChart',{attrs:{"data":_vm.getChartForQuestion(question, indicator)}}):_vm._e()],1):_vm._e()]),_vm._l((_vm.groupedData),function(period,periodIndex){return _c('td',{key:periodIndex,staticClass:"px-6 py-4 text-sm text-gray-500"},[(period.indicators[indicator])?_c('div',_vm._l((_vm.filterItemsByQuestion(
                      period.indicators[indicator].items,
                      question
                    )),function(item){return _c('div',{key:item.uniqueId},[(
                        item.chartType !== 'Bar' || item.chartType !== 'Line'
                      )?_c('p',{staticClass:"mt-2 text-gray-600"},[_vm._v(" "+_vm._s(item.value || "No data available")+" ")]):_vm._e()])}),0):_c('div',[_vm._v("No data available")])])})],2)}),0)])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }