import Vue from "vue";
import Router from "vue-router";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

// Projects pages
import countryReportOverview from "@/pages/reports/country-detail.vue";
import countryReportImprovements from "@/pages/reports/country-improvements.vue";
import countryChoose from "@/pages/reports/country-choose.vue";
import sectorChoose from "@/pages/reports/sector-choose.vue";

import countryReportThemes from "@/pages/reports/country-themes.vue";
import countryReportThemeDetail from "@/pages/reports/country-theme.vue";

import countryReportDomains from "@/pages/reports/country-domains.vue";
import countryReportDomainDetail from "@/pages/reports/country-domain.vue";
import gbvRiskHome from "@/pages/reports/gbv-risk-home.vue";
import gbvRiskDetail from "@/pages/reports/gbv-risk-detail.vue";
import whatWorks from "@/pages/reports/country-whatworks.vue";

import login from "@/pages/auth/login.vue";
import signup from "@/pages/auth/signup.vue";
import firstTime from "@/pages/auth/first-time.vue";
import reset_password from "@/pages/auth/reset_password.vue";
import set_password from "@/pages/auth/set_password.vue";
import maintenancePage from "@/pages/auth/maintenancePage.vue";

import settingsAccount from "@/pages/settings/account.vue";
import changePassword from "@/pages/settings/changePassword.vue";
import team from "@/pages/settings/team.vue";
import members from "@/pages/settings/members.vue";
import userRequest from "@/pages/settings/userRequest.vue";
import plans from "@/pages/settings/plans.vue";
import planDetail from "@/pages/settings/planDetail.vue";
import creditcard from "@/pages/settings/creditcard.vue";
import ach from "@/pages/settings/ach.vue";
import autorenew from "@/pages/settings/autorenew.vue";
import twoXC from "@/pages/settings/2XC.vue";
import subscriptionNew from "@/pages/settings/subscriptionNew.vue";

import methodology from "@/pages/reports/gesi-methodology.vue";
import demoVideo from "@/pages/settings/demoVideo";
import invoices from "@/pages/settings/invoices.vue";
import invoice from "@/pages/settings/invoice.vue";
import configureSubscription from "@/pages/settings/subscription.vue";
import configureSubscriptionSector from "@/pages/settings/subscription-sector.vue";

import notAllowed from "@/pages/reports/notAllowed.vue";
import welcome from "@/pages/reports/welcome.vue";
import globalAnalysis from "@/pages/reports/global-analysis.vue";

import homePage from "@/pages/home/homePage.vue";
import supergirl from "@/pages/reports/supergirl.vue";
import partnerdirectory from "@/pages/reports/partnerdirectory.vue";
import equilolandDemo from "@/pages/reports/equilolandDemo.vue";
import tutorials from "@/pages/reports/tutorials.vue";
import TAServiceProviderSubCard from "@/pages/reports/TAServiceProviderSubCard.vue";
import gbvRiskMethodology from "@/pages/reports/gbv-risk-methodology.vue";
import gbvRiskHeatMap from "@/pages/reports/gbv-risk-heatmap.vue";
import gbvRiskMateriality from "@/pages/reports/gbv-risk-materiality.vue";
import gbvRiskRiskModels from "@/pages/reports/risk-models.vue";

import supportFeedback from "@/pages/reports/support-feedback.vue";
import gesiNotAllowed from "@/pages/reports/gesi-not-allowed.vue";
import twoxThresholds from "@/pages/reports/my2xThresholds.vue";
import twoxThresholdsGlobal from "@/pages/reports/my2xThresholdsGlobal.vue";
import twoxThresholdsCSV from "@/pages/reports/my2xThresholdsCSV.vue";
import my2xThresholdsPaywall from "@/pages/reports/my2xThresholdsPaywall.vue";

import twoxCertify from "@/pages/reports/my2xCertification-Intro.vue";
import twoXChallenge from "@/pages/reports/2xChallenge.vue";
import twoxCertifyAssessmentSurveyPage from "@/pages/reports/2xCertAssessmentSurveyPage.vue";
import twoxCertifyIndicativeResults from "@/pages/reports/my2xCertification-PreliminaryResults.vue";
import selfAssessResults from "@/pages/reports/my2xCertification-SelfAssessResults.vue";
import twoxCertifyGlobalCertification from "@/pages/reports/my2xCertification-GlobalCertification.vue";
import twoxCertifyVerification from "@/pages/reports/my2xCertification-Verification.vue";
import twoxCertifyUploadDocuments from "@/pages/reports/my2xCertification-UploadDocuments.vue";
import twoxCertifyAssessmentFullResult from "@/pages/reports/my2xCertificationFullAssessmentResult/my2xCertification-Full-AssessmentResult.vue";
import My2XCertificationThemeBadgePage from "@/pages/reports/my2xCertification-ThemeBadgePage.vue";
import My2xCertificationNewScoreCard from "@/pages/reports/my2xCertification-2XNewScoreCard.vue";
import My2XCertificationSubDimension from "@/pages/reports/subDimension/my2xCertification-SubDimension.vue";
import twoxSelfAssessment from "@/pages/reports/2xSelfAssessment.vue";
import twoXPortfolioSetup from "@/pages/reports/2xPortfolioSetup.vue";

import requestassesment from "@/pages/reports/requestassesment.vue";
import newtwoxassesment from "@/pages/reports/newtwoxassesment.vue";
import newtwoxassesmentorg from "@/pages/reports/newtwoxassesmentorg";
import newtwoxassesmentfunds from "@/pages/reports/newtwoxassesmentfunds";
import newtwoxassessmentfis from "@/pages/reports/newtwoxassessmentfis";
import newtwoxmethodology from "@/pages/reports/newtwoxmethodology";
import myFavAssessments from "@/pages/reports/myFavAssessments";
import my2xassementDeals from "@/pages/reports/my2xassementDeals";
// import indicatorBlurbs from "@/pages/reports/indicatorBlurbs";
// import contextIndicatorBlurbs from "@/pages/reports/contextIndicatorBlurbs";
import dataPrivacy from "@/pages/reports/dataPrivacy";
import twoXCollabrativePrivacy from "@/pages/reports/twoXCollabrativePrivacy";
import dealsTermsOfUse from "@/pages/reports/dealsTermsOfUse";
import dealsPrivacyPolicy from "@/pages/reports/dealsPrivacyPolicy";
import cookiesPolicy from "@/pages/reports/cookiesPolicy";
import twoXUserAgreement from "@/pages/reports/2xUserAgreement";
import my2XDeals from "@/pages/reports/my2XDeals";
import twoXCMembership from "@/pages/reports/two-xc-membership";
import Cookies from "@/helpers/cookies";
import GenderSmartTools from "@/pages/reports/genderSmartTools";
import SubmitGenderSmartTools from "@/pages/reports/submitGenderSmartTools";
import SubmitPartner from "@/pages/reports/SubmitPartner";
import SubmitGirlSource from "@/pages/reports/submitGirlSource";
import SubmitDeals from "@/pages/reports/submitDeals";
import NewServiceProvision from "@/pages/reports/NewServiceProvision.vue";
import SubmitTSP from "@/pages/reports/submitTSP";
// import my2xAssesment from "@/pages/reports/my2xassement.vue";
import GbvhRoadmapAssess from "@/pages/reports/gbvhRoadmapAssess";
import GBVH from "@/pages/reports/gbvh";
import GBVHNewAssesment from "@/pages/reports/gbvhNewAssesment";

import gbvhSharedAssessment from "@/pages/reports/gbvhSharedAssessment";
import gbvhSharedAssessmentByMe from "@/pages/reports/gbvhSharedAssessmentByMe";
import my2xAssessmentSharedByMe from "@/pages/reports/my2xassementSharedByMe";
import myPortfolio from "@/pages/reports/myPortfolio.vue";
import twoXEcosystemTechnicalServicesList from "@/pages/reports/twoXEcosystemTechnicalServicesList.vue";
import twoXAssessSubLanding from "@/pages/reports/twoXAssessSubLanding.vue";
import twoXDealsSubLanding from "@/pages/reports/twoXDealsSubLanding.vue";

import pricing from "@/pages/home/pricing.vue";
import pricings from "@/pages/home/pricings.vue";
import roi from "@/pages/home/roi.vue";
import roiInnpact from "@/pages/home/roiInnpact.vue";
import roiAssessment from "@/pages/reports/roiAssessment.vue";
import roiInnpactAssessment from "@/pages/reports/roiInnpactAssessment.vue";
import roiPortfolio from "@/pages/reports/roiPortfolio.vue";
import roiInnpactPortfolio from "@/pages/reports/roiInnpactPortfolio.vue";
import roiSurvey from "@/pages/reports/roiSurvey.vue";
import roiInnpactSurvey from "@/pages/reports/roiInnpactSurvey.vue";
import roiSubmitSurvey from "@/pages/reports/roiSubmitSurvey.vue";
import roiInnpactSubmitSurvey from "@/pages/reports/roiInnpactSubmitSurvey.vue";
import gbvApiSettings from "@/pages/settings/gbvApiSettings.vue";
import roiPillar from "@/pages/reports/roiPillar.vue";
import roiPillarInnpact from "@/pages/reports/roiPillarInnpact.vue";
import gesiApiSettings from "@/pages/settings/gesiApiSettings.vue";
import twoXPortfolioApiSettings from "@/pages/settings/twoXPortfolioApiSettings.vue";
import roiApiSettings from "@/pages/settings/roiApiSettings.vue";
import roiSharedAssessment from "@/pages/reports/roiSharedAssessments.vue";
import ggsfSharedAssessments from "@/pages/reports/ggsfSharedAssessments.vue";
import innpactUserPortfolioDashboard from "@/pages/reports/innpactUserPortfolioDashboard.vue";
import ROIAssessmentApiSettings from "@/pages/settings/ROIAssessmentApiSettings.vue";
import twoXAssessmentApiSettings from "@/pages/settings/twoXAssessmentApiSettings.vue";
import CGEFApplication from "@/pages/home/CGEFApplication.vue";
import SubmitADeal from "@/pages/reports/submitADeal";
import TwoXChallengeDeals from "@/pages/reports/twoXChallengeDeals";
import twoXInvestorDisclosures from "@/pages/reports/twoXInvestorDisclosures";

import IMMLandingPage from "@/pages/home/IMM/IMMLandingPage.vue";
import CreateInvestorProfile from "@/pages/home/IMM/CreateInvestorProfile.vue";
import CreateCompanyProfile from "@/pages/home/IMM/CreateCompanyProfile.vue";
import SetUpPreferences from "@/pages/home/IMM/SetUpPreferences.vue";
import AggregatedPortfolioFinance from "@/pages/home/IMM/AggregatedPortfolioFinance.vue";
import CompanyPortfolioDashboard from "@/pages/home/IMM/CompanyPortfolioDashboard.vue";
import CompanyPortfolioInvestorFinance from "@/pages/home/IMM/CompanyPortfolioInvestorFinance.vue";
import IMMDashboardPage from "@/pages/home/IMM/IMMDashboardPage.vue";
import InvesteesPageClimate from "@/pages/home/IMM/InvesteesPageClimate.vue";
import CompanyPageClimate from "@/pages/home/IMM/CompanyPageClimate.vue";
import IIMImpactPage from "@/pages/home/IMM/IIMImpactPage.vue";
import ImmEsgPage from "@/pages/home/IMM/ImmEsgPage.vue";

import { useUserStore } from "@/stores/user";
import { getPermits, canAccess } from "../security/acl";

import survey from "@/pages/surveys/survey_template";
import EsgSurvey from "../pages/reports/IIMEsgSurvey.vue";
import ClimateSurvey from "../pages/reports/IIMClimateSurvey.vue";
import ImpactSurvey from "../pages/reports/IIMImpactSurvey.vue";
import FinanceSurvey from "../pages/reports/IIMFinanceSurvey.vue";
import chatbot from "../pages/chat/chatbot.vue";
import GESISearch from "@/pages/reports/GESISearch.vue";
import twoXInvite from "../pages/reports/2xInvite.vue";
import IMMInvite from "../pages/reports/IMMInvite.vue";

import TwoXAdminDashboard from "../pages/thirdPartyVerifier2X/dashboardPage.vue";
import VerificationAssessment from "../pages/thirdPartyVerifier2X/assessmentResults.vue";
import VerificationFullAssessment from "../pages/thirdPartyVerifier2X/full-assessmentResults.vue";
import filesUploader from "../pages/thirdPartyVerifier2X/filesUploader.vue";
import My2xNewPortfolio from "../pages/reports/my2xNewPortfolio.vue";

import IMMSurveys from "../pages/surveys/IMMSurveys.vue";
import TowxGlobalPortal from "../pages/towxGlobalPortal/2xGlobdashboardPage.vue";
import TowxChallengeDashboard from "../pages/towxGlobalPortal/2xChallangeAdmin.vue";
import TwoxGlobalHomePage from "../pages/home/twoxGlobalHomePage.vue";
import AcceptMatchMakingInteractions from "../pages/reports/AcceptMatchMakingInteractions.vue";
import twoXThirdPartyVerifier from "../pages/thirdPartyVerifier2X/thirdPartyData.vue";
import CertificationPrivacyNotice from "./../pages/reports/twoXCertificationPrivacyNotice.vue";

import CheckSessionPage from "@/pages/check-session/index.vue";
import { analyticsService } from "@/services/analyticsService";

import store from "@/helpers/store.js";
import DealPipelineDetails from "@/pages/home/IMM/DealPipelineDetails.vue";
import ImpactMetrics from "@/components/imm-components/impact-metrics/ImpactMetrics.vue";
import DashboardSingle from "@/components/imm-components/dashboard/Dashboard-Single.vue";

Vue.use(Router);

window.routes = [
  // Default settings
  {
    path: "/",
    name: "homePage",
    component: homePage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Home",
  },
  { path: "/login", name: "login", component: login, prettyName: "Login" },
  {
    path: "/signup/:invitationId?",
    name: "signup",
    component: signup,
    prettyName: "Sign-up",
  },
  {
    path: "/invite",
    name: "invite",
    component: firstTime,
    prettyName: "Invite",
  },
  {
    path: "/maintenance",
    name: "maintenancePage",
    component: maintenancePage,
    prettyName: "Maintenance",
  },
  {
    path: "/2X-Ecosystem/thresholds-global",
    name: "twoxThresholdsGlobal",
    component: twoxThresholdsGlobal,
    prettyName: "2X Dynamic Thresholds Global",
    meta: {
      hideHeaderFooter: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset_password",
    component: reset_password,
    prettyName: "Password Reset",
  },
  {
    path: "/login/:identificationCode",
    name: "login",
    component: login,
    prettyName: "Login with Identification Code",
  },
  {
    path: "/reset-password/:resetCode",
    name: "set_password",
    component: set_password,
    prettyName: "Password Reset with Code",
  },

  {
    path: "/not-allowed",
    name: "notAllowed",
    component: notAllowed,
    prettyName: "Not Allowed",
  },
  {
    path: "/welcome",
    name: "welcome",
    component: welcome,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Welcome",
  },
  {
    path: "/global-analysis",
    name: "globalAnalysis",
    component: globalAnalysis,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Global GESI Analysis",
  },

  // Reports
  {
    path: "/gbv-risk/choose-country/:gbv?",
    name: "countryChoose",
    component: countryChoose,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Country Choice",
  },
  {
    path: "/choose-sector",
    name: "sectorChoose",
    component: sectorChoose,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Sector Choice",
  },

  {
    path: "/gesi-analysis/choose-country",
    name: "countryChoose",
    component: countryChoose,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Country Choice",
  },
  {
    path: "/gesi-analysis/:iso2/choose-sector",
    name: "choose-sector",
    component: sectorChoose,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Sector Choice",
  },

  {
    path: "/gbv-risk/:iso2/choose-sector/:gbv?",
    name: "choose-sector",
    component: sectorChoose,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Sector Choice",
  },

  {
    path: "/gbv-risk/:iso2",
    redirect: "/reports/:iso2/country",
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Country Summary",
  },
  {
    path: "/gbv-risk/:iso2/:sector",
    name: "country-report-overview",
    component: countryReportOverview,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Overview",
  },
  {
    path: "/gesi-analysis/:iso2/:sector/snapshot",
    name: "country-report-overview",
    component: countryReportOverview,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Overview",
  },
  {
    path: "/reports/:iso2/:sector/improvements",
    name: "country-report-improvemens",
    component: countryReportImprovements,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis What Works",
  },
  {
    path: "/reports/:iso2/:sector/themes",
    name: "country-report-themes",
    component: countryReportThemes,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Theme Summary",
  },
  {
    path: "/reports/:iso2/:sector/themes/:themeId",
    name: "country-report-themes-detail",
    component: countryReportThemeDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Theme Detail",
  },
  {
    path: "/reports/:iso2/:sector/domains",
    name: "country-report-domains",
    component: countryReportDomains,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Domain Summary",
  },
  {
    path: "/reports/:iso2/:sector/domains/:domainId",
    name: "country-report-domains-detail",
    component: countryReportDomainDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Domain Detail",
  },
  {
    path: "/gbv-risk/:iso2/:sector/home",
    name: "gbv-risk-home",
    component: gbvRiskHome,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Home",
  },
  {
    path: "/gbv-risk/:iso2/:sector/risk-score",
    name: "gbv-risk-detail-score",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Overview",
  },
  {
    path: "/gbv-risk/:iso2/:sector/risk-models",
    name: "gbv-risk-detail-model",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Models in Country Risk",
  },
  {
    path: "/gbv-risk/:iso2/:sector/materiality",
    name: "gbv-risk-detail-materiality",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Materiality",
  },
  {
    path: "/gbv-risk/:iso2/:sector/take-action",
    name: "gbv-risk-detail-take-action",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Take Action",
  },
  {
    path: "/gbv-risk/:iso2/:sector/indicators",
    name: "gbv-risk-detail-indicators",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Indicators",
  },
  {
    path: "/gbv-risk/:iso2/:sector/heat-map",
    name: "gbv-risk-detail-map",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Global Heat Map in Country Risk",
  },
  {
    path: "/gbv-risk/risk-models",
    name: "gbv-risk-detail-risk-model",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Models",
  },
  {
    path: "/gbv-risk/:iso2/:sector/methodology",
    name: "gbv-risk-detail-methodology",
    component: gbvRiskDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Methodology",
  },
  /*{
    path: "/reports/:iso2/:sector/gbv-risk-indicators",
    name: "gbv-risk-indicators",
    component: gbvIndicators
  },*/
  {
    path: "/reports/:iso2/:sector/what-works",
    name: "what-works",
    component: whatWorks,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk What Works",
  },

  // Settings (account & organization)
  {
    path: "/settings/account",
    name: "settings",
    component: settingsAccount,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Account Settings",
  },
  {
    path: "/settings/team",
    name: "team",
    component: team,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Team Settings",
  },
  {
    path: "/settings/plans",
    name: "plans",
    component: plans,
    beforeEnter() {
      window.open("https://www.equilo.io/pricing", "_blank");
    },
    prettyName: "Plan Settings",
  },
  {
    path: "/settings/subscriptions",
    name: "subscriptionNew",
    component: subscriptionNew,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Subscription Settings",
  },
  {
    path: "/settings/plans/:planId",
    name: "Plan detail",
    component: planDetail,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Plan Detail Settings",
  },
  {
    path: "/settings/plans/:planId/ach",
    name: "pay",
    component: ach,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Plan Payment Settings ACH",
  },
  {
    path: "/settings/plans/:planId/creditcard",
    name: "pay",
    component: creditcard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Plan Payment Settings Credit Card",
  },
  {
    path: "/settings/invoices",
    name: "invoices",
    component: invoices,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Invoices",
  },
  {
    path: "/settings/configure-subscription",
    name: "configureSubscription",
    component: configureSubscription,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Configure Subscription",
  },
  {
    // Obsolete??
    path: "/settings/configure-subscription-sector",
    name: "configureSubscriptionSector",
    component: configureSubscriptionSector,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Configure Subscription by Sector",
  },
  {
    path: "/settings/members",
    name: "members",
    component: members,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Member Settings",
  },
  {
    path: "/settings/users/request",
    name: "request",
    component: userRequest,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Invite Team Members",
  },
  {
    path: "/settings/my-investor-profile",
    name: "TwoXC",
    component: twoXC,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "My Investor Profile",
  },
  {
    path: "/gesi-analysis/methodology",
    name: "methodology",
    component: methodology,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Methodology",
  },
  {
    path: "/settings/demo-video",
    name: "Demo video",
    component: demoVideo,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Demo Video",
  },
  {
    path: "/invoices/:invoiceId",
    name: "invoice",
    component: invoice,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Invoice Detail",
  },
  {
    path: "/settings/changepassword",
    name: "changepassword",
    component: changePassword,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Change Password Settings from Dashboard",
  },
  {
    path: "/settings/autorenew",
    name: "autorenew",
    component: autorenew,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Auto Renew Settings",
  },
  {
    path: "/supergirl",
    name: "supergirl",
    component: supergirl,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "SuperGIRL",
    meta: getPermits("/supergirl"),
  },
  {
    path: "/partner-directory",
    name: "partnerdirectory",
    component: partnerdirectory,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Partner Directory",
    meta: getPermits("/partner-directory"),
  },
  {
    path: "/equiloland-demo",
    name: "equilolandDemo",
    component: equilolandDemo,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis EquiloLand Demo",
  },
  {
    path: "/tutorials",
    name: "tutorials",
    component: tutorials,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Tutorials",
  },
  {
    path: "/ta-service-provision",
    name: "TAServiceProviderSubCard",
    component: TAServiceProviderSubCard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "TA Service Provision",
  },
  {
    path: "/ta-service-provision/add-new-service-provision",
    name: "NewServiceProvision",
    component: NewServiceProvision,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "TA Service Provision Log",
  },
  {
    path: "/gbv/methodology",
    name: "gbvRiskMethodology",
    component: gbvRiskMethodology,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Global GBV Risk Methodology",
  },
  {
    path: "/gbv/heat-map",
    name: "gbvRiskHeatMap",
    component: gbvRiskHeatMap,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Global GBV Risk Heat Map",
  },
  {
    path: "/gbv/materiality",
    name: "gbvRiskMateriality",
    component: gbvRiskMateriality,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Global GBV Risk Materiality",
  },
  {
    path: "/gbv/risk-models",
    name: "gbvRiskRiskModels",
    component: gbvRiskRiskModels,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Global GBV Risk Risk Models",
  },
  {
    path: "/support-feedback",
    name: "supportFeedback",
    component: supportFeedback,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Support & Feedback",
  },
  {
    path: "/gesi-upgrade",
    name: "gesiNotAllowed",
    component: gesiNotAllowed,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Analysis Paywall to Upgrade",
  },
  {
    path: "/2X-Ecosystem/Thresholds",
    name: "twoxThresholds",
    component: twoxThresholds,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Dynamic Thresholds",
  },
  {
    path: "/2X-Ecosystem/Thresholds/paywall",
    name: "my2xThresholdsPaywall",
    component: my2xThresholdsPaywall,
    prettyName: "2X Dynamic Thresholds Paywall",
  },
  {
    path: "/2X-Ecosystem/Thresholds/Download",
    name: "twoxThresholdsCSV",
    component: twoxThresholdsCSV,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Dynamic Thresholds CSV",
  },
  {
    path: "/2X-Ecosystem/2X-Certification",
    name: "twoxCertify",
    component: twoxCertify,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Ecosystem Certification",
  },
  // {
  //   path: "/2X-Ecosystem/Certification/2X-Portfolio",
  //   name: "twoXPortfolio",
  //   component: twoXPortfolio,
  //   beforeEnter: beforeRouteEnterValidation,
  //   prettyName: "2X Portfolio",
  // },
  {
    path: "/2X-Ecosystem/2X-Certification/2X-Portfolio",
    name: "My2xNewPortfolio",
    component: My2xNewPortfolio,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Portfolio",
  },
  {
    path: "/2X-Challenge/2X-Challenge-Self-Assessment",
    name: "twoxSelfAssessment",
    component: twoxSelfAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Challenge Self-Assessment",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment",
    name: "twoxSelfAssessment",
    component: twoxSelfAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Self-Assessment",
  },
  {
    path: "/2X-Challenge",
    name: "twoXChallenge",
    component: twoXChallenge,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Ecosystem Certification",
  },
  {
    path: "/2X-Challenge/2X-Challenge-Self-Assessment/:surveyURL",
    name: "twoxCertifyAssessmentSurveyPage",
    component: twoxCertifyAssessmentSurveyPage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Assessment Dashboard",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/2X-Certification-Self-Assessment/:surveyURL",
    name: "twoxCertifyFullAssessmentSurveyPage",
    component: twoxCertifyAssessmentSurveyPage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Assessment Dashboard",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/IndicativeResults",
    name: "twoxCertifyIndicativeResults",
    component: twoxCertifyIndicativeResults,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Indicative Results",
  },
  {
    path: "/2X-Challenge/2X-Challenge-Self-Assessment-Results",
    name: "selfAssessResults",
    component: selfAssessResults,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Challenge Self-Assessment Results",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/UploadDocuments",
    name: "twoxCertifyUploadDocuments",
    component: twoxCertifyUploadDocuments,
    beforeEnter: beforeRouteEnterValidation,
  },
  {
    path: "/2X-Ecosystem/2X-Certification/Verification",
    name: "twoxCertifyVerification",
    component: twoxCertifyVerification,
    beforeEnter: beforeRouteEnterValidation,
  },
  {
    path: "/2X-Ecosystem/2X-Certification/Self-Assessment-detailed-results",
    name: "twoxCertifyAssessmentFullResult",
    component: twoxCertifyAssessmentFullResult,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Full Results Page",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/GlobalCertification",
    name: "twoxCertifyGlobalCertification",
    component: twoxCertifyGlobalCertification,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Global",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/theme-badge",
    name: "My2XCertificationThemeBadgePage",
    component: My2XCertificationThemeBadgePage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Theme Badge Page",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/score-card",
    name: "My2xCertificationNewScoreCard",
    component: My2xCertificationNewScoreCard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Score Card",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/updated-score-card",
    name: "My2xCertificationNewScoreCard",
    component: My2xCertificationNewScoreCard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Score Card",
  },
  {
    path: "/2X-Ecosystem/2X-Certification/sub-dimension",
    name: "My2XCertificationSubDimension",
    component: My2XCertificationSubDimension,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Sub Dimension Page",
  },
  {
    path: "/2X-Ecosystem/Request-Assessment",
    name: "requestassesment",
    component: requestassesment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Asses Request Assessment",
  },
  {
    path: "/2X-Ecosystem/New-2XAssessment",
    name: "newtwoxassesment",
    component: newtwoxassesment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess Start New Assessment",
  },
  {
    path: "/2X-Ecosystem/New-2XAssessment/Org",
    name: "newtwoxassesmentorg",
    component: newtwoxassesmentorg,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess Corporate Survey",
  },
  {
    path: "/2X-Ecosystem/New-2XAssessment/Funds",
    name: "newtwoxassesmentfunds",
    component: newtwoxassesmentfunds,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess Fund Survey",
  },
  {
    path: "/2X-Ecosystem/New-2XAssessment/Fis",
    name: "newtwoxassessmentfis",
    component: newtwoxassessmentfis,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess FI Survey",
  },
  {
    path: "/2X-Ecosystem/Methodology",
    name: "newtwoxmethodology",
    component: newtwoxmethodology,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess Methodology",
  },
  {
    path: "/2X-Ecosystem/FavoriteAssessments",
    name: "myFavAssessments",
    component: myFavAssessments,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess Favorites",
  },
  {
    path: "/settings/AssessmentsSharedByMe",
    name: "my2xAssessmentSharedByMe",
    component: my2xAssessmentSharedByMe,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess Sharing Permission Settings",
  },
  // {
  //   path: "/2X-Ecosystem/My-2X-Assessments",
  //   name: "my2xassement",
  //   component: my2xAssesment,
  //   beforeEnter: beforeRouteEnterValidation,
  //   prettyName: "2X Assessments Shared with User",
  // },
  {
    path: "/2X-Ecosystem/2X-Matchmaking",
    name: "my2xassementDeals",
    component: my2xassementDeals,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Deals",
  },
  {
    path: "/match-making-interactions/accept",
    name: "AcceptMatchMakingInteractions",
    component: AcceptMatchMakingInteractions,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Accept Match Making Interactions",
  },
  {
    path: "/2X-Ecosystem/third-party-verification",
    name: "twoXThirdPartyVerifier",
    component: twoXThirdPartyVerifier,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Third Party Verification",
  },
  {
    path: "/2X-Ecosystem/upload-files",
    name: "twoXThirdPartyVerifierFileUploader",
    component: filesUploader,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Third Party Verification: Upload Files",
  },
  { path: "*", beforeEnter: undefinedRouteValidate },
  // If someone tries to access the URL of the OLD 2X Assess, what is the screen we show?
  // {
  //   path: "/2X-Ecosystem/My-2X-Assessments/contextual-analysis",
  //   name: "indicatorBlurbs",
  //   component: indicatorBlurbs,
  //   beforeEnter: beforeRouteEnterValidation,
  //   prettyName: "2X Assess GESI Context Home",
  // },
  // {
  //   path: "/2X-Ecosystem/My-2X-Assessments/context",
  //   name: "contextIndicatorBlurbs",
  //   component: contextIndicatorBlurbs,
  //   beforeEnter: beforeRouteEnterValidation,
  //   prettyName: "2X Assess GESI Dimension Indicators",
  // },
  {
    path: "/dataprivacy",
    name: "dataPrivacy",
    component: dataPrivacy,
    prettyName: "Data Privacy Policy",
  },
  {
    path: "/twoXCollabrativePrivacy",
    name: "twoXCollabrativePrivacy",
    component: twoXCollabrativePrivacy,
    prettyName: "2XG Privacy Policy",
  },
  {
    path: "/investorsPrivacyPolicy",
    name: "dealsPrivacyPolicy",
    component: dealsPrivacyPolicy,
    prettyName: "2X Deals Privacy Policy",
  },
  {
    path: "/dealsTermsOfUse",
    name: "dealsTermsOfUse",
    component: dealsTermsOfUse,
    prettyName: "2X Deals Terms",
  },
  {
    path: "/cookies-policy",
    name: "cookiesPolicy",
    component: cookiesPolicy,
    prettyName: "Cookies Policy",
  },
  {
    path: "/2x-user-agreement",
    name: "2xUserAgreement",
    component: twoXUserAgreement,
    prettyName: "2x User Agreement",
  },
  {
    path: "/2X-Ecosystem/2X-Deals",
    name: "my2XDeals",
    component: my2XDeals,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Deals",
  },
  {
    path: "/2X-Ecosystem/2XC-Membership/:investorOrg",
    name: "TwoXCMembership",
    component: twoXCMembership,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Member Profile Page",
  },
  {
    path: "/GenderSmartTools",
    name: "GenderSmartTools",
    component: GenderSmartTools,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Gender Smart Tools",
  },
  {
    path: "/submitGenderSmartTools",
    name: "SubmitGenderSmartTools",
    component: SubmitGenderSmartTools,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Gender Smart Tools Submission",
  },
  {
    path: "/submitPartner",
    name: "submitPartner",
    component: SubmitPartner,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Submit Partner",
  },
  {
    path: "/submitGirlSource",
    name: "submitGirlSource",
    component: SubmitGirlSource,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Submit Partner",
  },
  {
    path: "/2X-Ecosystem/create2Xprofile",
    name: "submitDeals",
    component: SubmitDeals,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Deals Investor Survey",
  },
  {
    path: "/2X-Ecosystem/submitTSP",
    name: "SubmitTSP",
    component: SubmitTSP,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "TA Provider Directory Submission",
  },
  {
    path: "/gbvh/gbvh-roadmap-assessment",
    name: "GbvhRoadmapAssess",
    component: GbvhRoadmapAssess,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBVH Assess Landing",
  },
  {
    path: "/gbvh",
    name: "GBVH",
    component: GBVH,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBVH Assess",
  },
  {
    path: "/gbvh/gbvhSharedAssessment",
    name: "gbvhSharedAssessment",
    component: gbvhSharedAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBVH Assessments Shared with User",
  },
  {
    path: "/gbvh/new-assessment",
    name: "GBVHNewAssesment",
    component: GBVHNewAssesment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "New GBVH Assessment",
  },

  {
    path: "/settings/gbvhSharedAssessmentByMe",
    name: "gbvhSharedAssessmentByMe",
    component: gbvhSharedAssessmentByMe,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBVH Assess Sharing Settings",
  },
  {
    path: "/2X-Ecosystem/my-portfolio",
    name: "myPortfolio",
    component: myPortfolio,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Portfolio",
  },
  {
    path: "/2X-Ecosystem/technical-service-list",
    name: "service-list",
    component: twoXEcosystemTechnicalServicesList,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "TA Service Provider Directory",
  },
  {
    path: "/upgrade",
    name: "pricing",
    component: pricing,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Upgrade Landing",
  },
  {
    path: "/pricings/:plan_type",
    name: "pricings",
    component: pricings,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Upgrade Specific Plans",
  },
  {
    path: "/2X-Ecosystem/twoXAssess",
    name: "twoXAssessSubLanding",
    component: twoXAssessSubLanding,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess",
  },
  {
    path: "/2X-Ecosystem/twoXDeals",
    name: "twoXDealsSubLanding",
    component: twoXDealsSubLanding,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Deals",
  },
  {
    path: "/roi",
    name: "roi",
    component: roi,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Landing",
  },
  {
    path: "/ggsf",
    name: "roiInnpact",
    component: roiInnpact,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI innpact Landing",
  },
  {
    path: "/roi/survey/:surveyId/:tag/:surveyName",
    name: "roiSurvey",
    component: roiSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Survey Modules",
  },
  {
    path: "/ggsf/survey-innpact/:surveyId/:tag/:surveyName",
    name: "roiInnpactSurvey",
    component: roiInnpactSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Innpact Survey Modules",
  },
  {
    path: "/ggsf/submit-survey-innpact/:surveyId/:tag/:surveyName",
    name: "roiInnpactSubmitSurvey",
    component: roiInnpactSubmitSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Innpact Survey Submission",
  },
  {
    path: "/roi/submit-survey/:surveyId/:tag/:surveyName",
    name: "roiSubmitSurvey",
    component: roiSubmitSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Survey Submission",
  },
  {
    path: "/roi/roiAssessment",
    name: "roiAssessment",
    component: roiAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Results",
  },
  {
    path: "/ggsf/ggsf-assessment",
    name: "roiInnpactAssessment",
    component: roiInnpactAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI innpact Results",
  },
  {
    path: "/settings/gbv_api_settings",
    name: "gbvApiSettings",
    component: gbvApiSettings,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GBV Risk Score Settings",
  },
  {
    path: "/settings/gesi_api_settings",
    name: "gesiApiSettings",
    component: gesiApiSettings,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI API Settings",
  },
  {
    path: "/settings/twox_portfolio_api_settings",
    name: "twoXPortfolioApiSettings",
    component: twoXPortfolioApiSettings,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Portfolio API Settings",
  },
  {
    path: "/settings/roi_api_settings",
    name: "roiApiSettings",
    component: roiApiSettings,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Portfolio API Settings",
  },
  {
    path: "/roi/roiPortfolio",
    name: "roiPortfolio",
    component: roiPortfolio,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Portfolio",
  },
  {
    path: "/ggsf/ggsfPortfolio",
    name: "roiInnpactPortfolio",
    component: roiInnpactPortfolio,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GGSF Portfolio",
  },
  {
    path: "/roi/pillar",
    name: "roiPillar",
    component: roiPillar,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Pillar Pages",
  },
  {
    path: "/ggsf/pillar-innpact",
    name: "roiPillarInnpact",
    component: roiPillarInnpact,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Pillar Pages",
  },
  {
    path: "/roi/roiSharedAssessments",
    name: "roiSharedAssessments",
    component: roiSharedAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Assessments Shared with User",
  },
  {
    path: "/ggsf/ggsfSharedAssessments",
    name: "ggsfSharedAssessments",
    component: ggsfSharedAssessments,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Assessments Shared with User",
  },
  {
    path: "/ggsf/fund-of-funds-dashboard",
    name: "innpactUserPortfolioDashboard",
    component: innpactUserPortfolioDashboard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI Assessments Shared with User",
  },
  {
    path: "/settings/roi_assessment_api_settings",
    name: "ROIAssessmentApiSettings",
    component: ROIAssessmentApiSettings,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ROI API Settings",
  },
  {
    path: "/settings/twox_assessment_api_settings",
    name: "twoXAssessmentApiSettings",
    component: twoXAssessmentApiSettings,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assess API Settings",
  },
  {
    path: "/CGEF/application",
    name: "CGEFApplication",
    component: CGEFApplication,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "CGEF Application",
  },
  {
    path: "/surveys/:survey",
    name: "Surveys",
    component: survey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Surveys",
  },
  {
    path: "/imm/landing-page",
    name: "IMMLandingPage",
    component: IMMLandingPage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "IMM Landing Page",
  },
  {
    path: "/imm/create-investor-profile",
    name: "CreateInvestorProfile",
    component: CreateInvestorProfile,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Create Investor Profile",
  },
  {
    path: "/imm/create-company-profile",
    name: "CreateCompanyProfile",
    component: CreateCompanyProfile,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Create Investor Profile",
  },
  {
    path: "/imm/setup-preferences",
    name: "SetUpPreferences",
    component: SetUpPreferences,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "IIM Landing Page",
  },
  {
    path: "/imm/aggregate-portfolio/finance",
    name: "AggregatedPortfolioFinance",
    component: AggregatedPortfolioFinance,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Aggregated Portfolio Finance",
  },
  {
    path: "/imm/dashboard/finance",
    name: "CompanyPortfolioFinanceDashboard",
    component: CompanyPortfolioDashboard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Portfolio Finance Dashboard",
  },
  {
    path: "/imm/:companyId/finance",
    name: "CompanyPortfolioInvestorFinance",
    component: CompanyPortfolioInvestorFinance,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Finance - Investor's page",
  },
  {
    path: "/imm/impact-survey",
    name: "IIMImpactSurvey",
    component: ImpactSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Impact Survey page",
  },
  {
    path: "/imm/climate-survey",
    name: "IIMClimateSurvey",
    component: ClimateSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Climate Survey page",
  },
  {
    path: "/imm/esg-survey",
    name: "IIMEsgSurvey",
    component: EsgSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ESG Survey page",
  },
  {
    path: "/imm/finance-survey",
    name: "IIMFinanceSurvey",
    component: FinanceSurvey,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ESG Survey page",
  },
  {
    path: "/imm/aggregate-portfolio/investees/impact",
    name: "IIMImpactPage",
    component: IIMImpactPage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ImpactPage - Investee's page",
  },
  {
    path: "/imm/dashboard",
    name: "IMMDashboardPage",
    component: IMMDashboardPage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "IMM Dashboard Page",
  },
  {
    path: "/imm/investor-page/company-profile/climate",
    name: "InvesteesPageClimate",
    component: InvesteesPageClimate,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Finance - Investee's page",
  },
  {
    path: "/imm/investor-page/company-profile/climate/investee",
    name: "CompanyPageClimate",
    component: CompanyPageClimate,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Company page - Investee's page",
  },
  {
    path: "/imm/Investor-page/company-profile/esg",
    name: "ImmEsgPage",
    component: ImmEsgPage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "ImmEsgPage Page",
  },
  {
    path: "/imm/deal-pipeline",
    name: "DealPipelineDetails",
    component: DealPipelineDetails,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Deal Pipeline Details Page",
  },
  {
    path: "/submit-deal",
    name: "SetUpPreSubmitADealferences",
    component: SubmitADeal,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Submit a Deal",
  },
  {
    path: "/challenge-deals",
    name: "TwoXChallengeDeals",
    component: TwoXChallengeDeals,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Challenge Deals",
  },
  {
    path: "/investor-disclosures",
    name: "twoXInvestorDisclosures",
    component: twoXInvestorDisclosures,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Challenge Investor Disclosures ",
  },
  {
    path: "/chatbot",
    name: "chatbot",
    component: chatbot,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "Chat Bot",
  },
  {
    path: "/2X-Ecosystem/2x-portfolio-setup",
    name: "twoXPortfolioSetup",
    component: twoXPortfolioSetup,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Portfolio Setup",
  },
  {
    path: "/data-explorer",
    name: "GESISearch",
    component: GESISearch,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "GESI Data Explorer",
  },
  {
    path: "/admin",
    name: "TwoXAdminDashboard",
    component: TwoXAdminDashboard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X third party verifiers dashboard",
  },
  {
    path: "/2x-global/2X-Certification",
    name: "TowxGlobalPortal",
    component: TowxGlobalPortal,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "TowxGlobalPortal verifiers dashboard",
  },
  {
    path: "/2x-global/Home",
    name: " twoxGlobalHomePage",
    component: TwoxGlobalHomePage,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "TowxGlobalPortal HomePage",
  },
  {
    path: "/2x-global/admin/assessment",
    name: "VerificationAssessment",
    component: VerificationAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assessment for Verification",
  },
  {
    path: "/2x-global/admin/full-assessment",
    name: "VerificationFullAssessment",
    component: VerificationFullAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Full Assessment for Verification",
  },
  {
    path: "/2x-global/admin/full-assessment/sub-dimension",
    name: "My2XCertificationSubDimension",
    component: My2XCertificationSubDimension,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Sub Dimension Page",
  },
  {
    path: "/2x-global/2X-Challenge",
    name: "TwoXChallengeDashboard",
    component: TowxChallengeDashboard,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Challenge Dashboard",
  },
  {
    path: "/admin/assessment",
    name: "VerificationAssessment",
    component: VerificationAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Assessment for Verification",
  },
  {
    path: "/admin/full-assessment",
    name: "VerificationFullAssessment",
    component: VerificationFullAssessment,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Full Assessment for Verification",
  },
  {
    path: "/admin/full-assessment/sub-dimension",
    name: "My2XCertificationSubDimension",
    component: My2XCertificationSubDimension,
    beforeEnter: beforeRouteEnterValidation,
    prettyName: "2X Certification Sub Dimension Page",
  },
  {
    path: "/2x-invite/:token",
    name: "twoXInvite",
    component: twoXInvite,
    prettyName: "2X Invite",
    meta: {
      hideHeaderFooter: true,
    },
  },
  {
    path: "/imm-invite/:token",
    name: "IMMInvite",
    component: IMMInvite,
    prettyName: "IMM Invite",
    meta: {
      hideHeaderFooter: true,
    },
  },
  {
    path: "/imm/surveys/:survey",
    name: "IMMSurveys",
    component: IMMSurveys,
    prettyName: "IMM Surveys",
  },
  {
    path: "/2X-Certification-Privacy-Notice",
    name: "CertificationPrivacyNotice",
    component: CertificationPrivacyNotice,
    prettyName: "2X Certification Privacy Notice",
  },
  {
    path: "/check-session",
    name: "CheckSessionPage",
    component: CheckSessionPage,
    prettyName: "Check Session Page",
  },
  {
    path: "/imm/impact-metrics",
    name: "ImpactMetrics",
    component: ImpactMetrics,
    prettyName: "Impact Metrics",
  },
  {
    path: "/imm/dashboard/:surveyId",
    name: "DashboardSingle",
    component: DashboardSingle,
    prettyName: "Dashboard Single",
  },
];

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: window.routes,
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // start with the ugly name, then try and find a prettier one.
  let pageName = to.name;
  if (to.matched && to.matched.length > 0) {
    let rawPath = to.matched[0].path;

    // Handle special case for home page
    if (rawPath === "") {
      rawPath = "/";
    }

    const rawRoute = window.routes.find((route) => route.path === rawPath);
    if (rawRoute && rawRoute.prettyName) {
      pageName = rawRoute.prettyName;
    }
  }

  // extract params in the URL if any.
  const params = {};
  if (to.params) {
    for (const key in to.params) {
      let metricsKey = key;
      let metricsValue = to.params[key];
      if (metricsKey === "iso2") {
        metricsKey = "country";
        metricsValue = metricsValue.split(",");
      }
      params[metricsKey] = metricsValue;
    }
  }

  analyticsService.page(pageName, {
    properties: {
      path: to.fullPath,
      params: params,
      from: from.name,
      fromPath: from.fullPath,
    },
  });

  // Identify user if logged in
  const userStore = useUserStore();
  if (userStore.user) {
    analyticsService.identify(userStore.user._id, {
      name: `${userStore.user.firstname.trim()} ${userStore.user.lastname.trim()}`,
      email: userStore.user.email,
      organization: userStore.user.organization?.name,
    });
  }
});

function undefinedRouteValidate(to, from, next) {
  if (Cookies.get("session")) {
    next({
      path: "/",
      component: homePage,
    });
  } else {
    next({
      path: "/login",
      replace: true,
    });
  }
}

// paywalls
router.beforeEach(async (to, from, next) => {
  if (
    !Cookies.get("session") &&
    !localStorage.getItem("isLogout") &&
    !["/login", "/signup", "/reset-password", "/"].includes(to.fullPath)
  ) {
    localStorage.setItem("previousURL", to.fullPath);
  }

  // Set navigation source when moving from 2X Challenge to Account
  if (
    from.path.includes("/2X-Challenge") &&
    (to.name === "settings" || to.name === "TwoXCMembership")
  ) {
    store.dispatch("setNavigationSource", true);
  } else if (to.name !== "Account") {
    // Reset when navigating to any other page
    store.dispatch("setNavigationSource", false);
  }

  if (
    [
      "/login",
      "/signup",
      "/reset-password",
      "/2X-Ecosystem/thresholds-global",
      "/invite",
      "/maintenance",
      "/not-allowed",
      "/dataPrivacy",
      "/dealsTermsOfUse",
      "/investorsPrivacyPolicy",
      "/twoXCollabrativePrivacy",
      "/cookies-policy",
      "/2x-invite",
      "/imm-invite",
    ].includes(to.path) ||
    to.path.includes("/2x-invite") || // e.g. /2x-invite/1234567890
    to.path.includes("/imm-invite") // e.g. /imm-invite/1234567890
  ) {
    next();
  } else {
    const userStore = useUserStore();
    if (await userStore.getSession()) {
      if (!userStore.user) await userStore.fetchUser();
      if (!userStore.subscriptionType) await userStore.fetchSubscriptions();
      if (
        canAccess(
          to.path,
          userStore.subscriptionType,
          userStore.user.featureFlags
        )
      ) {
        if (window.innerWidth <= 640 && to.path !== "/chatbot") {
          if (from.name === "chatbot") {
            const currentPath = window.location.href;
            if (!currentPath.includes("/chatbot")) {
              next("/chatbot");
            }
            if (from.path !== "/chatbot") {
              router.push("/chatbot");
              return;
            }
          }
          if (from.path === "/login") {
            return;
          } else {
            if (from.path !== "/chatbot") {
              await router.push("/chatbot");
              return;
            }
          }
        } else {
          next();
        }
      } else {
        next("/upgrade");
      }
    } else {
      next();
    }
  }
});

function beforeRouteEnterValidation(to, from, next) {
  if (
    to.query.id &&
    to.query.org &&
    to.query.type === "2x" &&
    !localStorage.getItem("isLogout")
  ) {
    if (localStorage.getItem("GbvhAssessUrl")) {
      localStorage.removeItem("GbvhAssessUrl");
    }
    localStorage.setItem("2XAssessUrl", to.fullPath);
  }
  if (
    to.query.recordId &&
    to.query.type === "gbvh" &&
    !localStorage.getItem("isLogout")
  ) {
    if (localStorage.getItem("2XAssessUrl")) {
      localStorage.removeItem("2XAssessUrl");
    }
    localStorage.setItem("GbvhAssessUrl", to.fullPath);
  }

  if (!Cookies.get("session") && !localStorage.getItem("isLogout")) {
    localStorage.setItem("previousURL", to.fullPath);
  }

  if (Cookies.get("session")) {
    next();
  } else {
    next({
      path: "/login",
      replace: true,
    });
  }
}

router.afterEach(() => {
  // complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
