<template>
  <div>
    <div v-if="loading">Loading</div>
    <div v-else>
      <div
        v-if="isComplete"
        class="mt-80 mx-10 text-4xl flex flex-col items-center"
      >
        <span> You already answered this survey. </span>
        <button
          @click="toggleCompletion"
          class="mt-20 bg-blue-500 hover:bg-blue-700 text-white text-2xl font-bold py-2 px-4 rounded-lg w-1/4"
        >
          Retake the survey
        </button>
      </div>
      <div v-else>
        <Survey
          :survey-data="surveyData"
          :survey-json="surveyJson"
          :user="user"
          :survey-id="surveyId"
          :answer-again="answerAgain"
          :id="id"
          :isComplete="isComplete"
          :userType="userType"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Survey from "../../components/surveys/survey.vue";
import auth from "@/helpers/auth";
import axios from "axios";
import env from "@/../env";
import Cookies from "@/helpers/cookies";
import apiService from '@/helpers/apiServices.js';

export default {
  components: {
    Survey,
  },
  data() {
    return {
      user: {},
      surveyData: null,
      surveyJson: {},
      loading: false,
      surveyId: "",
      isComplete: false,
      answerAgain: false,
      id: null,
      userType: null,
      userSurveyData: [],
    };
  },
  async mounted() {
    this.loading = true;
    try {
      // load user data, creates survey id from params and checks if survey has been answered already
      this.user = await auth.me();
        await apiService.fetchUserSurveys('All') // Need to add type to fetch imm survye
      .then(async response => {
        this.userSurveyData = await response.data.data;
      })

      this.surveyId = this.$route.params.survey;
      // check the url query and assign tag to variable

      // TO DO
      // set user type from back-end (Fund, Startup..) so that it matches with our current survey tags: fund and company
      // something like if this.user.organizationType === 'Startup' then this.userType = 'company'
      // setting userType here just to test
      // THIS IS FOR TESTING ONLY
      if (this.$route.query.tag) {
        this.userType = this.$route.query.tag;
      }
      //

      // intake is for all users, so it doesn't need a tag
      if (this.surveyId === "2x-intake") {
        this.userType = null;
      }

      if (this.userSurveyData) {
        this.surveyData = this.userSurveyData.find(
          (survey) =>
            survey.surveyId === this.surveyId && survey.tag === this.userType
        );
      }

      // check if survey has been answered already or saved
      if (this.surveyData) {
        this.id = this.surveyData?._id;
        this.isComplete = this.surveyData?.isComplete;

        // transform surveyData from array to object with key values
        let surveyDataObject = {};
        this.surveyData.surveyResponse.forEach((survey) => {
          surveyDataObject[survey.questionId] = survey.answer;
        });
        this.surveyData = surveyDataObject;
      }

      // fetch survey
      //
      const {
        data: { data },
        status,
      } = await axios.get(
        `${env.apiUrl}/surveys/${this.surveyId}?tag=${this.userType}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );
      if (status == 200) {
        this.surveyJson = data.json;
      }
    } catch (error) {
      console.log("Login error ", error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    toggleCompletion() {
      this.isComplete = false;
      this.answerAgain = true;
    },
  },
};
</script>
