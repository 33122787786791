<template>
  <div class="static" :class="{ 'ecosystem-landing': !hideResults }">
    <div id="notLoaded" v-if="!endofcreated" key="loading">
      <page-loader></page-loader>
    </div>
    <div class="container mx-auto -mt-20 px-4 lg:px-0 pb-1" v-if="endofcreated && certificationScoreData">
      <div class="mt-1" :class="{ 'PoweredbyTitle_logo mt-6': !hideResults }">
        <BreadCrumbs v-if="!hideResults" style="margin-top: 0"></BreadCrumbs>
        <div v-if="!hideResults" class="PoweredbyContentWrepper mt-6">
          <div class="flex justify-between">
            <div>
              <div class="text-2xl tracking-tighter font-bold print:text-7xl text-[#19486A]">
                {{ certificationScoreData?.team_id?.name }}
              </div>
              <div class="text-xl" v-if="userAssessData?.createdAt">Application date: {{ moment(userAssessData.createdAt).format("DD MMMM, YYYY") }}
              </div>
              <div class="text-xl" v-if="dateOfSubmission">Submission date: {{ moment(dateOfSubmission).format("DD MMMM, YYYY") }}
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                v-if="userAssessData?.status == 'In Progress'" style="border-radius: 20px; background-color: #fcc30b">
                <img src="@/assets/images/2x_certify/verification-eye.svg" />
                <span class="ml-2">Pending verification</span>
              </div>
              <div v-if="userAssessData?.status == 'Rejected'">
                <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold cursor-pointer"
                  @click="isRejectReasonModalOpen = true" style="border-radius: 20px; background-color: #FF3A21">
                  <img src="@/assets/images/2x_certify/verification-close.svg" />
                  <span class="ml-2">Rejected</span>
                </div>
                <span class="relative block text-center text-white top-[-46px] right-[-100px] bg-[#FF3A21] rounded-xl w-7 h-7 font-bold">1</span>
              </div>
              <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                v-if="userAssessData?.status == 'Verified'" style="border-radius: 20px; background-color: #395AFF">
                <img src="@/assets/images/2x_certify/check.svg" />
                <span class="ml-2">Verified</span>
              </div>
              <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                v-if="userAssessData?.status == 'Certified'" style="border-radius: 20px; background-color: #4C9F38">
                <img src="@/assets/images/2x_certify/check.svg" />
                <span class="ml-2">Certified</span>
              </div>
            </div>
          </div>
          <div class="PoweredbyTitleWrepper mt-6">
            <h3 class="text-4xl tracking-tighter font-bold print:text-7xl" style="color: #19486a; max-width: 845px">
              <span>2X Assessment results</span>
            </h3>
          </div>
        </div>
      </div>
      <div class="ContentWrapper w-12/12 mt-8">
        <div class="container mx-auto">
          <div v-if="!hideResults" class="overview-box mt-8">
            <div class="p-8">
              <h3 class="text-2xl tracking-tighter font-bold">Overview</h3>
              <div class="flex justify-between">
                <div class="flex items-center">
                  <div class="flex text-white text-sm items-center pl-3 pr-3 py-1 font-bold"
                    :style="
                      'border-radius: 20px; background-color:' +
                      (twoxEligible == 'Yes' ? '#4C9F38' : '#FF3A21')
                    ">
                    <svg
                      v-if="twoxEligible == 'Yes'"
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.13911 8.06542L1.17578 5.21493L0.166687 6.17876L4.13911 9.99992L12.6667 1.79708L11.6647 0.833252L4.13911 8.06542Z"
                        fill="white"
                      />
                    </svg>
                    <img v-else src="@/assets/images/white-x.svg" />
                    <span class="ml-2">{{ twoxEligible == "Yes" ? "2X Eligible" : "Not 2X Eligible" }}</span>
                  </div>
                </div>
                <div class="flex flex-col w-3/5">
                  <EvaluationButton
                    :text="'View Indicative Detailed Results'"
                    :isAllowed="isAllowedToCheckResults"
                    @on-click="$router.push({ path: redirectAssePath, query: { teamId: certificationScoreData?.team_id?._id }})"
                  />
                </div>
              </div>
            </div>
            <div class="" style="border-top: 1px solid #ccc">
              <div>
                <div class="flex">
                  <div class="" style="flex: 50%; border-right: 1px solid #ccc">
                    <div class="p-8" style="border-bottom: 1px solid #ccc">
                      <div class="flex gap-4 items-center">
                        <h3 class="text-xl tracking-tighter font-bold">
                          Entity Type:
                        </h3>
                        <p class="text-lg font-medium">
                          {{ entityDisplay[entityChosen] }}
                        </p>
                      </div>
                    </div>
                    <div class="p-8">
                      <h3 class="text-2xl tracking-tighter font-bold">
                        Gender Equality & Social Inclusion Context
                      </h3>
                      <div class="flex mt-4 items-center">
                        <img class="mr-5 h-16 print:h-auto print:w-64" :src="countryData.flagUrl" />
                        <span class="font-bold" style="color: #2c2e35; font-size: 14px">{{ countryData.humanName
                          }}</span>
                      </div>
                      <div class="mt-5 CountryOverviewText">
                        <span class="font-bold text-xl" style="color: #2c2e35; font-size: 16px">Country overview</span>
                        <transition name="slide-fade" v-if="countryData.intro">
                          <item :text="countryData.intro" :country="countryData"></item>
                        </transition>
                        <div class="flex" style="float: right">
                          <a class="font-bold" style="color: #2177c1" href="javascript:void(0)"
                            @click="readMore('Country Overview', countryData.intro)">Read more</a>
                          <img src="@/assets/images/2x_certify/long-arrow-right.svg" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="p-8" style="flex: 50%">
                    <div class="mt-5 CountryOverviewText">
                      <span class="font-bold" style="color: #2c2e35; font-size: 16px">Sector overview - {{
                        certificationScoreData?.GEN_General_13 }}</span>
                      <transition name="slide-fade">
                        <item :text="sectorData?.intro" :country="sectorData"></item>
                      </transition>
                      <div class="flex" style="float: right" v-if="sectorData?.intro">
                        <a class="font-bold" style="color: #2177c1" href="javascript:void(0)"
                          @click="readMore('Sector Overview', sectorData?.intro)">Read more</a>
                        <img src="@/assets/images/2x_certify/long-arrow-right.svg" />
                      </div>
                    </div>
                    <div class="mt-5">
                      <p class="mt-2" style="color: #2c2e35; font-size: 14px">
                        Want to do a deeper dive into the GESI contextual
                        analysis? Go <span style="color: #2177c1" class="cursor-pointer"
                          @click="$router.push({ path: '/gesi-analysis/choose-country' })">here</span> to
                        select a country and sector GESI analysis along with the
                        What Works library of actions. If you do not yet have a
                        subscription, you can upgrade.
                      </p>
                      <!-- <div class="flex justify-between">
                        <button @click="$router.push({ path: '/gesi-analysis/choose-country' })"
                          class="mt-5 gap-4 standard-button px-4 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                          <span class="pr-1">Explore 2X GESI Contextual Analysis</span>
                        </button>
                        <button @click="$router.push({ path: '/gbv-risk/choose-country/true' })"
                          class="mt-5 gap-4 standard-button px-4 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                          <span class="pr-1">Explore GBV Risk</span>
                        </button>
                      </div>
                      <button
                        class="mt-5 gap-4 standard-button px-4 py-2 text-xs gap-1 rounded-lg transition duration-200 flex flex-row items-center justify-center">
                        <span class="pr-1">Explore full GESI Context Deep-Dive</span>
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overview-section" style="border-top: 1px solid rgb(204, 204, 204);">
              <div class="spiderchartEligibleWrepper p-8 dimesnion-section">
                <Dimension-Progress-Section :dimensions="dimensions" :isEntOwnEligible="isEntOwnEligible"
                  :isPortfolioVisible="isPortfolioVisible"
                  :isSupplyChainEligible="isSupplyChainEligible" :isProductsEligible="isProductsEligible"
                  :isEmploymentEligible="isEmploymentEligible" :isLeadershipEligible="isLeadershipEligible"
                  :certificationScoreData="certificationScoreData" :entrepreneurshipPercent="entrepreneurshipPercent"
                  :leadershipPercent="leadershipPercent" :employmentPercent="employmentPercent"
                  :productServicePercent="productServicePercent" :supplyChainPercent="supplyChainPercent"
                  :portfolioPercent="portfolioPercent" :options_eligible="options_eligible"
                  :options_notEligible="options_notEligible" :srManagerThreshold="srManagerThreshold"
                  :workforceThreshold="workforceThreshold" :boardThreshold="boardThreshold"
                  :entityChosen="entityChosen"></Dimension-Progress-Section>
                <!-- spiderchart -->
                <Dimension-Spiderchart :domainChartData="domainChartData" :dimensions="dimensions"
                  :isPortfolioVisible="isPortfolioVisible"
                  :entityChosen="entityChosen" :srManagerThreshold="srManagerThreshold"
                  :workforceThreshold="workforceThreshold" :boardThreshold="boardThreshold"
                  :assessmentYear="certificationScoreData.assessmentYear"
                  :supplyChainPercent="supplyChainPercent"></Dimension-Spiderchart>
              </div>
              <div class="spiderchartEligibleWrepper p-8 governance-section">
                <Governance-Progress-Section :govStrategicAction="govStrategicAction"
                  :govManagementSystem="govManagementSystem" :govData="govData" :govChartData="govChartData"
                  :certificationScoreData="certificationScoreData" :options_eligible="options_eligible"
                  :options_notEligible="options_notEligible" :srManagerThreshold="srManagerThreshold"
                  :workforceThreshold="workforceThreshold" :boardThreshold="boardThreshold"
                  :governanceColors="governanceColors"></Governance-Progress-Section>

                <!-- spiderchart -->
                <Governance-Spiderchart :govData="govData" :govChartData="govChartData"
                  :assessmentYear="certificationScoreData.assessmentYear"
                  :governanceColors="governanceColors"></Governance-Spiderchart>
              </div>
              <div class="p-8" style="border-top: 1px solid rgb(204, 204, 204)">
                <EvaluationButton
                  :text="'View Indicative Detailed Results'"
                  :isAllowed="isAllowedToCheckResults"
                  @on-click="$router.push({ path: redirectAssePath, query: { teamId: certificationScoreData?.team_id?._id }})"
                />
              </div>
            </div>
          </div>
          <div class="verification-recommendation-table-section mt-10">
            <h3 class="text-4xl tracking-tighter font-bold print:text-7xl" style="color: #19486a; max-width: 845px">
              <span>Verification recommendation</span>
            </h3>
            <verification-overall-recommendation class="mt-6" :key="'overall'+updateKey"
              :tableData="certificationOverallTableData" @verificationDataRefresh="fetchAgainCertificationData"></verification-overall-recommendation>
            <verification-recommendation class="mt-6" :key="'strategic'+updateKey" :tableData="strategicDimensionsActionTableData" @verificationDataRefresh="fetchAgainCertificationData"></verification-recommendation>
            <verification-recommendation class="mt-6" :key="'strategic0'+updateKey" :tableData="strategicThemesActionTableData" @verificationDataRefresh="fetchAgainCertificationData"></verification-recommendation>
            <!-- <verification-recommendation class="mt-6" :key="'goverance'+updateKey" :tableData="governanceTableData" @verificationDataRefresh="fetchAgainCertificationData"></verification-recommendation> -->
            <h3 class="text-3xl tracking-tighter font-bold print:text-4xl mt-6" style="color: #19486a; max-width: 845px">
              <span>Summary Report</span>
            </h3>
            <verified-data v-if="!$route.path.includes('/2X-Certification')" class="mt-6 mb-6" :tableData="summaryReportData"></verified-data>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-button-section text-right flex justify-end mt-20"
    :class="{'pointer-events-none' : !(areAllStatusesAcceptedOrRejected && completedPercentage == 100)}"
    v-if="!$route.path.includes('/2X-Certification') && endofcreated && !hideResults && certificationScoreData && (userAssessData?.status == 'In Progress' || ($route.path.includes('/2x-global') && status2xCertification == 'Verification Recommends Certification'))">
      <button class="red-button mr-4 flex items-center justify-center" @click="isRejectApplicationModal = true;">
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z"
            fill="#fff" />
        </svg>
        Reject application
      </button>
      <button class="green-button flex items-center justify-center" @click="isApproveApplicationModal = true">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="icon/check">
            <path id="Vector"
              d="M9.76691 15.6786L6.21092 12.258L5 13.4146L9.76691 18L20 8.1566L18.7976 7L9.76691 15.6786Z"
              fill="#fff" />
          </g>
        </svg>
        Approve application
      </button>
    </div>
    <!-- Reject application modal -->
    <RejectApplicationModal v-show="isRejectApplicationModal" @close="isRejectApplicationModal = false" @show-feedback-modal="showFeedbackModal" />
    <!-- Approve application modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isApproveApplicationModal">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 624px">
          <div class="Modal_Readmorestyle p-6">
            <div class="ModalInnerStyle">
              <div class="heading-section flex justify-between items-center">
                <h1 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Are you sure you want to Approve this application.
                </h1>
              </div>
              <div class="mt-7 flex justify-between gap-4">
                <button class="cancel-button flex justify-center" @click="isApproveApplicationModal = false">
                  Cancel
                </button>
                <button class="save-button flex justify-center" @click="approveApplication()">Approve</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FeedbackModal       
      :isVisible="isFeedbackModalVisible"
      @submit-feedback="handleFeedback"
      @close-feedback-modal="closeFeedbackModal"
    />
    <!-- Country & Sector Modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isReadMore">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 630px; width: 100%">
          <div class="Modal_Readmorestyle p-6 pr-0">
            <div class="ModalInnerStyle" style="max-height: 630px;overflow: auto;padding-right: 30px;" id="style-1">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {{ title }}
              </h3>
              <transition name="slide-fade">
                <item :text="readMoreInfo" :country="title == 'Country Overview' ? countryData : sectorData"
                  style="font-size: 15px"></item>
              </transition>
            </div>
            <div class="cross_Icon" @click="isReadMore = false">
              <img src="../../assets/images/cros-icon.svg" alt="cross_Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reject reason modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isRejectReasonModalOpen">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="max-width: 624px">
          <div class="Modal_Readmorestyle p-6">
            <div class="ModalInnerStyle">
              <div class="heading-section flex justify-between items-center">
                <h1 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Remarks:
                </h1>
              </div>
              <div class="font-medium mt-2">
                <p>
                  {{ userCertRequestData?.verifierRemarks?.reason ? userCertRequestData?.verifierRemarks?.reason : 'No remarks' }}
                </p>
              </div>
              <div class="mt-7 flex justify-end">
                <button class="cancel-button flex justify-center" style="width:fit-content" @click="isRejectReasonModalOpen = false">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EvaluationButton from "@/components/EvaluationButton.vue";

const axios = require("axios");
import pageLoader from "@/components/page-loader.vue";
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import auth from "@/helpers/auth";
import Cookies from "@/helpers/cookies";
import env from "@/../env";
import apiService from '@/helpers/apiServices.js';
import item from "@/components/text.vue";
import DimensionProgressSection from "@/components/2x-certification/dimension-progress-section.vue";
import DimensionSpiderchart from "@/components/2x-certification/dimension-spiderchart.vue";
import GovernanceProgressSection from "@/components/2x-certification/governance-progress-section.vue";
import GovernanceSpiderchart from "@/components/2x-certification/governance-spiderchart.vue";
import VerificationRecommendation from "@/components/2x-certification/verification-recommendation-table.vue";
import RejectApplicationModal from "@/components/2x-certification/rejectApplicationModal.vue";
import VerificationOverallRecommendation from "@/components/2x-certification/verification-overall-recommendation-table.vue";
import VerifiedData from "@/components/2x-certification/verified-data-table.vue";
import FeedbackModal from "@/components/common/FeedbackModal.vue";

export default {
  name: "VerificationAssessment",
  data() {
    return {
      endofcreated: false,
      certificationScoreData: [],
      entityChosen: "",
      user: [],
      certification_result: {},
      cert_categories: {
        Good: {
          name: "Good",
          darkColor: "#FF3A21",
          lightColor: "#FFECE8",
        },
        Advanced: {
          name: "Advanced",
          darkColor: "#FCC30B",
          lightColor: "#FFF8E0",
        },
        "Best in Class": {
          name: "Best in Class",
          darkColor: "#4C9F38",
          lightColor: "#E5FAFA",
        },
      },
      dimensions: {
        Entrepreneurship: {
          color: "#395AFF",
          icon: "@/assets/images/2x_certify/entrepreneurship.svg",
          score: 0,
          threshold: 100,
        },
        Leadership: {
          color: "#3F7E44",
          icon: "@/assets/images/2x_certify/leadership.svg",
          score: 0,
          threshold: 100,
        },
        Employment: {
          color: "#DDA63A",
          icon: "@/assets/images/2x_certify/employment.svg",
          score: 0,
          threshold: 100,
        },
        Products: {
          color: "#666461",
          icon: "@/assets/images/2x_certify/products.svg",
          score: 0,
          threshold: 100,
        },
        SupplyChain: {
          color: "#19486A",
          icon: "@/assets/images/2x_certify/supplychain.svg",
          score: 0,
          threshold: 100,
        },
        Governance: {
          color: "#DDA63A",
          icon: "@/assets/images/2x_certify/governance.svg",
          score: 0,
          threshold: 100,
        },
        Portfolio: {
          color: "#7C7C7C",
          icon: "@/assets/images/2x_certify/portfolio.svg",
          score: 0,
          threshold: 100,
        },
      },
      srManagerThreshold: "",
      boardThreshold: "",
      workforceThreshold: "",
      thresholdsData: [],
      thresholds: [],
      industrySearchPlaceholder: "",
      selectedIndustries: [],
      domainChartData: [],
      entrepreneurshipPercent: "",
      leadershipPercent: "",
      employmentPercent: "",
      productServicePercent: "",
      supplyChainPercent: "",
      portfolioPercent: "",
      govStrategicAction: "",
      govManagementSystem: "",
      govData: "",
      govChartData: [],
      isSupplyChainEligible: "",
      isProductsEligible: "",
      isEmploymentEligible: "",
      isLeadershipEligible: "",
      isEntOwnEligible: "",
      options_eligible: {
        text: {
          color: "#4C9F38",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#4C9F38",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      options_notEligible: {
        text: {
          color: "#FF3A21",
          fontSize: 12,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          shadowEnable: false,
          shadowColor: "#000000",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#FF3A21",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 30,
          horizontalTextAlign: 12,
          zeroOffset: 0,
          strokeWidth: 12,
          progressPadding: 2,
          type: "circle",
        },
      },
      governanceColors: {
        strategicAction: "#4A742C",
        managementSystems: "#2574BB",
        data: "#F05323",
      },
      strategicDimensionsActionTableData: {},
      strategicThemesActionTableData: {},
      isRejectApplicationModal: false,
      isApproveApplicationModal: false,
      userAssessData: [],
      countryData: [],
      sectorData: [],
      isReadMore: false,
      title: "",
      readMoreInfo: [],
      certificationOverallTableData: [],
      governanceTableData: [],
      entrepreneurshipLevel: "",
      leadershipLevel: "",
      employmentLevel: "",
      productServiceLevel: "",
      supplyChainLevel: "",
      portfolioLevel: "",
      govStrategicActionLevel: "",
      govManagementSystemLevel: "",
      govDataLevel: "",
      expectedLevels: ['Good', 'Best in Class', 'Advanced', 'Failed'],
      certificationSurveyData: [],
      entityDisplay: {
        'start-up': 'Start up',
        'corporate': 'Corporate',
        'sme': 'SME',
        'fund-manager': 'Fund Manager',
        'financial-institution': 'Financial Institution',
      },
      updateKey: 0,
      redirectAssePath : '/admin/full-assessment',
      summaryReportData: {
        heading: [
          "Indicator",
          "Sub Domain",
          "Question",
          "Changed from",
          "Changed to",
          "Date",
          "Verification Status",
        ],
        data: []
      },
      isFeedbackModalVisible: false,
      dateOfSubmission: null,
      completedPercentage: 0,
      isPortfolioVisible: true,
      status2xCertification: "",
      isRejectReasonModalOpen: false,
      userCertRequestData: [],
    };
  },
  components: {
    EvaluationButton,
    item,
    BreadCrumbs,
    pageLoader,
    DimensionProgressSection,
    DimensionSpiderchart,
    GovernanceProgressSection,
    GovernanceSpiderchart,
    VerificationRecommendation,
    RejectApplicationModal,
    VerificationOverallRecommendation,
    VerifiedData,
    FeedbackModal
  },
  props: {
    hideResults: Boolean
  },
  async mounted() {
    // Loading Breadcrumbs items
    if (this.$route.path === "/2x-global/admin/assessment") {
      this.$store.commit("setNewBreadcrumbs", [
        { name: "2X Global Dashboard", url: "/2x-global/2X-Certification" },
        {
          name: "2X Global Verification",
          url: `/2x-global/admin/assessment?teamId=${this.$route.query.teamId}`
        }
      ]);
      this.redirectAssePath = "/2x-global/admin/full-assessment";
    } else {
      this.$store.commit("setNewBreadcrumbs", [
        { name: "2X Verifier Dashboard", url: "/admin" },
        {
          name: "2X Verification",
          url: `/admin/assessment?teamId=${this.$route.query.teamId}`
        }
      ]);
      this.redirectAssePath = "/admin/full-assessment";
    }

    this.$store.commit("resetCitations");
    this.user = await auth.me().catch(() => {
      console.log("Error login");
    });

    // As soon as we have user information, we validate information
    await this.getVerifierData();
    if(this.$route.query.teamId) {
      await apiService.fetch2xUserSurvey('2x', this.$route.query.teamId)
      .then(async response => {
        this.certificationSurveyData = await response.data.data;
      })
      await apiService
        .fetch2xCertRequest(this.$route.query.teamId)
        .then(async (response) => {
          this.userCertRequestData = response?.data?.data;
          this.dateOfSubmission = response.data.data.fullSurveySubmittedDate || response.data.data.min;
          this.status2xCertification = response?.data?.data?.status;
        });
    }
    else {
      await apiService.fetch2xUserSurvey('2x')
      .then(async response => {
        this.certificationSurveyData = await response.data.data;
      })
      await apiService
      .fetch2xCertRequest(this.user.organization._id)
      .then(async (response) => {
        this.dateOfSubmission = response.data.data.fullSurveySubmittedDate;
      });
    }
    if (this.certificationSurveyData) {
      this.certificationSurveyData?.forEach((data) => {
        if (data.surveyId === "2x-intake") {
          this.entityChosen = data.tag;
        }
      });
    }
    await this.fetchCertificationData();
    if (this.$route.query.teamId) {
      await this.getSummaryReportData();  
    }
    this.endofcreated = true;
    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
  },
  methods: {
    async fetchAgainCertificationData() {
      await this.fetchCertificationData();
      await this.updateKey++;
    },
    readMore(title, data) {
      this.isReadMore = true;
      this.title = title;
      this.readMoreInfo = data;
    },
    async getCountryData() {
      await apiService.fetchCountryData(this.certificationScoreData?.ISO)
        .then(async response => {
          this.countryData = response.data.data;
        })
    },
    async getSectorData() {
      await apiService.fetchSectorData(this.certificationScoreData?.ISO, this.certificationScoreData?.GEN_General_13)
        .then(async response => {
          this.sectorData = response.data.data;
        })
    },
    async getVerifierData() {
      await apiService.fetchVerifierRequests(this.$route.query.teamId)
        .then(async response => {
          this.userAssessData = response.data.data;
          // User is Verifier - Check payment
          if (
            this.user.isVerifier &&
            this.userAssessData.paymentStatus !== "Completed" &&
            this.$route.path.includes('/admin') &&
            !this.user.email.split('@')[1].includes('equilo.')
          ) {
            await this.$router.push({ path: "/admin" });
          }

          if (
            this.userAssessData.status !== 'Rejected' &&
            this.userAssessData.status !== 'Certified' &&
            this.userAssessData.status !== 'In Progress' &&
            this.userAssessData.status !== ''
          ) {
            this.userAssessData.status = 'Verified'
          }
        })
    },
    approveApplication() {
      const status = this.$route.path.includes("2x-global") ? "Certified" : "Verified";
      this.updateApplicationStatus(status);
    },
    showFeedbackModal() {
      this.isFeedbackModalVisible = true;
    },
    closeFeedbackModal() {
      this.isFeedbackModalVisible = false;
    },
    async handleFeedback(feedback) {
      try {
        await apiService.postFeedback(feedback.rating, feedback.comment, "Verifiers Portal");
      }
      catch (error) {
        console.error(error);
      }
    },
    updateApplicationStatus(status) {
      try {
        const updateTwoxStatus = this.$route.path.includes("2x-global")
      ? apiService.updateGlobalStatus
      : apiService.updateAssessStatus;
        updateTwoxStatus(this.$route.query.teamId, status)
          .then(() => {
            this.isApproveApplicationModal = false;
            this.$swal.fire({
              icon: "success",
              text: "Application Approved successfully!",
              showConfirmButton: true,
            }).then(() => {
                // window.location.reload();
                this.isFeedbackModalVisible = true;
            });
          }).catch(() => {
            this.isApproveApplicationModal = false;
            this.$swal.fire({
              icon: "error",
              text: "Somwething went wrong!",
              showConfirmButton: true,
            });
          });
      } catch (e) {
        this.isRejectApplicationModal = false;
        this.$swal.fire({
          icon: "error",
          text: "Somwething went wrong!",
          showConfirmButton: true,
        });
        throw new Error(e);
      }
    },
    async fetchCertificationData() {
        await apiService.fetch2xCertificationData(this.entityChosen, this.$route?.query?.teamId, 'Full')
        .then(async (res) => {
          this.certificationScoreData = await res.data.data;
          this.isPortfolioVisible = !this.certificationScoreData?.['PORT_Portfolio_900']?.includes('none');
          if (this.certificationScoreData?.ISO) {
            await this.getCountryData();
            await this.getSectorData();
          }
          await this.calculateCertLevel();
          await this.calculateThreshold();
          if (this.certificationScoreData.level == "Good") {
            this.certification_result = this.cert_categories.Good;
          } else if (
            this.certificationScoreData.level == "Best in class" ||
            this.certificationScoreData.level == "Best in Class"
          ) {
            this.certification_result = this.cert_categories.BestInClass;
          } else if (this.certificationScoreData.level == "Advanced") {
            this.certification_result = this.cert_categories.Advanced;
          }
          if (this.entityChosen == "fund-manager") {
            // Fund dimension percentage
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.ENTOWN_2X_104;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_104;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_104;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.PROD_2X_104;
            this.supplyChainPercent =
              this.certificationScoreData?.SUPCH_2X_104 == "NA"
                ? 0
                : this.certificationScoreData?.SUPCH_2X_104;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == "NA"
                ? 0
                : this.certificationScoreData?.PORT_Portfolio_1000;

            // Fund dimension level
            this.entrepreneurshipLevel =
              this.expectedLevels.includes(this.certificationScoreData?.ENTOWN_2X_204) ? this.certificationScoreData?.ENTOWN_2X_204 : "";
            this.leadershipLevel =
              this.expectedLevels.includes(this.certificationScoreData?.LEAD_2X_203) ? this.certificationScoreData?.LEAD_2X_203 : "";
            this.employmentLevel =
              this.expectedLevels.includes(this.certificationScoreData?.EMPLOY_2X_204) ? this.certificationScoreData?.EMPLOY_2X_204 : "";
            this.productServiceLevel =
              this.expectedLevels.includes(this.certificationScoreData?.PROD_2X_204) ? this.certificationScoreData?.PROD_2X_204 : "";
            this.supplyChainLevel =
              this.expectedLevels.includes(this.certificationScoreData?.SUPCH_2X_204) ? this.certificationScoreData?.SUPCH_2X_204 : "";
            this.portfolioLevel =
              this.expectedLevels.includes(this.certificationScoreData?.PORT_2X_901) ? this.certificationScoreData?.PORT_2X_901 : "";

              // Fund governace percentage
            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_103;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_103;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_114;
              // Goverance levels data
            this.govStrategicActionLevel =
              this.expectedLevels.includes(this.certificationScoreData?.GOVACC_Strategic_action_1001) ? this.certificationScoreData?.GOVACC_Strategic_action_1001 : "";
            this.govManagementSystemLevel =
              this.expectedLevels.includes(this.certificationScoreData?.GOVACC_Management_systems_re_gender_1001) ? this.certificationScoreData?.GOVACC_Management_systems_re_gender_1001 : "";
            this.govDataLevel =
              this.expectedLevels.includes(this.certificationScoreData?.GOVACC_Gender_disaggregated_data_1001) ? this.certificationScoreData?.GOVACC_Gender_disaggregated_data_1001 : "";
          } else {
            // Company dimension percentage
            this.entrepreneurshipPercent =
              this.certificationScoreData?.ENTOWN_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.ENTOWN_2X_101;
            this.leadershipPercent = this.certificationScoreData?.LEAD_2X_101;
            this.employmentPercent = this.certificationScoreData?.EMPLOY_2X_101;
            this.productServicePercent =
              this.certificationScoreData?.PROD_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.PROD_2X_101;
            this.supplyChainPercent =
              this.certificationScoreData?.SUPCH_2X_101 == "NA"
                ? 0
                : this.certificationScoreData?.SUPCH_2X_101;
            this.portfolioPercent =
              this.certificationScoreData?.PORT_Portfolio_1000 == 'NA' ? 0 : this.certificationScoreData?.PORT_Portfolio_1000;

            // Company dimension level
            this.entrepreneurshipLevel =
              this.expectedLevels.includes(this.certificationScoreData?.ENTOWN_2X_200) ? this.certificationScoreData?.ENTOWN_2X_200 : "";
            this.leadershipLevel =
              this.expectedLevels.includes(this.certificationScoreData?.LEAD_2X_200) ? this.certificationScoreData?.LEAD_2X_200 : "";
            this.employmentLevel =
              this.expectedLevels.includes(this.certificationScoreData?.EMPLOY_2X_200) ? this.certificationScoreData?.EMPLOY_2X_200 : "";
            this.productServiceLevel =
              this.expectedLevels.includes(this.certificationScoreData?.PROD_2X_200) ? this.certificationScoreData?.PROD_2X_200 : "";
            this.supplyChainLevel =
              this.expectedLevels.includes(this.certificationScoreData?.SUPCH_2X_200) ? this.certificationScoreData?.SUPCH_2X_200 : "";

            // Company goverance percentage
            this.govStrategicAction =
              this.certificationScoreData?.GOVACC_Strategic_action_102;
            this.govManagementSystem =
              this.certificationScoreData?.GOVACC_Management_systems_re_gender_102;
            this.govData =
              this.certificationScoreData?.GOVACC_Gender_disaggregated_data_113;

            // Company goverance level
            this.govStrategicActionLevel =
              this.expectedLevels.includes(this.certificationScoreData?.GOVACC_Strategic_action_1000) ? this.certificationScoreData?.GOVACC_Strategic_action_1000 : "";
            this.govManagementSystemLevel =
              this.expectedLevels.includes(this.certificationScoreData?.GOVACC_Management_systems_re_gender_1000) ? this.certificationScoreData?.GOVACC_Management_systems_re_gender_1000 : "";
            this.govDataLevel =
              this.expectedLevels.includes(this.certificationScoreData?.GOVACC_Gender_disaggregated_data_1000) ? this.certificationScoreData?.GOVACC_Gender_disaggregated_data_1000 : "";
          }
          if (this.isPortfolioVisible && (this.entityChosen == "fund-manager" || this.entityChosen == "financial-institution")) {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
              this.portfolioPercent,
            ];
          } else {
            this.domainChartData = [
              this.entrepreneurshipPercent,
              this.leadershipPercent,
              this.employmentPercent,
              this.productServicePercent,
              this.supplyChainPercent,
            ];
          }
          this.govChartData = [
            this.govStrategicAction,
            this.govManagementSystem,
            this.govData,
          ];
          if (this.entityChosen == "fund-manager") {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_304"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_304"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_304"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_304"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_304"];
          } else {
            this.isSupplyChainEligible =
              this.certificationScoreData["SUPCH_2X_300"];
            this.isProductsEligible =
              this.certificationScoreData["PROD_2X_300"];
            this.isEmploymentEligible =
              this.certificationScoreData["EMPLOY_2X_300"];
            this.isLeadershipEligible =
              this.certificationScoreData["LEAD_2X_300"];
            this.isEntOwnEligible =
              this.certificationScoreData["ENTOWN_2X_300"];
          }
          this.certificationOverallTableData = {
            heading: [
              "2X Certification Overall",
              "Results",
              !this.hideResults ? "Actions" : "Status"
            ],
            data: [
              {
                rowHeading: "2X Certification Level",
                badgeName: "2X Certification Level",
                percent: ['Good', 'Best in Class', 'Advanced'].includes(this.certificationScoreData.level) ? "Yes" : "No",
                badge: this.expectedLevels.includes(this.certificationScoreData.level) ? this.certificationScoreData.level : "",
                variable: this.twoXOverallScoreVariableName,
                status: this.certificationScoreData?.validationRecommendation?.[this.twoXOverallScoreVariableName]?.status,
              },
              {
                rowHeading: "ESG",
                badgeName: "ESG",
                percent: this.certificationScoreData?.ESG_ESG_100 == 'Yes' ? 'Yes' : 'No',
                badge: this.certificationScoreData?.ESG_ESG_100 == 'Yes' ? 'Yes' : 'No',
                variable: 'ESG_ESG_100',
                status: this.certificationScoreData?.validationRecommendation?.ESG_ESG_100?.status,
              },
              {
                rowHeading: "Commitments",
                badgeName: "Commitments",
                percent: this.COM_Commitment == 'Yes' ? 'Yes' : 'No',
                badge: this.COM_Commitment == 'Yes' ? 'Yes' : 'No',
                variable: this.COM_CommitmentVariableName,
                status: this.certificationScoreData?.validationRecommendation?.[this.COM_CommitmentVariableName]?.status,
              },
              {
                rowHeading: "GBVH Requirements",
                badgeName: "GBVH",
                percent: this.certificationScoreData?.EMPLOY_Workplace_diversity_practices_3000 > 0 ? 'Yes' : 'No',
                badge: this.certificationScoreData?.EMPLOY_Workplace_diversity_practices_3000 > 0 ? 'Yes' : 'No',
                variable: 'EMPLOY_Workplace_diversity_practices_3000',
                status: this.certificationScoreData?.validationRecommendation?.['EMPLOY_Workplace_diversity_practices_3000']?.status,
              },
            ],
          }
          this.strategicDimensionsActionTableData = {
            heading: [
              "Dimension",
              "Results",
              "2X  Threshold Criteria",
              "Badge",
              !this.hideResults ? "Actions" : "Status"
            ],
            data: [
            {
                rowHeading: "Strategic action",
                badgeName: "Strategic action",
                percent: this.govStrategicAction,
                badge: this.govStrategicActionLevel,
                variable: this.entityChosen == "fund-manager" ? 'GOVACC_Strategic_action_103' : 'GOVACC_Strategic_action_102',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'GOVACC_Strategic_action_103' : 'GOVACC_Strategic_action_102']?.status,
                thresholdCriteria: `Good =  1 practice <br/>
                                    Advanced = 1-4 Themes/ Dimensions addressed AND strategy period > 3 years OR 3rd party assessment <br/>
                                    Best in class > 5 Themes/ Dimensions addressed AND strategy period > 3 years OR 3rd party assessment`
              },
              {
                rowHeading: "Management Systems",
                badgeName: "Management Systems",
                percent: this.govManagementSystem,
                badge: this.govManagementSystemLevel,
                variable: this.entityChosen == "fund-manager" ? 'GOVACC_Management_systems_re_gender_103' : 'GOVACC_Management_systems_re_gender_102',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'GOVACC_Management_systems_re_gender_103' : 'GOVACC_Management_systems_re_gender_102']?.status,
                thresholdCriteria: `Good =  1 practice <br/>
                                    Advanced = 2-4 practices <br/>
                                    Best in class >  5 practices`
              },
              {
                rowHeading: "Data",
                badgeName: "Data",
                percent: this.govData,
                badge: this.govDataLevel,
                variable: this.entityChosen == "fund-manager" ? 'GOVACC_Gender_disaggregated_data_114' : 'GOVACC_Gender_disaggregated_data_113',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'GOVACC_Gender_disaggregated_data_114' : 'GOVACC_Gender_disaggregated_data_113']?.status,
                thresholdCriteria: `Good = Data regularly collected or commitment to do so <br/>
                                    Advanced = 1 Theme/ Dimension analysed or commitment to do so <br/>
                                    Best in class > 2 Theme/ Dimensions analysed or commitment to do so <br/>
                                    <br/>
                                    IF FUND DISPLAY DIFFERENT: <br/>
                                    Good= Data regularly collected or commitment to do so <br/>
                                    Advanced = 1 Theme/ Dimension analysed or commitment do so at BOTH Fund Manager AND Portfolio level <br/>
                                    Best-in-Class > 2 Themes/ Dimensions analysed or commitment to do so at BOTH Fund Manager AND Portfolio level`
              },
              {
                rowHeading: "Entrepreneurship & Ownership",
                badgeName: "Entrepreneurship",
                percent: this.entrepreneurshipPercent,
                badge: this.entrepreneurshipLevel,
                variable: this.entityChosen == "fund-manager" ? 'ENTOWN_2X_104' : 'ENTOWN_2X_101',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'ENTOWN_2X_104' : 'ENTOWN_2X_101']?.status,
                thresholdCriteria: `Good > 50% women founders with active role AND 1 role selected OR > 51% shares owned by women <br/>
                                    Advanced > 50% women founders with active role AND 1 role selected AND at least one intersectional aspect OR >51% shares owned by women, <br/>
                                    Best in class  >50% women founders with active role AND 2 roles selected OR 2 intersectional aspects > 0% OR 1 intersectional aspect > 10%`
              },
              {
                rowHeading: "Leadership",
                badgeName: "Leadership",
                percent: this.leadershipPercent,
                badge: this.leadershipLevel,
                variable: this.entityChosen == "fund-manager" ? 'LEAD_2X_104' : 'LEAD_2X_101',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'LEAD_2X_104' : 'LEAD_2X_101']?.status,
              },
              {
                rowHeading: "Employment",
                badgeName: "Employment",
                percent: this.employmentPercent,
                badge: this.employmentLevel,
                variable: this.entityChosen == "fund-manager" ? 'EMPLOY_2X_104' : 'EMPLOY_2X_101',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'EMPLOY_2X_104' : 'EMPLOY_2X_101']?.status,
              },
              {
                rowHeading: "Products & Services",
                badgeName: "Products & Services",
                percent: this.productServicePercent,
                badge: this.productServiceLevel,
                variable: this.entityChosen == "fund-manager" ? 'PROD_2X_104' : 'PROD_2X_101',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'PROD_2X_104' : 'PROD_2X_101']?.status,
              },
              {
                rowHeading: "Supply Chain",
                badgeName: "Supply Chain",
                percent: this.supplyChainPercent,
                badge: this.supplyChainLevel,
                variable: this.entityChosen == "fund-manager" ? 'SUPCH_2X_104' : 'SUPCH_2X_101',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'SUPCH_2X_104' : 'SUPCH_2X_101']?.status,
              },
            ],
          }
          this.strategicThemesActionTableData = {
            heading: [
              "Themes",
              "Results",
              "2X  Threshold Criteria",
              "Badge",
              !this.hideResults ? "Actions" : "Status"
            ],
            data: [
              {
                rowHeading: "Gender based violence and harassment",
                badgeName: "GBVH",
                percent: this.certificationScoreData['2X_2X_Score_Overall_511'],
                badge: this.certificationScoreData['2X_2X_Score_Overall_523'],
                variable: '2X_2X_Score_Overall_511',
                status: this.certificationScoreData?.validationRecommendation?.['2X_2X_Score_Overall_511']?.status,
              },
              {
                rowHeading: "Health & wellbeing",
                badgeName: "Health & wellbeing",
                percent: this.certificationScoreData['2X_2X_Score_Overall_508'],
                badge: this.certificationScoreData['2X_2X_Score_Overall_521'],
                variable: '2X_2X_Score_Overall_508',
                status: this.certificationScoreData?.validationRecommendation?.['2X_2X_Score_Overall_508']?.status,
              },
              {
                rowHeading: "JEDI",
                badgeName: "JEDI",
                percent: this.certificationScoreData['2X_2X_Score_Overall_507'],
                badge: this.certificationScoreData['2X_2X_Score_Overall_520'],
                variable: '2X_2X_Score_Overall_507',
                status: this.certificationScoreData?.validationRecommendation?.['2X_2X_Score_Overall_507']?.status,
              },
              {
                rowHeading: "Care",
                badgeName: "Care",
                percent: this.certificationScoreData['2X_2X_Score_Overall_510'],
                badge: this.certificationScoreData['2X_2X_Score_Overall_522'],
                variable: '2X_2X_Score_Overall_510',
                status: this.certificationScoreData?.validationRecommendation?.['2X_2X_Score_Overall_510']?.status,
              },
              {
                rowHeading: "Economic Empowerment",
                badgeName: "Pay",
                percent: this.certificationScoreData['2X_2X_Score_Overall_506'],
                badge: this.certificationScoreData['2X_2X_Score_Overall_519'],
                variable: '2X_2X_Score_Overall_506',
                status: this.certificationScoreData?.validationRecommendation?.['2X_2X_Score_Overall_506']?.status,
              },
            ],
          }
          if (this.entityChosen == "fund-manager") {
            this.strategicDimensionsActionTableData.data.push(
              {
                rowHeading: "Portfolio",
                badgeName: "Portfolio",
                percent: this.portfolioPercent,
                badge: this.portfolioLevel,
                variable: 'PORT_Portfolio_1000',
                status: this.certificationScoreData?.validationRecommendation?.['PORT_Portfolio_1000']?.status,
              }
            )
          }
          this.governanceTableData = {
            heading: [
              "Governance",
              "Results",
              "2X  Threshold Criteria",
              "Badge",
              !this.hideResults ? "Actions" : "Status"
            ],
            data: [
              {
                rowHeading: "Strategic action",
                badgeName: "Strategic action",
                percent: this.govStrategicAction,
                badge: this.govStrategicActionLevel,
                variable: this.entityChosen == "fund-manager" ? 'GOVACC_Strategic_action_103' : 'GOVACC_Strategic_action_102',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'GOVACC_Strategic_action_103' : 'GOVACC_Strategic_action_102']?.status,
              },
              {
                rowHeading: "Management Systems",
                badgeName: "Management Systems",
                percent: this.govManagementSystem,
                badge: this.govManagementSystemLevel,
                variable: this.entityChosen == "fund-manager" ? 'GOVACC_Management_systems_re_gender_103' : 'GOVACC_Management_systems_re_gender_102',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'GOVACC_Management_systems_re_gender_103' : 'GOVACC_Management_systems_re_gender_102']?.status,
              },
              {
                rowHeading: "Data",
                badgeName: "Data",
                percent: this.govData,
                badge: this.govDataLevel,
                variable: this.entityChosen == "fund-manager" ? 'GOVACC_Gender_disaggregated_data_114' : 'GOVACC_Gender_disaggregated_data_113',
                status: this.certificationScoreData?.validationRecommendation?.[this.entityChosen == "fund-manager" ? 'GOVACC_Gender_disaggregated_data_114' : 'GOVACC_Gender_disaggregated_data_113']?.status,
              },
            ],
          }
        })
        .catch(() => {
          this.endofcreated = true;
        });
    },
    async getSummaryReportData() {
      const response = await apiService.fetchSummaryReportData(this.$route.query.teamId);
      const result = response.data.data.result;
      this.completedPercentage = Math.round(response.data.data.completedPercentage);

      const transformedData = Object.keys(result)
      .map(key => {
        const item = result[key];
        if (!item.dataPointChangeHistory.length && !item.verificationChangeHistory.length) {
          return;
        }
        if (!item.indicator || !item.dimension) {
          return;
        }
        const lastChange = item.dataPointChangeHistory[0] || {};
        const verification = item.verificationChangeHistory[0] || {};
        
        return {
          indicator: item.indicator,
          dimension: item.dimension,
          changedFrom: lastChange.previousAnswer || '-',
          changedTo: lastChange.updatedAnswer || '-',
          latestUpdatedDate: lastChange.createdAt || verification.createdAt || '-',
          verificationStatus: verification.updatedAnswer,
          verificationDate: verification.createdAt || '',
          question: item.question
        };
      })
      .filter(item => item);

      this.summaryReportData.data = transformedData;

        // Group the transformed data by indicator
      const groupedByIndicator = transformedData.reduce((acc, item) => {
        // Create array for this indicator if it doesn't exist
        if (!acc[item.indicator]) {
          acc[item.indicator] = {
            indicator: item.indicator,
            dimension: item.dimension,
            items: [],
            verificationStatus: item.verificationStatus,
            latestUpdatedDate: '',
            isExpanded: false
          };
        }
        
        // Add the item to its indicator group
        acc[item.indicator].items.push({
          question: item.question,
          changedFrom: item.changedFrom,
          changedTo: item.changedTo,
          latestUpdatedDate: item.latestUpdatedDate,
          verificationStatus: item.verificationStatus,
          verificationDate: item.verificationDate
        });

        if (item.latestUpdatedDate) {
          if (!acc[item.indicator].latestUpdatedDate || 
              new Date(item.latestUpdatedDate) > new Date(acc[item.indicator].latestUpdatedDate)) {
            acc[item.indicator].latestUpdatedDate = item.latestUpdatedDate;
          }
        }

        return acc;
      }, {});

      this.summaryReportData.data = Object.values(groupedByIndicator);
      console.log("Chalo hum chale", this.summaryReportData.data);
      console.log("grouped indicator", groupedByIndicator);
    },
    calculateCertLevel() {
      if (this.entityChosen == "start-up") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "sme") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (this.entityChosen == "corporate") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_400"];
      } else if (
        this.entityChosen == "Fund Manager" ||
        this.entityChosen == "fund-manager"
      ) {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_403"];
      } else if (this.entityChosen == "financial-institution") {
        this.certificationScoreData["level"] =
          this.certificationScoreData["2X_2X_Score_Overall_404"];
      }
    },
    async calculateThreshold() {
      const thresholdsUrl = env.apiUrl + "/thresholds2x/all";
      const res = await axios.get(thresholdsUrl, {
        headers: {
          Authorization: "Bearer " + Cookies.get("session"),
        },
      });
      this.thresholds = res.data.data.filter((item) => item.threshold != "");
      if (
        this?.certificationScoreData?.ISO &&
        this?.certificationScoreData?.GEN_General_13
      ) {
        await this.getThresholdsData(
          this.certificationScoreData.ISO,
          this.certificationScoreData?.GEN_General_13
        );
      }
      this.industrySearchPlaceholder =
        this.certificationScoreData?.GEN_General_13;
      const workforce = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData?.GEN_General_11) &&
          item.indicator == "Employees" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const srManager = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Senior Manager" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );
      const board = this.thresholds.filter(
        (item) =>
          item.uniqueId.includes(this.certificationScoreData.GEN_General_11) &&
          item.indicator == "Board Member" &&
          (item.industry == this.certificationScoreData?.GEN_General_13 ||
            item.industry == "Overall")
      );

      this.workforceThreshold =
        workforce.length == 1
          ? workforce?.[0]?.threshold
          : workforce?.filter(
            (item) =>
              item.industry == this.certificationScoreData?.GEN_General_13
          )?.[0]?.threshold;

      this.srManagerThreshold =
        srManager.length == 1
          ? srManager?.[0]?.threshold
          : srManager?.filter(
            (item) =>
              item.industry == this.certificationScoreData?.GEN_General_13
          )?.[0]?.threshold;

      this.boardThreshold =
        board.length == 1
          ? board?.[0]?.threshold
          : board?.filter(
            (item) =>
              item.industry == this.certificationScoreData?.GEN_General_13
          )?.[0]?.threshold;

      this.endofcreated = true;
    },
    async getThresholdsData(recordId, sector) {
      await apiService.fetchThresholdsData(recordId, sector)
        .then(async (response) => {
          this.thresholdsData = await response.data.data;
      });
    },
  },
  computed: {
    twoxEligible() {
      let val;
      if (this.entityChosen == "fund-manager") {
        val = this.certificationScoreData["2X_2X_Score_Overall_301"];
      } else if (this.entityChosen == "financial-institution") {
        val = this.certificationScoreData["2X_2X_Score_Overall_302"];
      } else if (
        this.entityChosen == "start-up" ||
        this.entityChosen == "sme" ||
        this.entityChosen == "corporate"
      ) {
        val = this.certificationScoreData["2X_2X_Score_Overall_300"];
      }
      return val;
    },
    COM_Commitment() {
      let val;
      if (this.entityChosen == "fund-manager") {
        val = this.certificationScoreData.COM_Commitment_301;
      } else {
        val = this.certificationScoreData.COM_Commitment_300;
      }
      return val;
    },
    twoXOverallScoreVariableName() {
      if (this.entityChosen == "start-up") {
        return "2X_2X_Score_Overall_400";
      } else if (this.entityChosen == "sme") {
        return "2X_2X_Score_Overall_400";
      } else if (this.entityChosen == "corporate") {
        return "2X_2X_Score_Overall_400";
      } else if (
        this.entityChosen == "Fund Manager" ||
        this.entityChosen == "fund-manager"
      ) {
        return "2X_2X_Score_Overall_403";
      } else if (this.entityChosen == "financial-institution") {
        return "2X_2X_Score_Overall_404";
      }
      return "";
    },
    COM_CommitmentVariableName() {
      if (this.entityChosen == "fund-manager") {
        return "COM_Commitment_301";
      } else {
        return "COM_Commitment_300";
      }
    },
    isAllowedToCheckResults() {
      if (this.user.isVerifier) {
        return true;
      }
      return !this.user.isVerifier && this.user.featureFlags.includes('viewScore');
    },
    areAllStatusesAcceptedOrRejected() {
      if (!this.certificationOverallTableData.data && !this.strategicDimensionsActionTableData.data && !this.strategicThemesActionTableData.data) {
        return false;
      }

      const checkStatuses = (data) => {
        return data.every(item =>
          item?.status === "Accepted" || item?.status === "Rejected"
        );
      };

      const certificationOverallCheck = checkStatuses(this.certificationOverallTableData.data);
      const strategicDimensionsCheck = checkStatuses(this.strategicDimensionsActionTableData.data);
      const strategicThemesCheck = checkStatuses(this.strategicThemesActionTableData.data);

      return certificationOverallCheck && strategicDimensionsCheck && strategicThemesCheck;
    }
  }
};
</script>
<style lang="scss" scoped>
.ecosystem-landing {
  background-color: #f4f1ed;
  font-family: Work Sans !important;

  .PoweredbyTitle_logo {
    width: 100%;
    padding-top: 170px;
    padding: 170px 0 0;
    margin-top: 20px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        display: none;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powered-text {
        span {
          font-size: 14px;
          line-height: 20px;
          margin-right: 35px;
          font-weight: 400;
          color: #2c2e35;
        }
      }

      img {
        width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .standard-button {
    font-weight: bold;
    font-size: 16px;
    color: #19486a;
    border-style: solid;
    border-color: #19486a;
    border-width: 1px;
  }

  .overview-box {
    border: 1px solid #ccc;
    border-radius: 15px;

    .CountryOverviewText {
      margin-bottom: 30px;

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
        margin-bottom: 5px;
      }

      p,
      .leading-7 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #1b1b1b;
        display: -webkit-box;
        -webkit-line-clamp: 15;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      a {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: #1b1b1b;
        display: flex;
        align-items: center;

        img {
          margin-left: 5px !important;
          width: 15px !important;
          height: 15px;
          object-fit: contain;
        }
      }
    }

    //padding: 20px;
    /* FROM my2xassement PAGE */
    .OverviewTitle {
      display: flex;
      align-items: center;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 17px;
        position: relative;
      }
    }

    hr {
      margin: 20px 0;
      width: 103.5%;
      text-align: left;
      margin-left: -1.75%;
      border-color: #ccc;
    }

    .spiderchartEligibleWrepper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .spiderchartContent {
        width: 100%;
      }
    }
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 20;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .eligible-level-section {
        margin: 28px 0;
        display: flex;
        padding: 16px;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        border-radius: 12px;
        background: #f4f6f9;

        .eligible-level-text {
          color: var(--grey-grey-100, #2c2e35);
          text-align: center;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        img {
          width: 80px;
        }
      }

      .certification-details {
        .inner-certification-details {
          display: flex;
          align-items: center;
          gap: 12px;
          align-self: stretch;

          .image-div {
            height: 20px;

            img {
              padding: 0;
              height: 36px;
              width: 50px;
            }
          }

          img {
            display: flex;
            width: 36px;
            height: 36px;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 6px;
          }

          .write-color {
            background: var(--secondary-10, #eaf6ff);
          }

          .layer-color {
            background: var(--red-light, #ffece8);
          }

          .letter-color {
            background: var(--yellow-light, #fff8e0);
          }

          .check-color {
            background: var(--turquoise-light, #e5fafa);
          }

          .calendar-color {
            background: var(--secondary-10, #eaf6ff);
          }

          .text {
            color: var(--grey-grey-100, #2c2e35);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            height: 20px;
            line-height: 12px;
          }
        }
      }

      .amount-button-section {
        padding: 16px 0 0 0;
        gap: 16px;
        align-self: stretch;
        border-top: 1px solid var(--grey-grey-20-line, #e1e3e9);

        .amount {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .amount-bold {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
        }

        .amount-details {
          color: var(--grey-grey-100, #2c2e35);
          text-align: right;
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .button-section {
          button {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            border-radius: 8px;
            border: 1px solid var(--primary-100, #191b45);
            margin-right: 5px;
          }

          .cancel-button {
            color: var(--primary-100, #191b45);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-left: 5px;
          }

          .save-button {
            color: var(--white, #fff);
            text-align: center;
            font-family: Arial;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            background: var(--primary-100, #191b45);
          }
        }

        .privacy-notice-disclosure {
          color: var(--grey-grey-50, #717686);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-align: right;
        }

        .inner-data-privacy {
          color: var(--grey-grey-50, #717686);
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-decoration-line: underline;
        }
      }
    }
  }

  .bottom-button-section {
    height: 120px;
    padding: 36px 10%;
    border-top: 1px solid #e1e3e9;
    gap: 24px;
    box-shadow: 0px -2px 20px 0px #636e801a;

    button {
      height: 48px;
      gap: 20px;
      padding: 12px 24px 12px 24px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      gap: 8px;
      color: #fff;
      border: none;
      font-size: 16px;
      font-weight: bold;
    }

    .red-button {
      background: #ff3a21;
    }

    .green-button {
      background: #3f7e44;
    }
  }

  .Modal_Readmorestyle {
    position: relative;
    display: flex;

    #style-1::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #ffff;
      border-radius: 10px;
      background-color: #ffff;
    }

    #style-1::-webkit-scrollbar {
      width: 10px;
      background-color: #ffff;
    }

    #style-1::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px #c0c0c1;
      background-color: #c0c0c1;
    }

    .ModalInnerStyle {
      background: #fff;

      .heading-section {
        gap: 28px;
      }

      h1 {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #19486a;
      }

      p {
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #717686;
      }

      .textarea-section {
        width: 552px;
        gap: 12px;

        input {
          margin: 0;
          min-width: 100%;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 16px;
        }

        input::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #717686;
        }

        select {
          width: 100%;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid #e1e3e9;
          gap: 8px;
        }
      }

      .cancel-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        border: 1px solid #19486a;
        color: #19486a;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }

      .save-button {
        width: 280px;
        height: 40px;
        padding: 12px 24px 12px 24px;
        border-radius: 8px;
        gap: 8px;
        background: #19486a;
        color: #fff;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }
    }

    .cross_Icon {
      position: absolute;
      top: 15px;
      right: 25px;

      img {
        width: 15px;
        height: 15px;
        object-fit: contain;
        margin: 0 !important;
        cursor: pointer;
      }
    }
  }
}
</style>
