<template>
  <div class="container pl-1 fixed top-0 bg-[#f4f6f9]">
    <div class="chatbot w-full relative h-full">
      <div class="header bg-[#201747] text-white flex px-4 py-4">
        <div class="header-left w-[75%] px-2">
          <h3 class="text-white font-bold text-md">Chat with Jessie</h3>
          <p class="text-sm">
            Ask me anything I'll always respond with a gender lens.
          </p>
        </div>
        <div class="header-right w-3/12 flex justify-end">
          <chatOptions @option-selected="handleOptionSelection" />
          <svg
            @click="closeChat"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="3"
            stroke="currentColor"
            class="cursor-pointer w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
      <div class="bg-white p-2" v-if="!viewChatHistory && !viewHistory">
        <!-- <chatMode class="ml-4" :isSideMode="true" /> -->
      </div>
      <div
        class="chatbody sm:h-[calc(100vh-13.5rem)] h-[calc(100vh-16rem)]"
        :class="{
          '!h-[calc(100vh-12.5rem)]': viewChatHistory,
          '!h-[calc(100vh-10rem)]': viewHistory,
        }"
      >
        <chatHistoryList
          v-if="viewHistory"
          :YOU="YOU"
          :messages="messages"
          :isLoadingChatResponse="isLoadingChatResponse"
          :expandedMessageIndex="expandedMessageIndex"
          @toggle-sources="toggleSources"
          @view-history-chat="handleViewChatHistory"
        />
        <chatHistory
          v-else-if="viewChatHistory"
          :YOU="YOU"
          :messages="messages"
          :isLoadingChatResponse="isLoadingChatResponse"
          :expandedMessageIndex="expandedMessageIndex"
          :historyDescription="chatHistoryDescription"
          @toggle-sources="toggleSources"
          @back-to-history="handleBackToHistory"
        />
        <div class="flex justify-center h-full mt-1" v-else>
          <div class="flex flex-col justify-end w-full grow shrink-0 basis-0">
            <div class="flex flex-col overflow-auto px-4">
              <messageList
                :YOU="YOU"
                :messages="messages"
                :isLoadingChatResponse="isLoadingChatResponse"
                :expandedMessageIndex="expandedMessageIndex"
                @toggle-sources="toggleSources"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 bg-white w-full">
        <div class="flex flex-col items-center">
          <form
            class="w-full flex items-center"
            @submit.prevent="addMessage(true)"
          >
            <input
              v-model="message"
              :disabled="isLoadingChatResponse"
              type="text"
              :placeholder="
                viewChatHistory
                  ? 'Continue chatting...'
                  : 'Type your question here...'
              "
              class="flex-1 p-3 rounded-md w-[85%] focus:outline-none focus:ring-0 text-sm"
            />
            <button type="submit" class="ml-2 p-2 text-[#201747] rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-6 h-6"
              >
                <path
                  d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z"
                />
              </svg>
            </button>
          </form>
          <div
            class="bg-[#201747] text-white flex justify-center py-3 w-full"
            v-if="viewChatHistory"
            @click="openNewChat"
          >
            <button class="font-semibold flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="4.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span class="pl-2">New Conversation</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import messageList from "@/components/chat/common/messageList.vue";
import chatOptions from "@/components/chat/sideScreen/chatOptions.vue";
import chatHistoryList from "@/components/chat/sideScreen/chatHistoryList.vue";
import chatHistory from "@/components/chat/sideScreen/chatHistory.vue";
import chat from "@/api/chat";
import { useChatStore } from "../../stores/chat";
import { mapState } from "pinia";
import axios from "axios";
import env from "../../../env";
import Cookies from "@/helpers/cookies";

export default {
  name: "eqChat",
  components: {
    chatOptions,
    chatHistoryList,
    chatHistory,
    messageList,
  },
  emits: ["unauthorized"],
  computed: {
    ...mapState(useChatStore, ["isChatOpen"]),
  },
  data() {
    return {
      YOU: "user",
      ASSISTANT: "assistant",
      sessionId: "",
      message: "",
      messages: [
        {
          message:
            "Hello, I am an expert in the field of Gender Equality and Social Inclusion. Ask me anything. Be as specific as possible in your questions.",
          author: "assistant",
          sources: null,
        },
      ],
      isLoadingChatResponse: false,
      METADATA_MARKER: "\n\n___MESSAGE_METADATA___:",
      expandedMessageIndex: -1,
      dropdownOpen: false,
      viewHistory: false,
      viewChatHistory: false,
      chatHistoryDescription: {},
    };
  },

  methods: {
    async newChatMessage(_message) {
      this.isLoadingChatResponse = true;
      try {
        let response = await chat.newMessage(_message);
        this.messages.push({
          message: response.data.response,
          author: this.ASSISTANT,
        });
      } catch (error) {
        console.log("error", error);
        this.messages.push({
          message: "Sorry I can not respond right now.",
          author: this.ASSISTANT,
        });
      } finally {
        this.isLoadingChatResponse = false;
      }
    },
    addMessage(user) {
      if (!this.message.trim()) return;
      if (user) {
        this.messages.push({
          message: this.message,
          author: this.YOU,
          sources: null,
        });
        this.newChatMessage(this.message);
        this.message = "";
      }
    },
    toggleSources(index) {
      if (this.expandedMessageIndex === index) {
        this.expandedMessageIndex = -1;
      } else {
        this.expandedMessageIndex = index;
      }
    },
    closeChat() {
      const chatStore = useChatStore();
      chatStore.isChatOpen = false;
    },
    handleOptionSelection(option) {
      if (option === "See History") {
        this.viewHistory = true;
        this.messages = [
          {
            message: "Here are all of the conversations we have had!",
            author: "assistant",
            sources: null,
          },
        ];
      }
      if (option === "Full screen mode") {
        this.$router.push({ name: "chatbot" });
        const chatStore = useChatStore();
        chatStore.isChatOpen = false;
      }
    },
    handleViewChatHistory(history) {
      this.viewHistory = false;
      this.viewChatHistory = true;
      this.chatHistoryDescription = history;
      this.messages = [
        {
          message:
            "Hi, I'm Jessie! You can ask me about this and that and also these other things. I can help you out with this but not that. How can I help you?",
          author: "assistant",
          sources: null,
        },
        {
          message: "What are the main gender gaps in Kenya agriculture?",
          author: this.YOU,
          sources: null,
        },
        {
          message:
            "Absolutely, I can share an overview of the most pressing gender gaps in Kenya's agricultural sector",
          author: "assistant",
          sources: null,
        },
      ];
    },
    handleBackToHistory() {
      this.viewHistory = true;
      this.viewChatHistory = false;
      this.messages = [
        {
          message: "Here are all of the conversations we have had!",
          author: "assistant",
          sources: null,
        },
      ];
    },
    openNewChat() {
      this.viewChatHistory = false;
      this.messages = [
        {
          message:
            "Hello, I am an expert in the field of Gender Equality and Social Inclusion. Ask me anything. Be as specific as possible in your questions.",
          author: "assistant",
          sources: null,
        },
      ];
    },
    async logOutUser() {
      localStorage.removeItem("previousURL");
      localStorage.removeItem("lastActive");
      let sessionID = Cookies.get("session");
      let deviceID = localStorage.getItem("deviceToken");
      return axios
        .post(
          env.apiUrl + "/users/me/logout",
          { sessionId: sessionID, deviceId: deviceID },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(() => {
          if (!localStorage.getItem("isLogout")) {
            localStorage.setItem("isLogout", true);
          }
          this.$store.commit("logout");
        });
    },
  },
  mounted() {
    const chatStore = useChatStore();
    if (chatStore.messsage) {
      if (!chatStore.messsage.trim()) return;
      if (chatStore.messsage) {
        this.messages.push({
          message: chatStore.messsage,
          author: this.YOU,
          sources: null,
        });
        this.newChatMessage(chatStore.messsage);
        chatStore.messsage = "";
      }
    }
  },
};
</script>

<style scoped>
.container {
  width: inherit;
  height: inherit;
}
</style>
