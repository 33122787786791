<template>
  <div>
    <div class="flex gap-4 mb-8">
      <h2 class="text-2xl font-bold leading-8">Actual vs Budget YTD </h2>
      <label class="flex gap-2 items-center">
        <input type="checkbox" v-model="hideEmptyRowsYTD" />
        Hide empty rows
      </label>
    </div>
    <div v-if="JSON.stringify(newYtdData) !== '{}'" class="table-container">
      <table class="inner-table">
        <thead>
        <tr class="table-heading-row">
          <th>Item</th>
          <th>Actual YTD'{{ year ?? '' }}</th>
          <th>Budget YTD'{{ year ?? ''}}</th>
          <th>Variance</th>
          <th>Variance (%)</th>
          <th v-if="displayComment">Comment</th>
        </tr>
        </thead>
        <tbody>
        <tr
          class="table-content-row"
          v-for="(item, key) in newYtdData"
          :key="key"
          v-show="displayRevenue(key.substring(0, key.length - 3), 'YTD') && !isRowEmpty(item.Actual, item.Budget) || !hideEmptyRowsYTD"
        >
          <td
            :class="{'font-bold': isBoldItem(item.name), 'italic': isItalicItem(item.name)}"
          >
            {{ item.name }}
            <template v-if="key.substring(0, key.length - 3) === revenueRows.header">
              <img
                v-if="totalRevenueYTDOpen"
                class="cursor-pointer"
                alt="arrow-up"
                src="@/assets/images/arrow-up.svg"
                @click="totalRevenueYTDOpen = false"
              />
              <img
                v-else
                class="cursor-pointer"
                alt="arrow-down"
                src="@/assets/images/arrow-down.svg"
                @click="totalRevenueYTDOpen = true"
              />
            </template>
          </td>
          <td>{{ formatToCurrency(item.Actual) }}</td>
          <td>{{ formatToCurrency(item.Budget) }}</td>
          <td>{{ formatToCurrency(item.Variance) }}</td>
          <td>{{ item["Variance (%)"] }}</td>
          <td v-if="displayComment" class="w-96">
            <div class="flex justify-between">
              <template v-if="item.comments.length > 0">
                <div class="flex flex-col gap-2">
                  <span class="text-tiny text-wrap">{{ lastComment(item.comments) }}</span>
                  <button
                    type="button"
                    class="[ h-[28px] w-auto ][ py-1 ][ text-sm text-[#2177C1] font-bold ][ flex items-center gap-2 ]"
                    @click="openCommentModal('YTD', item.name, item.comments)"
                  >
                    Read more
                    <img class="w-[13px]" src="@/assets/images/IMM/vector-navy.svg" alt="edit-icon" />
                  </button>
                </div>
              </template>
              <template v-else>
                <button
                  class="[ h-[28px] w-[163px] ][ py-1 px-3 ][ border-black border-2 rounded-lg ][ text-sm font-bold ][ flex justify-center items-center gap-2 ]"
                  @click="openCommentModal('YTD', item.name, [])"
                >
                  Make a comment
                  <img class="w-[13px]" src="@/assets/images/IMM/vector-black.svg" alt="edit-icon" />
                </button>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div class="py-2">
        <div>
          <p class="text-base font-semibold leading-normal text-gray-400 mb-1">
            Actual vs Budget YTD Data is not present!
          </p>
        </div>
      </div>
    </div>
    <div class="flex gap-4 my-8">
      <h2 class="text-2xl font-bold leading-8">Actual vs Budget Monthly</h2>
      <label class="flex gap-2 items-center">
        <input type="checkbox" v-model="hideEmptyRowsMonthly" />
        Hide empty rows
      </label>
    </div>

    <div class="table-container mb-8" v-if="JSON.stringify(newMonthlyData) !== '{}'">
      <table class="inner-table">
        <thead>
        <tr class="table-heading-row">
          <th>Item</th>
          <th>Actual {{ month?.substring(0, 3) ?? '' }}'{{ year ?? '' }}</th>
          <th>Budget {{ month?.substring(0, 3) ?? '' }}'{{ year ?? '' }}</th>
          <th>Variance</th>
          <th>Variance (%)</th>
          <th v-if="displayComment">Comment</th>
        </tr>
        </thead>
        <tbody>
        <tr
          class="table-content-row"
          v-for="(item, key) in newMonthlyData"
          :key="key"
          v-show="displayRevenue(key, 'Monthly') && !isRowEmpty(item.Actual, item.Budget) || !hideEmptyRowsMonthly"
        >
          <td :class="{'font-bold': isBoldItem(item.name), 'italic': isItalicItem(item.name)}">
            {{ item.name }}
            <template v-if="key === revenueRows.header">
              <img
                v-if="totalRevenueOpen"
                class="cursor-pointer"
                alt="arrow-up"
                src="@/assets/images/arrow-up.svg"
                @click="totalRevenueOpen = false"
              />
              <img
                v-else
                class="cursor-pointer"
                alt="arrow-down"
                src="@/assets/images/arrow-down.svg"
                @click="totalRevenueOpen = true"
              />
            </template>
          </td>
          <td>{{ formatToCurrency(item.Actual) }}</td>
          <td>{{ formatToCurrency(item.Budget) }}</td>
          <td>{{ formatToCurrency(item.Variance) }}</td>
          <td>{{ item["Variance (%)"] }}</td>
          <td v-if="displayComment" class="w-96">
            <div class="flex justify-between">
              <template v-if="item.comments.length > 0">
                <div class="flex flex-col gap-2">
                  <span class="text-tiny text-wrap">{{ lastComment(item.comments) }}</span>
                  <button
                    type="button"
                    class="[ h-[28px] w-auto ][ py-1 ][ text-sm text-[#2177C1] font-bold ][ flex items-center gap-2 ]"
                    @click="openCommentModal('MONTHLY', item.name, item.comments)"
                  >
                    Read more
                    <img class="w-[13px]" src="@/assets/images/IMM/vector-navy.svg" alt="edit-icon" />
                  </button>
                </div>
              </template>
              <template v-else>
                <button
                  class="[ h-[28px] w-[163px] ][ py-1 px-3 ][ border-black border-2 rounded-lg ][ text-sm font-bold ][ flex justify-center items-center gap-2 ]"
                  @click="openCommentModal('MONTHLY', item.name, [])"
                >
                  Make a comment
                  <img class="w-[13px]" src="@/assets/images/IMM/vector-black.svg" alt="edit-icon" />
                </button>
              </template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <div class="py-2">
        <div>
          <p class="text-base font-semibold leading-normal text-gray-400 mb-1">
            Actual vs Budget Monthly Data is not present!
          </p>
        </div>
      </div>
    </div>
    <Modal v-if="showCommentModal" @on-close="showCommentModal = false">
      <AddCommentForm
        :initialComments="currentComments"
        @save-comment="saveComment"
        @cancel-comment="cancelComment"
      />
    </Modal>
  </div>
</template>

<script>
import AddCommentForm from "@/components/imm-components/AddCommentForm.vue";
import Modal from "@/components/basics/Modal.vue";
import { formatToCurrency } from "@/helpers/utils";

const ROW_HEADERS = {
  "Total revenue YTD": { bold: true, italic: true },
  "Net revenue YTD": { bold: true, italic: true },
  "Gross Profit YTD": { bold: true, italic: true },
  "Total Operating Expenses YTD": { bold: true, italic: true },
  "Net Other Operating Income/Expenses YTD": { bold: true, italic: true },
  "Net Income from Operating Activities (EBITDA) YTD": { bold: true, italic: true },
  "Net Income YTD": { bold: true, italic: true },
  "Total revenue": { bold: true, italic: true },
  "Net revenue": { bold: true, italic: true },
  "Gross Profit": { bold: true, italic: true },
  "Total Operating Expenses": { bold: true, italic: true },
  "Net Other Operating Income/Expenses": { bold: true, italic: true },
  "Net Income from Operating Activities (EBITDA)": { bold: true, italic: true },
  "Net Income": { bold: true, italic: true }
};

const REVENUE_ROWS = {
  header: "totalRevenue",
  children: [
    'revenue1',
    'revenue2',
    'revenue3',
    'revenue4',
    'revenue5',
    'revenue6',
    'revenue7',
    'revenue8',
    'revenue9',
    'revenue10'
  ],
}

export default {
  name: "SalesTableUpdated",
  components: { Modal, AddCommentForm },
  props: {
    year: {
      type: String,
      required: true
    },
    month: {
      type: String,
      required: true
    },
    ytdData: {
      type: Object,
      required: true
    },
    monthlyData: {
      type: Object,
      required: true
    },
    isInvestor: {
      type: Boolean,
      default: false
    },
    displayComment: {
      type: Boolean,
      default: true
    }
  },
  emits: ["save-comment"],
  data() {
    return {
      newYtdData: [],
      newMonthlyData: [],
      showCommentModal: false,
      currentTable: "",
      currentRowHeading: "",
      currentComments: [],
      hideEmptyRowsYTD: true,
      hideEmptyRowsMonthly: true,
      revenueRows: REVENUE_ROWS,
      totalRevenueYTDOpen: false,
      totalRevenueOpen: false,
    };
  },
  methods: {
    formatToCurrency,
    isRowEmpty(actual, budget) {
      return actual + budget === 0;
    },
    isBoldItem(item) {
      return ROW_HEADERS[item]?.bold;
    },
    isItalicItem(item) {
      return ROW_HEADERS[item]?.italic;
    },
    openCommentModal(table, rowHeading, comments) {
      this.showCommentModal = true;
      this.currentTable = table;
      this.currentRowHeading = rowHeading;
      this.currentComments = comments;
    },
    cancelComment() {
      this.showCommentModal = false;
      this.currentTable = "";
      this.currentRowHeading = "";
      this.currentComments = [];
    },
    saveComment(comment) {
      this.$emit("save-comment", this.currentTable, this.currentRowHeading, comment);
      this.cancelComment();
    },
    truncate(value) {
      if (!value) return "";
      return value.length > 100 ? value.substring(0, 100) + "..." : value;
    },
    lastComment(comments) {
      if (comments.length > 0) {
        return this.truncate(comments[0].comment);
      }
      return null;
    },
    displayRevenue(key, table) {
      if (this.revenueRows.children.includes(key)) {
        if (table === 'YTD') {
          return this.totalRevenueYTDOpen;
        } else {
          return this.totalRevenueOpen;
        }
      }
      return true;
    }
  },
  watch: {
    ytdData(newVal) {
      this.newYtdData = newVal;
    },
    monthlyData(newVal) {
      this.newMonthlyData = newVal;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-container {
  gap: 20px;
  border-radius: 16px;
  border: 1px solid #E1E3E9;

  .inner-table {
    width: 100%;

    th,
    td {
      border: 1px solid #E1E3E9;
    }

    .table-heading-row th {
      padding: 16px;
      gap: 32px;
      border-top: none;
      background: linear-gradient(0deg, #F4F6F9, #F4F6F9), linear-gradient(0deg, #E1E3E9, #E1E3E9);
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #2C2E35;

      &:first-child {
        border-top-left-radius: 16px;
        border-left: none;
      }

      &:last-child {
        border-top-right-radius: 16px;
        border-right: none;
      }
    }

    .table-content-row td {
      border-bottom: none;
      height: 34px;
      padding: 12px;
      font-family: Arial;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: #2C2E35;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  .red {
    color: #CB2E00;
  }

  .green {
    color: #13A094;
  }
}

.font-bold {
  font-weight: 700 !important;
}
</style>
