import BaseAPI from "@/api/index";

class SurveyAPI {
  resourceBasePath = "/surveys";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  /**
   * @param surveyId<string>
   * @param tag<string>
   */
  getSurvey(surveyId, tag) {
    return this.axiosInstance.get(`${this.resourceBasePath}/${surveyId}?tag=${tag}`);
  }
}

export default SurveyAPI;
