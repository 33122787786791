import { render, staticRenderFns } from "./my2xThresholdsCSV.vue?vue&type=template&id=339a81fa&scoped=true"
import script from "./my2xThresholdsCSV.vue?vue&type=script&lang=js"
export * from "./my2xThresholdsCSV.vue?vue&type=script&lang=js"
import style0 from "./my2xThresholdsCSV.vue?vue&type=style&index=0&id=339a81fa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339a81fa",
  null
  
)

export default component.exports