<template>
  <div class="w-[300px]" :id="`chart-${this.id}`">
    <h3 v-if="title" class="text-[16px] font-bold mt-1 mb-2">{{ title }}</h3>
  </div>
</template>

<script>
import { createBarChart } from "@/components/D3-Charts/generateCharts";

export default {
  name: "plot-bar-chart",
  props: {
    data: {
      type: Object,
      required: true,
    },
    colorSchema: {
      type: Array,
      required: false,
    },
    orientation: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      id: `${this.data.indicator}-${this.data.uniqueId}-${this.data.value}`,
    };
  },
  mounted() {
    const transformedData = this.transformData();
    const chart = createBarChart(transformedData, this.colorSchema || "", this.orientation, 400, '');
    document.getElementById(`chart-${this.id}`).appendChild(chart);
  },
  methods: {
    transformData() {
      return this.data.keys.map((period, index) => ({
        period: period,
        value: this.data.values[index],
        label: this.data.indicator,
      }));
    },
  },
};
</script>
