import BaseAPI from "@/api/index";

class SurveyAnswerAPI {
  resourceBasePath = "/survey-answers";

  constructor() {
    this.axiosInstance = BaseAPI.getInstance();
  }

  /**
   * @param surveyType<string>
   * @param teamId<string>
   * @param uuid<string>
   * @param handleMultipleDuplicate<boolean>
   */
  getUserAnswersBySurveyType(surveyType, teamId, uuid, handleMultipleDuplicate) {
    let url = `${this.resourceBasePath}/user-answer?`;
    if (surveyType) {
      url += `surveyType=${surveyType}&`;
    }
    if (teamId) {
      url += `teamId=${teamId}&`;
    }
    if (uuid) {
      url += `uuid=${uuid}&`;
    }
    if (handleMultipleDuplicate) {
      url += `handleMultipleDuplicate=${handleMultipleDuplicate}`;
    }
    return this.axiosInstance.get(url);
  }

  /**
   * @param surveyId<string>
   * @param investee<string>
   */
  getSurveyAnswer(surveyId, investee) {
    return this.axiosInstance.get(`${this.resourceBasePath}/survey/${surveyId}?investee=${investee}`);
  }
}

export default SurveyAnswerAPI;
