<template>
  <div class="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 mt-16">
    <div class="max-w-7xl mx-auto">
      <!-- header section -->
      <div class="mb-8">
        <h1 class="text-3xl font-bold text-gray-900">{{ page }}</h1>
        <p class="mt-2 text-xl text-gray-500">Dashboard overview and metrics</p>
      </div>

      <!-- loading state -->
      <div v-if="loading" class="flex justify-center items-center h-64">
        <div
          class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"
        ></div>
      </div>

      <!-- main content -->
      <div v-else class="bg-white shadow rounded-lg p-6">
        <div v-if="dashboardData.length === 0">No data available</div>
        <div
          v-for="(indicator, indicatorIndex) in uniqueIndicators"
          :key="indicatorIndex"
          class="mb-8"
        >
          <h2 class="text-2xl font-bold text-gray-800 mb-4">
            {{ indicator[0] }}
          </h2>
          <table class="divide-y divide-gray-200">
            <thead>
              <tr class="w-[100px]">
                <th
                  class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Survey Question
                </th>
                <th
                  v-for="(period, periodIndex) in groupedData"
                  :key="periodIndex"
                  class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ period.date }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(question, questionIndex) in getQuestionsForIndicator(
                  indicator
                )"
                :key="questionIndex"
              >
                <td class="px-6 py-4 text-sm font-medium text-gray-900">
                  {{ question }}
                  <div
                    v-if="getChartForQuestion(question, indicator)"
                    class="mt-4"
                  >
                    <PlotBarChart
                      v-if="
                        getChartForQuestion(question, indicator).chartType ===
                        'Bar'
                      "
                      :data="getChartForQuestion(question, indicator)"
                    />
                    <!--  <PlotLineChart
                      v-if="
                        getChartForQuestion(question, indicator).chartType ===
                        'Line'
                      "
                      :data="getChartForQuestion(question, indicator)"
                    /> -->
                  </div>
                </td>
                <td
                  v-for="(period, periodIndex) in groupedData"
                  :key="periodIndex"
                  class="px-6 py-4 text-sm text-gray-500"
                >
                  <div v-if="period.indicators[indicator]">
                    <div
                      v-for="item in filterItemsByQuestion(
                        period.indicators[indicator].items,
                        question
                      )"
                      :key="item.uniqueId"
                    >
                      <p
                        class="mt-2 text-gray-600"
                        v-if="
                          item.chartType !== 'Bar' || item.chartType !== 'Line'
                        "
                      >
                        {{ item.value || "No data available" }}
                      </p>
                    </div>
                  </div>
                  <div v-else>No data available</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/user";
import { getDashboardData } from "@/components/imm-components/IMMhelpers";
import PlotBarChart from "@/components/imm-components/charts/Plot-BarChart.vue";
//import PlotLineChart from "@/components/imm-components/charts/Plot-LineChart.vue";

const pageMapper = {
  "climate-dd": "Climate Dashboard",
  "esg-dd": "ESG Dashboard",
  "sweef-impact": "Impact Dashboard",
  exclusions: "Exclusions Dashboard",
};

export default {
  name: "DashboardSingle",
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  components: {
    PlotBarChart,
    //PlotLineChart,
  },
  data() {
    return {
      user: null,
      page: "",
      loading: true,
      dashboardData: [],
      surveyId: null,
    };
  },
  computed: {
    groupedData() {
      const groups = {};

      this.dashboardData?.forEach((period) => {
        const periodKey = period.period;
        if (!groups[periodKey]) {
          groups[periodKey] = {
            date: period.date,
            period: period.period,
            indicators: {},
          };
        }

        period.data.forEach((item) => {
          const indicator = item.indicator;

          if (!groups[periodKey].indicators[indicator]) {
            groups[periodKey].indicators[indicator] = {
              indicator: item.indicator,
              items: [],
            };
          }
          if (item.value && item.chartType !== "Heading") {
            groups[periodKey].indicators[indicator].items.push(item);
          }
        });
      });

      // eslint-disable-next-line
      return Object.entries(groups).map(([_, periodData]) => {
        return {
          date: periodData.date,
          period: periodData.period,
          indicators: periodData.indicators,
        };
      });
    },
    uniqueIndicators() {
      const indicators = new Set();
      this.groupedData.forEach((period) => {
        Object.values(period.indicators).forEach((indicatorData) => {
          if (indicatorData.items && indicatorData.items.length > 0) {
            indicators.add(indicatorData.indicator);
          }
        });
      });
      return Array.from(indicators);
    },
    chartData() {
      const chartableData = {};
      this.groupedData.forEach((period) => {
        Object.entries(period.indicators).forEach(([indicator, data]) => {
          data.items
            .filter(
              (item) => item.chartType === "Bar" || item.chartType === "Line"
            )
            .forEach((item) => {
              if (!chartableData[item.uniqueId]) {
                chartableData[item.uniqueId] = {
                  uniqueId: item.uniqueId,
                  values: [],
                  keys: [],
                  surveyQuestion: item.surveyQuestion,
                  chartType: item.chartType,
                  indicator: indicator,
                };
              }
              chartableData[item.uniqueId].values.push(item.value);
              chartableData[item.uniqueId].keys.push(period.period);
            });
        });
      });
      return Object.values(chartableData);
    },
  },
  async mounted() {
    try {
      this.user = this.userStore.user;
      if (!this.user) {
        this.user = await this.userStore.fetchUser();
      }
      this.surveyId = this.$route.params.surveyId;
      this.page = pageMapper[this.surveyId];
      this.dashboardData = await getDashboardData(
        this.page,
        this.surveyId,
        this.$route.query.frequency
      );
    } catch (error) {
      console.error("Error in mounted:", error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getQuestionsForIndicator(indicator) {
      const questionsSet = new Set();
      this.groupedData.forEach((period) => {
        if (period.indicators[indicator]) {
          period.indicators[indicator].items.forEach((item) => {
            questionsSet.add(item.surveyQuestion);
          });
        }
      });
      return Array.from(questionsSet);
    },
    filterItemsByQuestion(items, question) {
      return items.filter((item) => item.surveyQuestion === question);
    },
    getChartForQuestion(question, indicator) {
      return this.chartData.find(
        (chart) =>
          chart.surveyQuestion === question && chart.indicator === indicator[0]
      );
    },
  },
  watch: {
    chartData: {
      handler(newVal) {
        console.log("CHART DATA", newVal);
      },
    },
  },
};
</script>
