<template>
  <div class="static assementPageStyle" id="contentForm">
    <transition name="fadeIn" mode="out-in">
      <div id="notLoaded" v-if="loading" key="loading">
        <page-loader></page-loader>
      </div>
      <div
        id="loaded"
        key="load"
        class="container"
        v-if="roiData && roiData !== '' && !loading"
      >
        <div class="PoweredbyTitle_logo">
          <div class="PoweredbyTitleWrepper">
            <img
              v-if="roiData.logo && roiData.logo[0] && roiData.logo[0].url"
              class="h-16 print:h-auto print:w-64"
              :src="roiData.logo[0].url"
              style="height: 10rem"
            />
            <h1
              class="text-6xl ml-10 tracking-tighter font-medium print:text-7xl"
            >
              {{ roiData.organizationName }}'s
              {{
                user.innpact
                  ? "GGSF Gender Assessment"
                  : "Gender ROI™ Assessment"
              }}
            </h1>
          </div>
          <div class="powerd_invite-wrapper">
            <div class="powerdby_logo_wrapper">
              <div class="Poweredby_logoImg items-center">
                <span class="powerd-logo">Powered by:</span>
                <img
                  src="@/assets/images/roi/GGSF-Logo.png"
                  alt="background-image"
                  style="height: 50px"
                />
                <img
                  src="@/assets/images/logo-navy.png"
                  alt="background-image"
                />
              </div>
            </div>
          </div>
        </div>
        <transition name="fadeIn" mode="out-in">
          <div id="loaded" key="content">
            <!-- Assement Page Wrepper -->
            <div class="AssementPageWrepper">
              <div
                class="most_recent_message_section"
                id="mostRecentMessageSection"
                v-if="roiData.mostRecentSurveyData"
              >
                <span
                  class="close"
                  @click="closeMostRecentSurveyMessageSection()"
                  style="top: -35px !important; right: -18px !important"
                  >&times;</span
                >
                <span class=""
                  >This is the most recently calculated
                  {{ user.innpact ? "GGSF Gender Score" : "Gender ROI™ Score" }}
                  based on a fully completed assessment. A new assessment is
                  currently in progress, currently it is
                  <b>{{ roiData.percentage | mathOnlyRound }}%</b>.</span
                >
              </div>
              <div class="OverviewBlock flex">
                <div class="OverviewBlock_left_side mr-5">
                  <div class="equal_strength">
                    <div class="OverviewTitle">
                      <h3 class="font-bold">{{ roiData.organizationName }}</h3>
                    </div>
                    <ul>
                      <li v-if="roiData.organizationType">
                        <span
                          >Enterprise Type:
                          <b> {{ roiData.organizationType }} </b></span
                        >
                      </li>
                      <li
                        v-if="
                          roiData.organizationType && roiData.enterpriseSize
                        "
                      >
                        <span
                          >Enterprise Size:
                          <b>{{
                            roiData.enterpriseSize.split("(")[0]
                          }}</b></span
                        >
                      </li>
                      <li v-if="roiData.womworkforceTwo">
                        <span
                          >Number of Employees :
                          <b>
                            {{ roiData.womworkforceTwo | mathOnlyRound }}</b
                          ></span
                        >
                      </li>
                      <li v-if="roiData.countryHqText">
                        <span
                          >Headquarters:
                          <b>{{ roiData.countryHqText }}</b></span
                        >
                      </li>
                      <li v-if="roiSector">
                        <span
                          >Sector: <b>{{ roiSector }}</b></span
                        >
                      </li>
                    </ul>
                    <br />
                    <TwoXAligned :roiData="roiData" />
                    <br />
                    <!-- <span class="flex" style="align-items: center;" id="learnMoreAbout">
                                            <h3 class="font-semibold">
                                                Learn about the {{ user.innpact ? 'GGSF Gender Assessment' : 'Gender ROI™ Assessment' }}
                                            </h3>
                                            <img class="cursor-pointer" src="@/assets/images/plus-sign-new.svg"
                                                style="height: 20px" @click="learnMoreAboutModal = true" />
                                        </span> -->
                    <div class="flex">
                      <div class="mt-5" id="DownloadCSVBtn" v-if="csvData">
                        <div class="DownloadBtn">
                          <div class="tooltip">
                            <div @click="checkDownloadCSVPermission()">
                              <p style="cursor: pointer; margin-top: 3px">
                                <img
                                  src="../../assets/images/DownloadBtn.png"
                                  alt=""
                                />
                                {{ loading ? "Loading..." : "Download XLSX" }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-5 ml-10" id="DownloadPDFBtn">
                        <div class="DownloadBtn">
                          <div class="tooltip">
                            <div @click="checkDownloadPDFPermission()">
                              <p style="cursor: pointer; margin-top: 3px">
                                <img
                                  src="../../assets/images/DownloadBtn.png"
                                  alt=""
                                />
                                <span>{{
                                  downloadPDF
                                    ? "Downloading..."
                                    : "Download PDF"
                                }}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex mt-6">
                      <div class="InviteBtn" style="">
                        <div class="tooltip TooltipImgText">
                          <button
                            class="text-white text-sm font-medium"
                            style="opacity: 1"
                            @click="viewDetailedAssessment()"
                          >
                            View Detailed Assessment
                          </button>
                        </div>
                      </div>
                      <div class="InviteBtn ml-10" style="">
                        <div class="tooltip TooltipImgText">
                          <button
                            class="text-white text-sm font-medium pointer-events-none"
                          >
                            Recommended Actions
                          </button>
                          <span
                            class="tooltiptext"
                            style="
                              width: 200px;
                              left: -35px;
                              top: 114%;
                              height: auto;
                            "
                          >
                            Coming Soon!
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex mt-6">
                      <div class="InviteBtn" style="">
                        <div class="tooltip TooltipImgText">
                          <a
                            href="https://drive.google.com/file/d/1Wg_pKwNzdokscJqIcuplNCRAFIQdnljy/view?usp=drive_link"
                            target="_blank"
                            download
                          >
                            <button
                              class="text-white text-sm font-medium"
                              style="opacity: 1"
                            >
                              GGSF Methodology
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="OverviewBlock_right_side ml-5">
                  <div class="gender_roi_score">
                    <div class="OverviewTitle">
                      <h2
                        :style="user.innpact ? 'width: 300px' : 'width: 530px'"
                        class="tooltip_most_recent"
                      >
                        {{
                          user.innpact ? "Gender Score" : "Gender ROI™ Score"
                        }}
                        <span
                          v-if="roiData.mostRecentSurveyData"
                          class="tooltip_text_most_recent"
                          >This is the most recently calculated
                          {{
                            user.innpact
                              ? "GGSF Gender Score"
                              : "Gender ROI™ Score"
                          }}
                          based on a fully completed assessment. A new
                          assessment is currently in progress.</span
                        >
                      </h2>
                      <span></span>
                    </div>
                    <div class="mb-8">
                      <h1 class="font-bold text-5xl">{{ genderRoiScore }}</h1>
                    </div>
                    <div class="gender_roi_score_chart">
                      <k-progress
                        style="
                          width: 100%;
                          margin: 0 !important;
                          position: relative;
                          top: 7px;
                        "
                        :percent="((genderRoiScore * 100) / 6) | mathOnlyRound"
                        :show-text="false"
                        color="#2BD4C4"
                      >
                      </k-progress>
                      <img
                        src="@/assets/images/roi/chart-bar.png"
                        style="position: relative; width: 100%"
                      />
                    </div>
                    <div
                      class="finance_average_score mt-5"
                      v-if="
                        (roiData.organizationType &&
                          roiData.organizationType == 'On-lending facility') ||
                        roiData.organizationType == 'Fund' ||
                        roiData.organizationType == 'Institutional Investor'
                      "
                    >
                      <div class="OverviewTitle">
                        <h2
                          style="width: 700px"
                          class="cursor-pointer tooltip_most_recent"
                          @click="redirectToFinancePillar"
                        >
                          Financial Processes, Products, & Services
                          <span
                            v-if="roiData.mostRecentSurveyData"
                            class="tooltip_text_most_recent"
                            >This is the most recently calculated
                            {{
                              user.innpact
                                ? "GGSF Gender Score"
                                : "Gender ROI™  Score"
                            }}
                            based on a fully completed assessment. A new
                            assessment is currently in progress.</span
                          >
                        </h2>
                      </div>
                      <div class="mb-8">
                        <h1 class="font-bold text-5xl">
                          {{ averageFinanceScore }}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="mt-10 mb-10"
                id="country-sector-overview"
                :class="
                  sector && sector.intro && Object.keys(sector).length > 0
                    ? 'OverviewBlock flex '
                    : 'spiderchart_main_container'
                "
              >
                <div class="OverviewBlock_left_side mr-5">
                  <div class="equal_strength">
                    <div class="OverviewTitle">
                      <h2 style="width: 500px">Country Overview</h2>
                      <span></span>
                    </div>
                    <div class="OverviewTitle" v-if="country.intro">
                      <img
                        class="mr-5 h-16 print:h-auto print:w-64"
                        :src="
                          country.flagUrl ? country.flagUrl : country.flagurl
                        "
                        v-if="country.flagUrl || country.flagurl"
                      />
                      <h1 class="title-bold">{{ country.humanName }}</h1>
                    </div>
                    <div
                      class="CountryOverviewText"
                      v-if="country && Object.keys(country).length > 0"
                    >
                      <transition name="slide-fade" v-if="country.intro">
                        <item :text="country.intro" :country="country"></item>
                      </transition>
                      <a
                        class="readMoreBtn"
                        href="javascript:void(0)"
                        @click="readMore('Country Overview', country.intro)"
                        v-if="country.intro"
                        >Read more
                        <img
                          src="../../assets/images/plushIcon.png"
                          alt="plush icon"
                      /></a>
                    </div>
                  </div>
                </div>
                <div
                  class="OverviewBlock_right_side ml-5"
                  v-if="
                    sector && sector.intro && Object.keys(sector).length > 0
                  "
                >
                  <div class="equal_strength">
                    <div class="CountryOverviewText">
                      <div class="OverviewTitle">
                        <h2 style="width: 500px">Sector Overview</h2>
                        <span></span>
                      </div>
                      <div class="CountryOverviewText">
                        <transition name="slide-fade">
                          <item :text="sector.intro" :country="sector"></item>
                        </transition>
                        <a
                          class="readMoreBtn"
                          href="javascript:void(0)"
                          @click="readMore('Sector Overview', sector.intro)"
                          v-if="sector.intro"
                          >Read more
                          <img
                            src="../../assets/images/plushIcon.png"
                            alt="plush icon"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spiderchart_main_container mt-10 mb-10">
                <div class="OverviewTitle">
                  <h2
                    :style="user.innpact ? 'width: 400px' : 'width: 942px'"
                    class="tooltip_most_recent"
                  >
                    {{
                      user.innpact
                        ? "Assessment Results"
                        : "Gender ROI™ Pillar Assessment Results"
                    }}
                    <span
                      v-if="roiData.mostRecentSurveyData"
                      class="tooltip_text_most_recent"
                      >This is the most recently calculated
                      {{
                        user.innpact ? "GGSF Gender Score" : "Gender ROI™ Score"
                      }}
                      based on a fully completed assessment. A new assessment is
                      currently in progress.</span
                    >
                  </h2>
                  <span></span>
                </div>
                <div class="spiderchart_content">
                  <div class="right_side">
                    <div class="spiderchartContent" id="chart">
                      <div class="relative">
                        <div v-if="user.innpact">
                          <spiderchart-innpact
                            v-if="
                              roiData.scoreData != '' &&
                              roiData.scoreData.length == 1
                            "
                            :paddingL="40"
                            :paddingR="40"
                            :paddingT="40"
                            :paddingB="40"
                            :details="roiData.scoreData[0]"
                            :gesiScore="roiData.roiGesiScoreData"
                            :financialYear="
                              roiData.scoreData[0].financialReportingYear
                                ? roiData.scoreData[0].financialReportingYear
                                : 'Latest Quarter'
                            "
                            :gesiLabel="
                              'GESI Contextual Score - ' + roiData.countryHqText
                            "
                            class="overflow-visible"
                            style="
                              position: relative;
                              height: 100%;
                              width: 100%;
                            "
                            :organizationType="roiData.organizationType"
                            :roiData="roiData.scoreData[0]"
                          >
                          </spiderchart-innpact>
                          <spiderchart-innpact-multiple
                            v-else
                            :paddingL="40"
                            :paddingR="40"
                            :paddingT="40"
                            :paddingB="40"
                            :financialYear="
                              roiData.scoreData[0].financialReportingYear
                                ? roiData.scoreData[0].financialReportingYear
                                : 'Latest Quarter'
                            "
                            :financialYear1="
                              roiData.scoreData[1].financialReportingYear
                                ? roiData.scoreData[1].financialReportingYear
                                : 'First Historical Year'
                            "
                            :details="roiData.scoreData[0]"
                            :detailsOld="roiData.scoreData[1]"
                            :gesiLabel="
                              'GESI Contextual Score - ' + roiData.countryHqText
                            "
                            :gesiScore="roiData.roiGesiScoreData"
                            class="overflow-visible"
                            style="
                              position: relative;
                              height: 100%;
                              width: 100%;
                            "
                            :organizationType="roiData.organizationType"
                            :roiData="roiData.scoreData[0]"
                          >
                          </spiderchart-innpact-multiple>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Domain Assessment Results -->
              <div class="domain_assessment_results_section">
                <div class="OverviewTitle">
                  <h2 style="width: 600px" class="tooltip_most_recent">
                    Domain Assessment Results
                    <span
                      v-if="roiData.mostRecentSurveyData"
                      class="tooltip_text_most_recent"
                      >This is the most recently calculated
                      {{
                        user.innpact ? "GGSF Gender Score" : "Gender ROI™ Score"
                      }}
                      based on a fully completed assessment. A new assessment is
                      currently in progress.</span
                    >
                  </h2>
                  <span></span>
                </div>
                <div class="domain_assessment_results_container flex">
                  <div class="spiderchart_container">
                    <div class="spiderchart_content">
                      <div>
                        <!-- <div class="flex dot_with_text">
                            <div class="grey-dot mr-5"></div>
                            <span class="font-bold">Previous Results 2021</span>
                          </div> -->
                        <div class="left_side flex">
                          <div class="orange-dot mr-5"></div>
                          <!-- <template v-for="(year, index) in roiData.scoreData[0]" v-once> -->
                          <span
                            class="font-bold"
                            v-if="roiData.scoreData[0].financialReportingYear"
                            >{{
                              roiData.scoreData[0].financialReportingYear
                            }}</span
                          >
                          <span
                            class="font-bold"
                            v-if="
                              roiData.scoreData[0].financialReportingYear == ''
                            "
                            >Latest Quarter</span
                          >
                          <!-- </template> -->
                        </div>
                        <div
                          class="flex dot_with_text"
                          v-if="roiData.scoreData.length > 1"
                        >
                          <div class="blue-dot mr-5"></div>
                          <!-- <template v-for="(year, index) in roiData.scoreData[1]" v-once> -->
                          <span
                            class="font-bold"
                            v-if="roiData.scoreData[1].financialReportingYear"
                            >{{
                              roiData.scoreData[1].financialReportingYear
                            }}</span
                          >
                          <span
                            class="font-bold"
                            v-if="
                              roiData.scoreData[1].financialReportingYear == ''
                            "
                            >First Historical Year</span
                          >
                          <!-- </template> -->
                        </div>
                        <!-- <div class="flex dot_with_text">
                            <div class="blue-dot mr-5"></div>
                            <span class="font-bold">Benchmark</span>
                          </div> -->
                      </div>
                      <div class="" style="width: 100%">
                        <div class="spiderchartContent" id="chart">
                          <div class="relative">
                            <domain-spiderchart
                              v-if="roiData.domainAssesmnet.length == 1"
                              :paddingL="40"
                              :paddingR="40"
                              :paddingT="40"
                              :paddingB="40"
                              :details="roiData.domainAssesmnet[0]"
                              class="overflow-visible"
                              style="
                                position: relative;
                                height: 100%;
                                width: 100%;
                              "
                            ></domain-spiderchart>
                            <domain-spiderchart-multiple
                              v-else
                              :paddingL="40"
                              :paddingR="40"
                              :paddingT="40"
                              :paddingB="40"
                              :details="roiData.domainAssesmnet[0]"
                              :detailsOld="roiData.domainAssesmnet[1]"
                              class="overflow-visible"
                              style="
                                position: relative;
                                height: 100%;
                                width: 100%;
                              "
                            ></domain-spiderchart-multiple>
                            <template>
                              <!--Leadership-->
                              <div
                                class="Ind_Inv_Icon leadership-icon"
                                id="2uur"
                              >
                                <img
                                  src="../../assets/images/leadership.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Leadership </span>
                              </div>

                              <!--Workforce-->
                              <div
                                class="Ind_Inv_Icon workforce-icon"
                                id="2uur"
                              >
                                <img
                                  src="../../assets/images/workforce.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Workforce </span>
                              </div>

                              <!--Value Chain-->
                              <div
                                class="Ind_Inv_Icon value-chain-icon"
                                id="8uur"
                              >
                                <img
                                  src="../../assets/images/value_chain.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Value Chain </span>
                              </div>

                              <!--Society-->
                              <div class="Ind_Inv_Icon society-icon" id="10uur">
                                <img
                                  src="../../assets/images/society.svg"
                                  alt="Indirect Investment"
                                />
                                <span style="color: black"> Society </span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="gender_roi_domain">
                    <div class="mb-5">
                      <h1 class="font-bold text-2xl">
                        {{
                          user.innpact ? "Gender Domain" : "Gender ROI™ Domain"
                        }}
                      </h1>
                    </div>
                    <div class="gender_roi_domain_taabs_container">
                      <div
                        class="flex my-4 items-center"
                        v-for="dom in roiData.domainAssesmnet[0]"
                        :key="'Leadership' + dom.name"
                        v-show="dom.name == 'Leadership'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            (((Number(dom.BonusScore) +
                              Number(dom.ThresholdScore)) /
                              6) *
                              100)
                              | mathOnlyRound
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img
                          class="mx-4"
                          src="@/assets/images/leadership.svg"
                        />
                        <h2 class="font-bold text-xl">Leadership</h2>
                      </div>

                      <div
                        class="flex my-4 items-center"
                        v-for="dom in roiData.domainAssesmnet[0]"
                        :key="'Workforce' + dom.name"
                        v-show="dom.name == 'Workforce'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            (((Number(dom.BonusScore) +
                              Number(dom.ThresholdScore)) /
                              6) *
                              100)
                              | mathOnlyRound
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img class="mx-4" src="@/assets/images/workforce.svg" />
                        <h2 class="font-bold text-xl">Workforce</h2>
                      </div>

                      <div
                        class="flex my-4 items-center"
                        v-for="dom in roiData.domainAssesmnet[0]"
                        :key="'Chains' + dom.name"
                        v-show="dom.name == 'Value Chains'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            (((Number(dom.BonusScore) +
                              Number(dom.ThresholdScore)) /
                              6) *
                              100)
                              | mathOnlyRound
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img
                          class="mx-4"
                          src="@/assets/images/value_chain.svg"
                        />
                        <h2 class="font-bold text-xl">Value Chain</h2>
                      </div>
                      <div
                        class="flex my-4 items-center"
                        v-for="dom in roiData.domainAssesmnet[0]"
                        :key="'Society' + dom.name"
                        v-show="dom.name == 'Society'"
                      >
                        <progress-bar
                          :options="options"
                          :value="
                            (((Number(dom.BonusScore) +
                              Number(dom.ThresholdScore)) /
                              6) *
                              100)
                              | mathOnlyRound
                          "
                          style="
                            width: 45px;
                            height: 45px;
                            line-height: 0;
                            margin: auto 0;
                          "
                        />
                        <img class="mx-4" src="@/assets/images/society.svg" />
                        <h2 class="font-bold text-xl">Society</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Parameters -->
              <div class="parameters_block">
                <div class="parameters_section_first">
                  <div class="OverviewTitle">
                    <h2 style="width: 200px" class="tooltip_most_recent">
                      Parameters
                      <span
                        v-if="roiData.mostRecentSurveyData"
                        class="tooltip_text_most_recent"
                        >This is the most recently calculated
                        {{
                          user.innpact
                            ? "GGSF Gender Score"
                            : "Gender ROI™ Score"
                        }}
                        based on a fully completed assessment. A new assessment
                        is currently in progress.</span
                      >
                    </h2>
                    <span></span>
                  </div>
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/resilience.png" />
                          <h3 class="font-bold text-2xl">Resilience</h3>
                          <progress-bar
                            :options="options"
                            :value="
                              (roiData.resiliencePercentage * 100)
                                | mathOnlyRound
                            "
                            style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            "
                          />
                        </div>
                        <div
                          class="flex right_side_flex"
                          :style="{
                            'font-size': '13px',
                            'margin-right':
                              user.languagePreference == 'Spanish'
                                ? '-68px'
                                : '',
                          }"
                        >
                          <div class="flex dot_with_text">
                            <div class="orange-dot mr-2"></div>
                            <span
                              class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear"
                              >{{
                                roiData.scoreData[0].financialReportingYear
                              }}</span
                            >
                            <span
                              class="font-bold"
                              v-if="
                                roiData.scoreData[0].financialReportingYear ==
                                ''
                              "
                              >Latest Quarter</span
                            >
                          </div>
                          <div
                            class="flex dot_with_text"
                            v-if="roiData.scoreData.length > 1"
                          >
                            <div class="blue-dot mr-2"></div>
                            <span
                              class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear"
                              >{{
                                roiData.scoreData[1].financialReportingYear
                              }}</span
                            >
                            <span
                              class="font-bold"
                              v-if="
                                roiData.scoreData[1].financialReportingYear ==
                                ''
                              "
                              >First Historical Year</span
                            >
                          </div>
                          <div
                            class="flex teal_dot_with_text tooltip"
                            style="display: flex !important"
                          >
                            <div class="teal-dot mr-5"></div>
                            <template>
                              <span class="font-bold" style="width: 250px"
                                >GESI Contextual Score -
                                {{ roiData.countryHqText }}</span
                              >
                            </template>
                            <span class="gender_roi_context_hover_text"
                              >This score is the contextual gender analysis
                              score for this country, calculated using Equilo's
                              proprietary algorithms and utilizing existing
                              publicly available for this country related to
                              this Gender ROI parameter. The full country
                              contextual assessment with detailed data
                              visualizations and statistics is available via
                              subscription
                              <a
                                href="https://www.equilo.io/pricing-enterprise"
                                target="_blank"
                                style="color: blue"
                                >here</a
                              >.</span
                            >
                          </div>
                          <div
                            class="flex dot_with_text tooltip"
                            style="display: flex"
                          >
                            <div class="yellow-dot mr-2">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section">
                      <RoiProgressBarsSection
                        v-if="roiData.domainAssesmnet.length == 1"
                        :scoreData="roiData.scoreData[0].Resilience"
                        :roiData="roiData"
                        :gesiScore="roiData.roiGesiScoreData.Resilience"
                      />
                      <RoiProgressBarsSectionMultiple
                        v-else
                        :scoreData="roiData.scoreData[0].Resilience"
                        :gesiScore="roiData.roiGesiScoreData.Resilience"
                        :scoreDataOld="roiData.scoreData[1].Resilience"
                        :roiData="roiData"
                      />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/opportunity.svg" />
                          <h3 class="font-bold text-2xl">Opportunity</h3>
                          <progress-bar
                            :options="options"
                            :value="
                              (roiData.opportunityPercentage * 100)
                                | mathOnlyRound
                            "
                            style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            "
                          />
                        </div>
                        <div
                          class="flex right_side_flex"
                          :style="{
                            'font-size': '13px',
                            'margin-right':
                              user.languagePreference == 'Spanish'
                                ? '-95px'
                                : '',
                          }"
                        >
                          <div class="flex dot_with_text">
                            <div class="orange-dot mr-2"></div>
                            <span
                              class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear"
                              >{{
                                roiData.scoreData[0].financialReportingYear
                              }}</span
                            >
                            <span
                              class="font-bold"
                              v-if="
                                roiData.scoreData[0].financialReportingYear ==
                                ''
                              "
                              >Latest Quarter</span
                            >
                          </div>
                          <div
                            class="flex dot_with_text"
                            v-if="roiData.scoreData.length > 1"
                          >
                            <div class="blue-dot mr-2"></div>
                            <span
                              class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear"
                              >{{
                                roiData.scoreData[1].financialReportingYear
                              }}</span
                            >
                            <span
                              class="font-bold"
                              v-if="
                                roiData.scoreData[1].financialReportingYear ==
                                ''
                              "
                              >First Historical Year</span
                            >
                          </div>
                          <div
                            class="flex teal_dot_with_text tooltip"
                            style="display: flex !important"
                          >
                            <div class="teal-dot mr-5"></div>
                            <template>
                              <span class="font-bold" style="width: 250px"
                                >GESI Contextual Score -
                                {{ roiData.countryHqText }}</span
                              >
                            </template>
                            <span class="gender_roi_context_hover_text"
                              >This score is the contextual gender analysis
                              score for this country, calculated using Equilo's
                              proprietary algorithms and utilizing existing
                              publicly available for this country related to
                              this Gender ROI parameter. The full country
                              contextual assessment with detailed data
                              visualizations and statistics is available via
                              subscription
                              <a
                                href="https://www.equilo.io/pricing-enterprise"
                                target="_blank"
                                style="color: blue"
                                >here</a
                              >.</span
                            >
                          </div>
                          <div
                            class="flex dot_with_text tooltip"
                            style="display: flex"
                          >
                            <div class="yellow-dot mr-2">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_bar_section">
                      <RoiProgressBarsSection
                        v-if="roiData.domainAssesmnet.length == 1"
                        :scoreData="roiData.scoreData[0].Opportunities"
                        :roiData="roiData"
                        :gesiScore="roiData.roiGesiScoreData.Opportunities"
                      />
                      <RoiProgressBarsSectionMultiple
                        v-else
                        :scoreData="roiData.scoreData[0].Opportunities"
                        :gesiScore="roiData.roiGesiScoreData.Opportunities"
                        :scoreDataOld="roiData.scoreData[1].Opportunities"
                        :roiData="roiData"
                      />
                      <div class="left_side_progress_bar"></div>
                      <div class="right_side_progress_bar"></div>
                    </div>
                  </div>
                </div>
                <div class="parameters_section">
                  <div class="inner_parameter_block">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex">
                          <img src="@/assets/images/roi/inclusion.svg" />
                          <h3 class="font-bold text-2xl">Inclusion</h3>
                          <progress-bar
                            :options="options"
                            :value="
                              (roiData.inclusionPercentage * 100)
                                | mathOnlyRound
                            "
                            style="
                              width: 45px;
                              height: 45px;
                              line-height: 0;
                              margin: auto 0;
                            "
                          />
                        </div>
                        <div
                          class="flex right_side_flex"
                          :style="{
                            'font-size': '13px',
                            'margin-right':
                              user.languagePreference == 'Spanish'
                                ? '-47px'
                                : '',
                          }"
                        >
                          <div class="flex dot_with_text">
                            <div class="orange-dot mr-2"></div>
                            <span
                              class="font-bold"
                              v-if="roiData.scoreData[0].financialReportingYear"
                              >{{
                                roiData.scoreData[0].financialReportingYear
                              }}</span
                            >
                            <span
                              class="font-bold"
                              v-if="
                                roiData.scoreData[0].financialReportingYear ==
                                ''
                              "
                              >Latest Quarter</span
                            >
                          </div>
                          <div
                            class="flex dot_with_text"
                            v-if="roiData.scoreData.length > 1"
                          >
                            <div class="blue-dot mr-2"></div>
                            <span
                              class="font-bold"
                              v-if="roiData.scoreData[1].financialReportingYear"
                              >{{
                                roiData.scoreData[1].financialReportingYear
                              }}</span
                            >
                            <span
                              class="font-bold"
                              v-if="
                                roiData.scoreData[1].financialReportingYear ==
                                ''
                              "
                              >First Historical Year</span
                            >
                          </div>
                          <div
                            class="flex teal_dot_with_text tooltip"
                            style="display: flex !important"
                          >
                            <div class="teal-dot mr-5"></div>
                            <template>
                              <span class="font-bold" style="width: 250px"
                                >GESI Contextual Score -
                                {{ roiData.countryHqText }}</span
                              >
                            </template>
                            <span class="gender_roi_context_hover_text"
                              >This score is the contextual gender analysis
                              score for this country, calculated using Equilo's
                              proprietary algorithms and utilizing existing
                              publicly available for this country related to
                              this Gender ROI parameter. The full country
                              contextual assessment with detailed data
                              visualizations and statistics is available via
                              subscription
                              <a
                                href="https://www.equilo.io/pricing-enterprise"
                                target="_blank"
                                style="color: blue"
                                >here</a
                              >.</span
                            >
                          </div>
                          <div
                            class="flex dot_with_text tooltip"
                            style="display: flex"
                          >
                            <div class="yellow-dot mr-2">
                              <span class="bonus_score_tooltiptext">
                                When a company is performing additional
                                activities above and beyond what companies their
                                size normally do, bonus points are awarded, up
                                to a total of 1 whole point, on top of the score
                                calculated for the actions that are taken that
                                are expected of a company of its size.
                              </span>
                            </div>
                            <span class="font-bold">Bonus Score</span>
                          </div>
                        </div>
                      </div>
                      <div class="progress_bar_section">
                        <RoiProgressBarsSection
                          v-if="roiData.domainAssesmnet.length == 1"
                          :gesiScore="roiData.roiGesiScoreData.Inclusion"
                          :scoreData="roiData.scoreData[0].Inclusion"
                          :roiData="roiData"
                        />
                        <RoiProgressBarsSectionMultiple
                          v-else
                          :scoreData="roiData.scoreData[0].Inclusion"
                          :gesiScore="roiData.roiGesiScoreData.Inclusion"
                          :scoreDataOld="roiData.scoreData[1].Inclusion"
                          :roiData="roiData"
                        />
                        <div class="left_side_progress_bar"></div>
                        <div class="right_side_progress_bar"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="container" v-else key="else_div">
        <div class="flex flex-row justify-left items-center">
          <div class="flex flex-col item-center justify-left mt-10 ml-20">
            <div
              class="text-gray-600 font-medium flex flex-row items-center justify-center print:flex-col"
            >
              Uh-oh! You have not given Equilo the data required to populate
              this assessment!.
            </div>
            <div
              class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2"
            >
              <a
                href="https://airtable.com/shrwLpbGTYawS4fYj"
                class="text-white text-sm font-medium mr-3"
                style="width: max-content"
              >
                Start your assessment now
              </a>
            </div>
            <div
              class="text-gray-600 font-medium flex flex-row items-center print:flex-col mt-5"
            >
              You gave us the data but it seems missing?
            </div>
            <div
              class="survey flex flex-row items-center justify-left print:flex-col mt-3 mb-2"
            >
              <a
                href="https://airtable.com/embed/shrvvhKBs0eAcICnu?backgroundColor=red"
                class="text-white text-sm font-medium mr-3"
                style="width: max-content"
              >
                Contact support now
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Learn more About Modal -->
    <div class="fixed z-10 inset-0" v-if="learnMoreAboutModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="width: 40% !important; margin-top: 6rem"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
            style="
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
            "
          >
            <div class="sm:flex sm:items-start" style="height: auto">
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left learn_more_modal_text_container"
              >
                <div class="flex">
                  <h3
                    class="text-lg leading-6 font-bold text-gray-900"
                    id="modal-headline"
                    style="width: 360px"
                  >
                    About the
                    {{
                      user.innpact
                        ? "GGSF Gender Assessment"
                        : "Gender ROI™ Assessment"
                    }}
                  </h3>
                  <span class="close" @click="closePopup">&times;</span>
                </div>
                <div
                  class="mt-5 overflow-y-auto learn_more_modal_text"
                  id="style-1"
                >
                  <p>
                    The Sweef Capital Gender ROI™️ (Gender ROI™️) is a
                    diagnostic measurement and management tool that is designed
                    to support entrepreneurs, investment and development finance
                    practitioners to assess the gender equality and diversity
                    performance of companies, informing both practice and
                    performance in addressing inequalities, while highlighting
                    significant opportunities to improve outcomes for women and
                    girls.
                  </p>
                  <br />
                  <p>
                    The tool comprises of 24 quantitative and qualitative
                    indicators applied across the intersectionality of the
                    enterprise dimensions of Leadership, Workforce, Value
                    Chains, as well as through Society and Outcome Pillars of
                    Resilience, Opportunity, and Inclusion. The assessment
                    identifies gaps, determining appropriate actions and
                    interventions applied at the enterprise level which are
                    focused on delivering outcomes that support women’s economic
                    empowerment.
                  </p>
                  <br />
                  <p>
                    By applying different levers of change across the enterprise
                    dimensions targeting women’s economic and social roles,
                    contributions, and constraints and vulnerabilities, women’s
                    economic empowerment is demonstrated through 3 Outcome
                    Pillars - Resilience, Opportunities and Inclusion.
                  </p>
                  <br />
                  <p>
                    These Outcome Pillars are goalposts for women’s economic
                    empowerment which involve addressing the need for women’s
                    participation in gainful employment and to be in a position
                    of influence and decision making, as well as ensuring equal
                    access to opportunities for women’s participation and
                    advancement, and safeguarding women’s well-being through
                    safety nets and protection, thereby addressing gender norms
                    and biases. These are also the key tenets in building strong
                    and resilient companies to meet the challenges of today's
                    dynamic business environment. Each stakeholder has a role to
                    play; they are mutually beneficial and should be seen as a
                    collective engine of sustainable and inclusive development.
                  </p>
                  <br />
                  <p>
                    To calculate Gender ROI™ scores across these 24 indicators,
                    there are 16 discrete survey modules that ask questions
                    across a range of topics. These surveys may be taken all at
                    once, or over a discrete period of time, filled by one or
                    more people as appropriate.
                  </p>
                  <br />
                  <p>
                    This tool is digitized via the Equilo platform, with the
                    following benefits for users coming soon (data will be
                    collected now, with the automated benefits below in beta
                    testing and coming soon!):
                  </p>
                  <br />
                  <ul style="list-style-type: disc">
                    <li class="flex">
                      <span class="mr-2">➜</span>
                      <p>
                        Eases data collection and reporting, with multiple
                        survey modules that can be completed by multiple people
                        within an organization at any time.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Automatically analyzes the reported data, displaying
                        results instantly on a personalized dashboard. Users
                        need to have an account created at app.equilo.io to
                        access their results.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Easily share dashboard and summary results or
                        downloadable .CSV files with others.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        View organizational results alongside a contextual
                        market-level analysis to better understand,
                        contextualize, and address gaps.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Data can be automatically aggregated for investors and
                        financial actors at portfolio levels when enterprises
                        complete this assessment across the portfolio.
                      </p>
                    </li>
                    <li class="flex mt-3">
                      <span class="mr-2">➜</span>
                      <p>
                        Receive intelligently automated action plan based on
                        identified gaps.
                      </p>
                    </li>
                  </ul>
                  <br />
                  <p>
                    Module 1 must be completed first, but all other modules may
                    be completed in any order. Each survey module may be
                    completed by the same or a different person. The Assessment
                    is associated with the main user's email ID. This person may
                    choose, for each module, to complete the survey, or may
                    enter an email address to send a request to another person
                    to complete the specific module.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Learn more About Modal -->

    <!-- Complete Survey Modal -->
    <div class="fixed z-10 inset-0" v-if="completeSurveyModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 transition-opacity cursor-pointer"
          @click="closePopup()"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="width: 35% !important; margin-top: 6rem"
        >
          <div
            class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4"
            style="
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
              border-radius: 8px;
            "
          >
            <div class="sm:flex sm:items-start" style="height: auto">
              <div
                class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left learn_more_modal_text_container"
                style="width: 100%"
              >
                <div class="flex">
                  <h1
                    class="text-lg leading-6 font-bold text-gray-900"
                    id="modal-headline"
                    style="width: 100%"
                  >
                    Who will complete this Survey?
                  </h1>
                  <span class="close" @click="closePopup">&times;</span>
                </div>
                <div
                  class="mt-5 overflow-y-auto learn_more_modal_text"
                  style="height: auto; color: black; font-weight: 500"
                >
                  <input
                    class="mr-4"
                    type="radio"
                    name="shared"
                    id="myself"
                    @change="isMyselfChecked()"
                  />Complete this module myself
                  <br />
                  <input
                    class="mr-4"
                    type="radio"
                    name="shared"
                    id="other"
                    @change="isMyselfChecked()"
                  />Request a team member to complete this module
                </div>
                <div class="InviteBtn mt-5 mb-4" v-if="!isShareVisible">
                  <div>
                    <button
                      class="text-white text-sm font-medium"
                      @click="fillMyself"
                      :class="
                        isMyselfRadioChecked
                          ? ''
                          : 'pointer_event_none opacity_light'
                      "
                    >
                      Launch
                    </button>
                  </div>
                </div>
                <div>
                  <div
                    class="EmailInvitemember mt-5 mb-4 flex"
                    v-if="isShareVisible"
                  >
                    <div class="EmailInput">
                      <input
                        type="email"
                        placeholder="Enter Email Address"
                        v-model="invitedUserEmail"
                      />
                    </div>
                    <div class="InviteBtn tooltip">
                      <div @click="onInviteClick">
                        <button class="text-white text-sm font-medium">
                          Share
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isReadMore">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {{ title }}
              </h3>
              <transition name="slide-fade">
                <item
                  :text="readMoreInfo"
                  :country="title == 'Country Overview' ? country : sector"
                  style="font-size: 15px"
                ></item>
              </transition>
            </div>
            <div class="cross_Icon" @click="closeReadmoreModal()">
              <img src="../../assets/images/cros-icon.svg" alt="cross_Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Modal -->

    <!-- View Detailed Assessment Modal -->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="isViewDetailedAssessmentModalOpen"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <h1
                class="text-2xl leading-6 font-bold text-gray-900"
                id="modal-headline"
                style="text-align: center"
              >
                Parameters
              </h1>
              <transition name="slide-fade">
                <div>
                  <div class="inner_parameter_block mt-4 mb-2">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex items-center">
                          <img src="@/assets/images/roi/resilience.png" />
                          <h2 class="font-bold text-xl ml-5">Resilience</h2>
                        </div>
                      </div>
                      <div class="flex my-1">
                        <div style="width: 50%">
                          <div
                            class=""
                            v-for="(score, index) in roiData.scoreData[0]
                              .Resilience"
                            :key="'score1' + index"
                            v-show="index < 4"
                          >
                            <div
                              class="block__label cursor-pointer font-semibold py-1 pr-1"
                              @click="redirectToPillar(score)"
                            >
                              {{ score.parameter }}
                            </div>
                          </div>
                        </div>
                        <div style="width: 50%">
                          <div
                            class=""
                            v-for="(score, index) in roiData.scoreData[0]
                              .Resilience"
                            :key="'score2' + index"
                            v-show="index > 3"
                          >
                            <div
                              class="block__label cursor-pointer font-semibold py-1 pl-1"
                              @click="redirectToPillar(score)"
                            >
                              {{ score.parameter }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner_parameter_block mt-4 mb-2">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex items-center">
                          <img src="@/assets/images/roi/opportunity.png" />
                          <h2 class="font-bold text-xl ml-5">Opportunity</h2>
                        </div>
                      </div>
                      <div class="flex my-1">
                        <div style="width: 50%">
                          <div
                            class=""
                            v-for="(score, index) in roiData.scoreData[0]
                              .Opportunities"
                            :key="'score3' + index"
                            v-show="index < 4"
                          >
                            <div
                              class="block__label cursor-pointer font-semibold py-1 pr-1"
                              @click="redirectToPillar(score)"
                            >
                              {{ score.parameter }}
                            </div>
                          </div>
                        </div>
                        <div style="width: 50%">
                          <div
                            class=""
                            v-for="(score, index) in roiData.scoreData[0]
                              .Opportunities"
                            :key="'score4' + index"
                            v-show="index > 3"
                          >
                            <div
                              class="block__label cursor-pointer font-semibold py-1 pl-1"
                              @click="redirectToPillar(score)"
                            >
                              {{ score.parameter }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inner_parameter_block mt-4 mb-2">
                    <div class="score_results_section">
                      <div class="parameter_percent flex">
                        <div class="flex items-center">
                          <img src="@/assets/images/roi/inclusion.png" />
                          <h2 class="font-bold text-xl ml-5">Inclusion</h2>
                        </div>
                      </div>
                      <div class="flex my-1">
                        <div style="width: 50%">
                          <div
                            class=""
                            v-for="(score, index) in roiData.scoreData[0]
                              .Inclusion"
                            :key="'score5' + index"
                            v-show="index < 4"
                          >
                            <div
                              class="block__label cursor-pointer font-semibold py-1 pr-1"
                              @click="redirectToPillar(score)"
                            >
                              {{ score.parameter }}
                            </div>
                          </div>
                        </div>
                        <div style="width: 50%">
                          <div
                            class=""
                            v-for="(score, index) in roiData.scoreData[0]
                              .Inclusion"
                            :key="'score6' + index"
                            v-show="index > 3"
                          >
                            <div
                              class="block__label cursor-pointer font-semibold py-1 pl-1"
                              @click="redirectToPillar(score)"
                            >
                              {{ score.parameter }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="cross_Icon" @click="closeViewDetailedAssessmentModal()">
              <img src="../../assets/images/cros-icon.svg" alt="cross_Icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End View Detailed Assessment Modal -->
    <div class="fixed z-50 inset-0 overflow-y-auto" v-if="mostRecentModal">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close cursor-pointer"
            @click="mostRecentModal = false"
            style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle">
            <div class="ModalInnerStyle">
              <p style="font-weight: 600; text-align: center">
                You are viewing results from the most recently completed
                assessment. A repeat assessment is currently in progress, and
                the dashboard will be updated with the results once the current
                assessment is 100% complete.
              </p>
              <button
                style="
                  background-color: #fe5000;
                  color: white;
                  margin-bottom: 0;
                "
                class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                @click="mostRecentModal = false"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Start Request Admin Modal For PDF/CSV-->
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      v-if="requestAdminModalForPDFCSV"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity cursor-pointer">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span
        >&#8203;
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style="max-width: 630px; width: 100%"
        >
          <span
            class="close"
            @click="closeAdminRequestModal()"
            style="right: 8px"
            >&times;</span
          >
          <div class="Modal_Readmorestyle" style="margin: 45px; padding: 0">
            <div class="ModalInnerStyle" style="padding: 0">
              <p style="font-weight: 600" class="">
                Your organization do not have plan that allow this feature to
                access. Email your account administrator to upgrade and unlock.
              </p>
              <button
                style="background-color: #fe5000; color: white"
                class="text-white px-4 py-4 text-medium gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center m-auto mt-5"
                @click="SendMailToAdmin()"
              >
                Email request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <!-- PDF download page -->
    <div
      class="pdfexportbtn"
      v-if="!loading && roiData && roiData !== ''"
      :key="updateKey"
    >
      <roiAssessment-print
        id="roi_assessment_print"
        :userFromParent="user"
        :roiDataFromParent="roiData"
        :countryDataFromParent="country"
        :sectorDataFromParent="sector"
      />
    </div>
  </div>
</template>

<script>
import env from "@/../env";
import axios from "axios";
import Cookies from "@/helpers/cookies";
import RoiProgressBarsSection from "@/components/roiProgressBarsSection.vue";
import RoiProgressBarsSectionMultiple from "@/components/roiProgressBarsSectionMultiple.vue";
import pageLoader from "@/components/page-loader.vue";
import domainSpiderchart from "@/components/charts/roiDomainSpiderchart.vue";
import domainSpiderchartMultiple from "@/components/charts/roiDomainSpiderchartMultiple.vue";

import item from "@/components/text.vue";
import html2pdf from "html2pdf.js";
import roiAssessmentPrint from "@/pages/reports/roiInnpactAssessment-print.vue";

import spiderchartInnpact from "@/components/charts/roiInnpactSpiderchart.vue";
import spiderchartInnpactMultiple from "@/components/charts/roiInnpactSpiderchartMultiple.vue";

import { useUserStore } from "@/stores/user";
import { onExport } from "@/helpers/ROI-GGSF-XLSX";
import TwoXAligned from  "@/components/2XAlignedSectionGgsfRoi.vue";

export default {
  name: "roiInnpactAssessment",
  data: function () {
    return {
      percentValue: "80%",
      user: [],
      roiData: [],
      loading: true,
      invitedUserEmail: "",
      learnMoreAboutModal: false,
      selectedSurvey: [],
      completeSurveyModal: false,
      isShareVisible: false,
      isMyselfRadioChecked: false,
      isOtherRadioChecked: false,
      options: {
        text: {
          color: "#0077C8",
          shadowEnable: false,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#0077C8",
          backgroundColor: "#D0D0D0",
          inverted: false,
        },
        layout: {
          height: 40,
          width: 50,
          verticalTextAlign: 20,
          horizontalTextAlign: 10,
          zeroOffset: 0,
          strokeWidth: 15,
          progressPadding: 0,
          type: "circle",
        },
      },
      json_data: [],
      csvData: [],
      comapnyDetails: [],
      country: {
        income: "",
      },
      sector: { name: "" },
      isReadMore: false,
      readMoreInfo: null,
      title: null,
      downloadPDF: false,
      isViewDetailedAssessmentModalOpen: false,
      fill1: { gradient: ["#0077C8", "#2BD4C4"] },
      fill2: { gradient: ["#821A7D", "#FAE053"] },
      gradient: {
        radial: false,
        colors: [
          {
            color: "#2BD4C4",
            offset: 10,
            opacity: "1",
          },
          {
            color: "#0077C8",
            offset: 90,
            opacity: "0.6",
          },
        ],
      },
      mostRecentModal: false,
      requestAdminModalForPDFCSV: false,
      emailBody: {},
      updateKey: 0,
      tag: "",
    };
  },
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  components: {
    pageLoader,
    item,
    RoiProgressBarsSection,
    domainSpiderchart,
    roiAssessmentPrint,
    domainSpiderchartMultiple,
    RoiProgressBarsSectionMultiple,
    spiderchartInnpactMultiple,
    spiderchartInnpact,
    TwoXAligned
  },
  methods: {
    closeMostRecentSurveyMessageSection() {
      document.getElementById("mostRecentMessageSection").style.display =
        "none";
    },
    redirectToPillar(score) {
      this.$router.push({
        name: "roiPillarInnpact",
        params: {},
        query: {
          survey: score.parameter,
          recordId: this.roiData.mostRecentSurveyData
            ? this.roiData.mostRecentSurveyRecordId
            : this.roiData.recordId,
        },
      });
    },
    viewDetailedAssessment() {
      this.isViewDetailedAssessmentModalOpen = true;
    },
    closeViewDetailedAssessmentModal() {
      this.isViewDetailedAssessmentModalOpen = false;
    },
    redirectToFinancePillar() {
      this.$router.push({
        name: "roiPillarInnpact",
        params: {},
        query: {
          survey: "Financial Processes, Products, & Services",
          recordId: this.roiData.mostRecentSurveyData
            ? this.roiData.mostRecentSurveyRecordId
            : this.roiData.recordId,
        },
      });
      // window.open(routeData.href, "_blank");
    },

    SendMailToAdmin() {
      axios
        .post(
          env.apiUrl + "/assesment2x/gesi-contextual-request-to-admin",
          this.emailBody,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async (res) => {
          this.closeAdminRequestModal();
          if (res.data.data.status) {
            this.$swal.fire({
              icon: "success",
              text: res.data.data.message,
              showConfirmButton: true,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong!",
              showConfirmButton: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeAdminRequestModal() {
      this.requestAdminModalForPDFCSV = false;
    },
    checkUserIsAdminOrNot(paramName) {
      if (this.user.admin) {
        if (
          this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus == "Subscribed"
        )
          this.redirectforcustomerportal();
        else if (
          !this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus != "Subscribed"
        )
          this.redirecttoupgrade();
        else if (
          !this.user.organization.stripeCustomerId &&
          this.user.organization.subscriptionStatus == "Subscribed"
        )
          this.redirectToEquilo();
      } else {
        this.emailBody = {
          productName: paramName,
          product: paramName,
        };
        this.requestAdminModalForPDFCSV = true;
      }
    },
    redirecttoupgrade() {
      this.$router.push({ path: "/upgrade" });
    },
    redirectforcustomerportal() {
      axios
        .post(
          env.apiUrl + "/payments/create-customer-portal-session",
          { stripeCustomerId: this.user.organization.stripeCustomerId },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          window.location.href = res.data.data.url;
        });
    },
    redirectToEquilo() {
      window.open("https://www.equilo.io/general-contact", "_blank");
    },

    checkDownloadPDFPermission() {
      this.exportToPDF();
    },

    async exportToPDF() {
      this.downloadPDF = true;
      var pagebreak = { before: ".before_page" };
      setTimeout(async () => {
        let element = document.querySelector("#roi_assessment_print");
        await html2pdf()
          .set({
            margin: 1,
            filename:
              this.roiData?.organizationName + " GGSF Gender Assessment.pdf",
            pagebreak: pagebreak,
            html2canvas: {
              logging: false,
              dpi: 192,
              letterRendering: true,
              useCORS: true,
              allowTaint: true,
            },
            jsPDF: {
              orientation: "portrait",
              unit: "cm",
              format: [60, 35],
            },
          })
          .from(element)
          .save();
        this.downloadPDF = false;
        return;
      }, 1000);
    },
    checkDownloadCSVPermission() {
      onExport(this.csvData, this.roiData, "GGSF");
    },
    fetchCsvData() {
      const _self = this;
      return new Promise(function (resolve) {
        let url = "";
        if (_self.$route.query?.recordId) {
          url =
            "/roi/export-data?type=GGSF&teamId=" +
            _self.roiData?.team_id +
            "&tag=" +
            _self.tag;
        } else {
          url = "/roi/export-data?type=GGSF&tag=" + _self.tag;
        }
        axios
          .get(env.apiUrl + url, {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data);
          });
      });
    },
    async getCSVData() {
      const url =
        this.$route.query.recordId || this.$route.query.roiPortfolio
          ? `/roi/download-csv?recordId=${this.$route.query.recordId}&ggsf=true&type=GGSF&tag=${this.tag}`
          : `/roi/download-csv?ggsf=true&type=GGSF&tag=${this.tag}`;

      try {
        const response = await axios.get(`${env.apiUrl}${url}`, {
          headers: {
            Authorization: `Bearer ${Cookies.get("session")}`,
          },
        });
        this.csvData = response.data.data;
      } catch (error) {
        console.error("Error fetching CSV data:", error);
      }
    },
    fillMyself() {
      this.redirectToSurvey(this.selectedSurvey);
    },
    isMyselfChecked() {
      if (document.getElementById("myself").checked == true) {
        this.isShareVisible = false;
        this.isMyselfRadioChecked = true;
      }
      if (document.getElementById("other").checked == true) {
        this.isOtherRadioChecked = true;
        this.isShareVisible = true;
      }
    },
    moduleClicked(event) {
      let modVal = event.target.alt.split("/")[1];
      if (event.target.alt.split("/")[0] == "edit-image-icon") {
        this.selectedSurvey = [];
        this.modules.forEach((data) => {
          if (data.module == modVal) {
            this.selectedSurvey = data;
          }
        });
        if (this.roiData.shareAccess) {
          Object.entries(this.roiData).forEach((item) => {
            if (item[0] == this.selectedSurvey.var) {
              // if (item[1] == true || item[1] == "Yes") {
              //   this.redirectToSurvey(this.selectedSurvey);
              // }
              if (item[1] == false) {
                this.completeSurveyModal = true;
              } else {
                this.redirectToSurvey(this.selectedSurvey);
              }
            }
          });
        } else {
          this.redirectToSurvey(this.selectedSurvey);
        }
      }
    },
    redirectToSurvey(data) {
      this.closePopup();
      let routeData = this.$router.resolve({
        name: "roiSurvey",
        params: { surveyId: data.module },
      });
      window.open(routeData.href, "_blank");
    },
    closePopup: function () {
      this.learnMoreAboutModal = false;
      this.completeSurveyModal = false;
      this.isShareVisible = false;
    },
    pageReload() {
      window.location.reload();
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    async onInviteClick() {
      let validate = this.validEmail(this.invitedUserEmail);
      if (validate) {
        let body = {
          email: this.invitedUserEmail,
          surveyName: this.selectedSurvey.name,
          surveyUrl:
            "ggsf/survey-innpact/" +
            this.selectedSurvey.module +
            "/" +
            (!this.tag || this.selectedSurvey.module == 1 ? "null" : this.tag) +
            "/" +
            this.selectedSurvey.surveyName,
          type: "ggsf",
        };
        let getInvitedUserData = await this.sendInviteToUser(body);
        if (getInvitedUserData.status) {
          this.closePopup();
          this.$swal.fire({
            icon: "success",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: getInvitedUserData.message,
            showConfirmButton: true,
          });
        }
        this.invitedUserEmail = "";
      } else {
        alert("Please enter valid email address!");
      }
    },
    sendInviteToUser(body) {
      return new Promise(function (resolve) {
        axios
          .post(env.apiUrl + "/roi/shared-assessments", body, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    getRoiData() {
      let url = "";
      if (this.$route.query.recordId) {
        if (this.$route.query.roiPortfolio) {
          url =
            "/roi/flags?score=true&flag=true&type=ggsf&roiPortfolio=true&recordId=" +
            this.$route.query.recordId;
        } else {
          url =
            "/roi/flags?score=true&flag=true&type=ggsf&recordId=" +
            this.$route.query.recordId;
        }
      } else {
        url = "/roi/flags?score=true&type=ggsf";
      }
      return axios
        .get(env.apiUrl + url, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        })
        .then((res) => {
          this.roiData = res.data.data;
          this.roiData.roiGesiScoreData.Resilience.forEach((data) => {
            if (data.chartTitle === "Managing Partners") {
              data.gesiScore = this.roiData.roiGesiScoreData.Resilience.filter(
                (obj) => obj.chartTitle === "Senior Managers"
              )[0].gesiScore;
            }
            if (data.chartTitle === "Partners") {
              data.gesiScore = this.gesiScore?.Resilience.filter(
                (obj) => obj.chartTitle === "Middle Managers"
              )[0].gesiScore;
            }
          });
          if (res.data.message) {
            this.roiData = [];
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },

    getCountry: function (val) {
      const _self = this;
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/countries/" + val + "/sectors/country", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
            _self.updateKey++;
          });
      });
    },

    getSector: function (val) {
      const _self = this;
      return new Promise(function (resolve) {
        if (val) {
          axios
            .get(env.apiUrl + "/sectors/" + val, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session"),
              },
              params: { iso: _self.roiData.ISO[0] },
            })
            .then(function (res) {
              resolve(res.data.data);
              _self.updateKey++;
            });
        } else {
          resolve([]);
        }
      });
    },

    readMore(title, data) {
      this.isReadMore = true;
      this.title = title;
      this.readMoreInfo = data;
    },

    closeReadmoreModal() {
      this.isReadMore = false;
    },
  },
  computed: {
    sectorAry() {
      return this.$store.state.sectors.sectors;
    },
    roiSector() {
      return this.roiData.sector == "Other"
        ? this.roiData.otherSector
        : this.roiData.sector;
    },
    genderRoiScore() {
      let val = "";
      if (this.roiData.genderRoiScore) {
        val = Number(this.roiData.genderRoiScore).toFixed(2);
      }
      if (
        this.roiData.genderRoiScore == 0 &&
        this.roiData.genderRoiScore !== null
      ) {
        val = 0;
      }
      if (
        (this.roiData.genderRoiScore == null ||
          this.roiData.genderRoiScore == "") &&
        this.roiData.genderRoiScore !== 0
      ) {
        val = "";
      }
      return val;
    },
    averageFinanceScore() {
      let val = "";
      if (this.roiData.averageFinanceScore) {
        val = Number(this.roiData.averageFinanceScore).toFixed(2);
      }
      if (
        this.roiData.averageFinanceScore == 0 &&
        this.roiData.averageFinanceScore !== null
      ) {
        val = 0;
      }
      if (
        (this.roiData.averageFinanceScore == null ||
          this.roiData.averageFinanceScore == "") &&
        this.roiData.genderRoiScore !== 0
      ) {
        val = "";
      }
      return val;
    },
    companySize() {
      let state = "";
      let val = Math.round(Number(this.roiData.womworkforceTwo));
      if (val <= 10) {
        state = "Micro";
      } else if (val > 10 && val <= 250) {
        state = "SME";
      } else if (val > 250) {
        state = "Large";
      }
      return state;
    },
  },
  async created() {
    this.user = this.userStore.user;
    if (!this.user) {
      await this.userStore.fetchUser(false, true);
      this.user = this.userStore.user;
    }
    if (!this.user.innpact) {
      this.$router.push({ path: "/" });
    }
    this.adminFlag = this.$route.query.admin;
    await this.getRoiData();
    if (
      this.roiData.organizationType == "Bank" ||
      this.roiData.organizationType == "MFI" ||
      this.roiData.organizationType == "NBFI/NBFC" ||
      this.roiData.organizationType == "Leasing/Factoring" ||
      this.roiData.organizationType == "Payroll Lending" ||
      this.roiData.organizationType == "Cooperative" ||
      this.roiData.organizationType == "NGO" ||
      this.roiData.organizationType == "Other" ||
      this.roiData.organizationType == "On-lending facility"
    ) {
      this.tag = "on-lending-facility";
    }
    if (this.roiData.organizationType == "Fund") {
      this.tag = "fund";
    }

    if (
      this.roiData.percentage != 100 &&
      !this.roiData.mostRecentSurveyData &&
      !this.adminFlag
    ) {
      this.$router.push({ name: "notAllowed" });
    }

    if (this.roiData) {
      this.country = await this.getCountry(this.roiData.ISO[0]);
      let sectorValue = "Other";
      let sectorData = this.sectorAry.find(
        (sector) => sector.name == this.roiData.sector
      );
      if (sectorData) {
        sectorValue = sectorData.label;
      }
      if (sectorValue && sectorValue != "Other") {
        this.sector = await this.getSector(sectorValue);
      }
    }
    if (this.roiData) {
      await this.getCSVData();
    }
    this.$store.commit("setBreadcrumbs", [
      {
        name: "GGSF",
        url: "/ggsf",
      },
      {
        name: this.user.innpact
          ? "GGSF Gender Assessment"
          : "Gender ROI™ Assessment",
        url: "/ggsf/ggsf-assessment",
      },
    ]);

    if (this.roiData && this.roiData.mostRecentSurveyData) {
      this.mostRecentModal = true;
    }
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  top: -8px;
  position: relative;
}

.Modal_Readmorestyle {
  padding: 30px 0 10px 35px;
  position: relative;
  display: flex;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h1 {
      font-weight: bold;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
      text-align: center;
      padding-bottom: 30px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      text-align: left;
      line-height: 18px;
      color: #1b1b1b;
    }

    button {
      margin: 30px auto;
      order: none;
      padding: 15px 32px;
      font-size: 16px;
    }
  }

  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}

.tooltip_most_recent {
  position: relative;
  display: inline-block;
}

.tooltip_most_recent .tooltip_text_most_recent {
  visibility: hidden;
  width: 280px !important;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 20px;
  height: 200px !important;
  font-family: "Montserrat", arial !important;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  text-align: center;
  line-height: 1.5 !important;
  font-weight: 500;
  border: none !important;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip_most_recent:hover .tooltip_text_most_recent {
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .gender_roi_context_hover_text {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 350px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 99;
  left: 225px;
  top: -40px;
}

.tooltip:hover .gender_roi_context_hover_text {
  visibility: visible;
}

.tooltip .gender_roi_context_hover_text::after {
  right: 95%;
}

.DownloadBtn {
  p {
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 17px;
    transition: 0.3s ease;

    img {
      margin-right: 5px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.k-progress {
  margin: 0 !important;
}

.grey-dot {
  width: 10px !important;
  height: 10px;
  background-color: #9d9d9c;
  border-radius: 10px;
  top: 6px;
  position: relative;
}

.orange-dot {
  width: 10px !important;
  height: 10px;
  background-color: #821a7d;
  border-radius: 10px;
  top: 5px;
  position: relative;
}

.yellow-dot {
  width: 10px !important;
  height: 10px;
  background-color: #fae053;
  border-radius: 10px;
  top: 5px;
  position: relative;
  cursor: pointer;
}

.blue-dot {
  width: 10px !important;
  height: 10px;
  background-color: #201747;
  border-radius: 10px;
  top: 5px;
  position: relative;
  cursor: pointer;
}

.teal-dot {
  width: 10px !important;
  height: 10px;
  background-color: #2bd4c4;
  border-radius: 10px;
  top: 5px;
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .bonus_score_tooltiptext {
  font-family: "Montserrat", arial;
  padding: 20px;
  font-size: medium;
  letter-spacing: normal;
  visibility: hidden;
  width: 600px;
  background-color: #201747;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: -250px;
  top: 20px;
}

.tooltip:hover .bonus_score_tooltiptext {
  visibility: visible;
}

.tooltip .bonus_score_tooltiptext::after {
  right: 95%;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #ffff;
  border-radius: 10px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #ffff;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #c0c0c1;
  background-color: #c0c0c1;
}

.pointer_event_none {
  pointer-events: none;
}

.opacity_light {
  opacity: 0.5;
}

.learn_more_modal_text_container {
  h2 {
    width: 60%;
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: -8px;
    position: relative;
    width: 50%;
    text-align: end;
    cursor: pointer;
  }

  .learn_more_modal_text {
    height: 68vh;

    ::-webkit-scrollbar {
      width: 14px;
    }

    input {
      accent-color: black;
    }
  }
}

.assementPageStyle {
  background: #f5f6f9;
  margin-top: 65px;

  .EmailInput {
    padding: auto 0;
    // border: 1px solid gainsboro;
    border-radius: 5px;
    color: black;

    input {
      background: #fff;
      border: 0.9px solid #d0d0d0;
      padding: 10px 14px;
      border-radius: 4px;
      color: #1b1b1b;
      font-size: 13px;
      font-weight: 500;
      width: 240px;
      height: 36px;
      margin-right: 10px;
      height: 100%;

      ::placeholder {
        color: #d0d0d0;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .container {
    margin: 0 auto;

    .modal {
      display: none;
      /* Hidden by default */
      position: fixed;
      /* Stay in place */
      z-index: 1;
      /* Sit on top */
      padding-top: 100px;
      /* Location of the box */
      left: 0;
      top: 0;
      width: 100%;
      /* Full width */
      height: 100%;
      /* Full height */
      overflow: auto;
      /* Enable scroll if needed */
      background-color: rgb(0, 0, 0);
      /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4);
      /* Black w/ opacity */
    }

    /* Modal Content */
    .modal-content {
      background-color: #fefefe;
      margin: auto;
      padding: 20px;
      border: 1px solid #888;
      width: 40%;
      border-radius: 20px;

      .selected-class {
        background-color: green;
      }
    }

    /* The Close Button */
    .close {
      color: #aaaaaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      top: -8px;
      position: relative;
    }

    .close:hover,
    .close:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }

    .save-opportunity-button {
      text-align: right;
    }

    .save-opportunity-button button {
      background: #201747;
      color: #fff;
      border-color: #201747;
      border: 1px solid #1b1b1b;
      height: 36px;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;
    }
  }

  .PoweredbyTitle_logo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .PoweredbyTitleWrepper {
      max-width: 847px;
      widows: 100%;
      display: flex;
      align-items: center;

      img {
        width: 100px;
        height: 100px !important;
        object-fit: contain;
        // display: none;
      }

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 61px;
        color: #000;
      }
    }

    .Poweredby_logoImg {
      display: flex;
      justify-content: flex-end;

      .powerd-logo {
        font-size: 12px;
        color: #000;
        margin-right: 50px;
      }

      img {
        max-width: 100px;
        height: 33px;
        object-fit: contain;
        margin-right: 35px;
        max-width: 100px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .invite_members {
      input {
        background: #fff;
        border: 1px solid #d0d0d0;
        height: 36px;
        border-radius: 4px;
        padding: 5px;

        .invite-member-btn {
        }

        &::placeholder {
          font-size: 13px;
          color: #d0d0d0;
        }
      }
    }

    .EmailInvitemember {
      display: flex;
      margin-top: 33px;

      .EmailInput {
        padding: auto 0;
        border: 1px solid gainsboro;
        border-radius: 5px;

        input {
          background: #fff;
          border: 0.9px solid #d0d0d0;
          padding: 10px 14px;
          border-radius: 4px;
          color: #1b1b1b;
          font-size: 13px;
          font-weight: 500;
          width: 240px;
          height: 36px;
          margin-right: 10px;
          height: 100%;

          ::placeholder {
            color: #d0d0d0;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }

      .InviteBtn {
        button {
          border: 1px solid #1b1b1b;
          height: 36px;
          color: #1b1b1b;
          border-radius: 20px;
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          padding: 9px 11px;
          transition: 0.3s ease;

          &:hover {
            background: rgb(32, 23, 71);
            color: #fff;
            border-color: rgb(32, 23, 71);
          }
        }
      }
    }
  }

  .InviteBtn {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      transition: 0.3s ease;
      opacity: 0.7;

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);
      }
    }

    .tooltip {
      position: relative;
      display: inline-block;
    }

    .tooltip .tooltiptext {
      font-family: "Montserrat", arial;
      padding: 20px;
      font-size: medium;
      letter-spacing: normal;
      visibility: hidden;
      width: 600px;
      background-color: #201747;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      left: 20px;
      top: -160px;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }

    .tooltip .tooltiptext::after {
      right: 95%;
    }
  }

  .fav-image {
    margin: auto !important;
    margin-right: 15px !important;
    padding: 0;
    cursor: pointer;
  }

  .AssementPageWrepper {
    margin-top: 30px;
    padding-bottom: 60px;

    .most_recent_message_section {
      background-color: #ffffff;
      border-radius: 10px;
      padding: 40px;
      margin-bottom: 40px;
    }

    .OverviewBlock {
      // background: #ffffff;
      // border-radius: 10px;
      // padding: 0 40px 40px;
      // padding-top: 40px;
      // margin-bottom: 18px;
      .OverviewBlock_left_side {
        width: 50%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .equal_strength {
          li {
            margin: 0;
            padding: 5px 0;
          }
        }
      }

      .OverviewBlock_right_side {
        width: 50%;
        background: #ffffff;
        border-radius: 10px;
        padding: 40px;

        .gender_roi_score {
          .gender_roi_score_chart {
            width: 100%;
          }
        }
      }

      .take_survey_container {
        .take_survey_inner {
          h2 {
            display: flex;

            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
              object-fit: contain;
            }
          }

          width: 50%;
          padding: 5px;

          ul {
            margin-top: 20px;

            li {
              display: flex;
              font-size: 13px;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0px;
              text-align: left;
              margin-left: 0;
              padding-bottom: 10px;

              .edit_button {
                width: 16px;
                height: 16px;
              }

              span {
                width: 70%;
              }

              img {
                height: 20px;
                padding-left: 40px;
              }
            }
          }
        }
      }

      .grey_border {
        display: inline-block;
        border-bottom: 2px solid #d0d0d0;
        width: 100%;
        height: 2px;
        margin: 35px 0;
      }

      .progress_bar_container {
        h2 {
          margin: auto 20px auto 0;
          width: 40%;
        }

        .span_text {
          padding-left: 68px;
          width: 100%;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0px;
          text-align: right;
        }
      }

      .OverviewTitle {
        display: flex;
        align-items: center;

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;
          color: #1b1b1b;
          margin-right: 17px;
          position: relative;
        }

        span {
          display: inline-block;
          border-bottom: 2px solid #1b1b1b;
          width: 100%;
          height: 2px;
        }
      }

      .spiderchartEligibleWrepper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Xeligibleconten {
          width: 50%;

          .EligibleListWrepper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;

            .EligibleListImg {
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                display: inline-block;
                border: 1px solid #d0d0d0;
                position: absolute;
                height: 30px;
                top: 18px;
                left: 8px;
                z-index: -1;
              }

              img {
                border-radius: 50%;
                object-fit: contain;
                margin: 0 !important;
                margin-right: 15px !important;
              }
            }

            .bigImg {
              margin-left: -10px;

              &::after {
                top: 40px;
                left: 18px;
                height: 28px;
              }

              img {
                width: 40px !important;
                height: 40px;
              }
            }

            .lastAffter {
              &::after {
                content: none;
              }
            }

            .EligibleTitle {
              display: flex;
              align-items: center;

              img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                object-fit: contain;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              h3 {
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                line-height: 30px;
                color: #1b1b1b;
              }
            }
          }

          .bigMargin {
            margin-bottom: 25px;
          }

          .BorderLine {
            border: 1px solid #d0d0d0;
            max-width: 375px;
            margin: 30px 0;
          }

          .PartyVerifiedToolTip {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .PartyTooltipImg {
              img {
                border-radius: 50%;
              }
            }

            .TooltipImgText {
              display: flex;
              align-items: center;

              img {
                width: 15px;
                height: 15px;
                margin-left: 5px;
              }

              p {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                color: #1b1b1b;
              }

              .tooltiptext {
                max-width: 400px;
                top: 20px;
              }
            }
          }

          .DownloadBtn {
            p {
              display: flex;
              align-items: center;
              border: 1px solid #1b1b1b;
              border-radius: 20px;
              color: #1b1b1b;
              font-weight: bold;
              font-size: 13px;
              line-height: 16px;
              padding: 11px 17px;
              transition: 0.3s ease;

              img {
                margin-right: 5px;
                width: 15px;
                height: 15px;
              }

              &:hover {
                background: rgb(32, 23, 71);
                color: #fff;
                border-color: rgb(32, 23, 71);
              }
            }
          }
        }
      }
    }

    .spiderchart_main_container {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 80px 40px;

      .spiderchart_content {
        .left_side {
          width: 25%;
        }

        .dot_with_text {
          position: absolute;
          margin-top: 30px;
        }

        .teal_dot_with_text {
          position: absolute;
          margin-top: 60px;
        }

        .right_side {
          // width: 93%;

          .spiderchartContent {
            // padding: 0 20px;
            // width: 85%;

            .chartjs-render-monitor {
              // margin-left: -33px;
              // height: 530px !important;
            }

            .resilince-icon {
              max-width: 200px;
              left: 200px;
              top: 50px;
            }

            .opportunity-icon {
              max-width: 200px;
              top: 50px;
              right: 200px;
            }

            .inclusion-icon {
              max-width: 200px;
              right: 47%;
              bottom: -50px;
            }

            .spiderchartContent {
              width: 60%;
            }

            .Ind_Inv_Icon {
              // z-index: 9999;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              cursor: pointer !important;

              img {
                // width: 30px !important;
                // height: 30px;
                object-fit: contain;
                margin-right: 0 !important;
                margin-top: 30px !important;
              }

              span {
                font-weight: bold;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                margin-top: 6px;
                // margin-left: 20px !important;
              }
            }

            .Indirect_Investment {
              top: 0;
              left: 0;
            }
          }
        }
      }
    }

    .domain_assessment_results_section {
      background: #ffffff;
      border-radius: 10px;
      padding: 40px 40px 80px 40px;
      margin-bottom: 40px;

      .spiderchartContent {
        // padding: 0 20px;
        width: 100%;

        .chartjs-render-monitor {
          // margin-left: -33px;
          // height: 530px !important;
        }

        .workforce-icon {
          max-width: 200px;
          left: 50px;
          top: 39%;
        }

        .value-chain-icon {
          max-width: 200px;
          right: 44%;
          bottom: -15px;
        }

        .leadership-icon {
          max-width: 200px;
          top: -11%;
          right: 45%;
        }

        .society-icon {
          max-width: 200px;
          top: 39%;
          right: 50px;
        }

        .spiderchartContent {
          width: 60%;
        }

        .Ind_Inv_Icon {
          // z-index: 9999;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          cursor: pointer !important;

          img {
            // width: 30px !important;
            // height: 30px;
            object-fit: contain;
            margin-right: 0 !important;
            margin-top: 30px !important;
          }

          span {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            margin-top: 6px;
            // margin-left: 20px !important;
          }
        }

        .Indirect_Investment {
          top: 0;
          left: 0;
        }
      }

      .domain_assessment_results_container {
        // color: black;

        .gender_roi_domain_taabs_container {
          img {
            width: 35px;
          }
        }

        .spiderchart_container {
          width: 60%;
        }
      }
    }

    .parameters_block {
      background: #ffff;
      padding: 40px;

      .parameters_section_first {
        padding: 0 10px 60px 10px;
      }

      .parameters_section {
        padding: 40px 10px 60px 10px;
        border-top: 1px solid #d0d0d0;
      }

      .inner_parameter_block {
        .score_results_section {
          .parameter_percent {
            h3 {
              margin: auto 0;
              padding: 0 20px;
            }

            .right_side_flex {
              margin: auto 0 auto auto;

              .dot_with_text {
                padding: 0 15px;
              }
            }
          }
        }

        .progress_bar_section {
          padding: 2rem 0;

          .left_side_progress_bar {
            width: 50%;
          }

          .right_side_progress_bar {
            width: 50%;
          }

          .roi-progress-content {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            &--left,
            &--right {
              font-size: 13px;
              width: 45%;
              font-weight: 600;
            }

            .roi-progress-content--left {
              color: #1b1b1b;
            }

            .roi-progress-content--right {
              width: 60%;
              line-height: 1;

              .bar-div {
                span {
                  color: #646363;
                  width: 50px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .OverviewTitle {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        color: #1b1b1b;
        margin-right: 10px;
        position: relative;
        width: 320px;
      }

      span {
        display: inline-block;
        border-bottom: 2px solid #1b1b1b;
        width: 100%;
        height: 2px;
      }
    }

    .CountryLine {
      align-items: flex-end;

      h2 {
        width: auto;
      }

      span {
        width: 110px;
      }
    }
  }

  .download-csv {
    button {
      border: 1px solid #1b1b1b;
      height: 36px;
      color: #1b1b1b;
      border-radius: 20px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      padding: 9px 11px;
      -webkit-transition: 0.3s ease;
      transition: 0.3s ease;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        background: rgb(32, 23, 71);
        color: #fff;
        border-color: rgb(32, 23, 71);

        img {
          filter: invert(1);
        }
      }
    }
  }
}

.pointer-event-none {
  pointer-events: none;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #1b1b1b;
}

.CountryOverviewText {
  margin-bottom: 30px;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #1b1b1b;
    margin-bottom: 5px;
  }

  p,
  .leading-7 {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #1b1b1b;
    display: -webkit-box;
    -webkit-line-clamp: 15;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  a {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #1b1b1b;
    display: flex;
    align-items: center;

    img {
      margin-left: 5px !important;
      width: 15px !important;
      height: 15px;
      object-fit: contain;
    }
  }
}

.Modal_Readmorestyle {
  padding: 45px 0 45px 40px;
  position: relative;

  .ModalInnerStyle {
    max-height: 630px;
    overflow: auto;
    background: #fff;
    padding-right: 30px;

    h3 {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: #1b1b1b;
      margin-bottom: 5px;
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 18px;
      color: #1b1b1b;
    }
  }

  .cross_Icon {
    position: absolute;
    top: 15px;
    right: 25px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      margin: 0 !important;
      cursor: pointer;
    }
  }
}

.ExploreBtnDeck {
  display: flex;
  margin-top: 30px;

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #1b1b1b;
    border-radius: 20px;
    color: #1b1b1b;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 11px 10px;
    transition: 0.3s ease;

    img {
      margin-right: 8px;
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff;
      border-color: rgb(32, 23, 71);
    }
  }
}

.TooltipImgText {
  display: flex;
  align-items: center;

  img {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1b1b1b;
  }

  .tooltiptext {
    max-width: 400px;
    top: 20px;
  }
}

.pdfexportbtn {
  height: 0px;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
}

.title-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.survey {
  a {
    border: 1px solid #1b1b1b;
    height: 36px;
    color: #1b1b1b !important;
    border-radius: 20px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    padding: 9px 11px;
    transition: 0.3s ease;

    &:hover {
      background: rgb(32, 23, 71);
      color: #fff !important;
      border-color: rgb(32, 23, 71);
    }
  }
}
</style>
