<template>
  <div class="flex flex-col items-center">
    <div class="w-short flex flex-col text-black">
      <span class="text-4xl font-bold montserrat mt-4 mb-4">I'm a...</span>
      <span class="text-xl font-bold arial line"
        >Tell us which of the industries below you identify with the most. This
        way you can customize your dashboard. Don't worry, you can always change
        this later.</span
      >
      <div class="option mt-[2.75rem] flex flex-col items-center">
        <div
          class="square flex items-center justify-between pr-6 my-[.4rem] transition-colors cursor-pointer"
          :class="{ square__active: isActive(item.code) }"
          v-for="item in organizationTypeList"
          :key="item.id"
          @click="setActive(item.code)"
        >
          <label
            class="cursor-pointer arial text-xl max-w-md select-none ml-[1.5rem] my-[1.5rem]"
            >{{ item.name }}</label
          >
          <input
            class="rad cursor-pointer shrink-0 grow-0"
            type="radio"
            :value="item.code"
            v-model="selected"
          />
        </div>
      </div>
      <div class="flex justify-center">
        <div
          class="skip flex items-center justify-center arial font-bold transition-colors cursor-pointer select-none"
          @click="skip"
        >
          Skip
        </div>
        <button
          type="button"
          @click="this.continue"
          :disabled="!selected"
          class="continue flex items-center justify-center arial font-bold transition-colors cursor-pointer select-none"
        >
          Continue
        </button>
      </div>
    </div>
    <simple-modal :isActive="showModal" :setModal="setModal" v-if="showModal">
      <div class="flex flex-col p-[1.5rem] text-black h-full justify-between">
        <div class="font-bold text-[1.5rem]">
          Introducing your refreshed Equilo.io interface!
        </div>
        <div class="text-[1rem] leading-[24px]">
          Designed with clarity and ease in mind, ensuring you can effortlessly locate essentials and customize preferences.
        </div>
        <button
          class="w-[21.5625rem] h-[2.5rem] bg-[#191B45] text-[#FFFFFF] font-bold text-[1rem] rounded-[8px]"
          @click="setModal()"
        >
          Set up in two clicks!
        </button>
      </div>
    </simple-modal>
  </div>
</template>

<script>
import simpleModal from "./simple-modal.vue";
import { ORGANIZATION_TYPES } from "@/helpers/constants";

export default {
  name: "onBoarding",
  props: {
    onContinue: Function,
    onSkip: Function,
  },
  components: {
    "simple-modal": simpleModal,
  },
  data: function () {
    return {
      organizationTypeList: ORGANIZATION_TYPES,
      selected: "",
      showModal: true,
    };
  },
  methods: {
    isActive: function (code) {
      return code && code === this.selected;
    },
    setActive: function (code) {
      this.selected = code;
    },
    continue: function () {
      if (this.selected) this.onContinue(this.selected);
    },
    skip: function () {
      this.onSkip("BASIC");
    },
    setModal: function () {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style lang="scss" scoped>
.montserrat {
  font-family: "Montserrat";
}

.arial {
  font-family: "Arial";
}

.w-short {
  max-width: 60%;
}

.line {
  line-height: 1.75rem;
}

.square {
  min-width: 38.5rem;
  min-height: 4.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem;
  background: white;
  border: 1px solid rgba(158, 164, 186, 1);

  &__active {
    border: 1.9px solid rgba(33, 119, 193, 1);
  }

  &:hover {
    border: 1.9px solid rgba(33, 119, 193, 1);
  }
}

.rad {
  width: 1.25rem;
  height: 1.25rem;
}

.skip {
  border: 1px solid rgba(25, 27, 69, 1);
  width: 18.625rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;

  &:hover {
    border: 1px solid rgba(33, 119, 193, 1);
  }

  &:active {
    transform: translateY(1px);
  }
}

.continue {
  border: 1px solid rgba(25, 27, 69, 1);
  width: 18.625rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: white;
  background-color: rgba(25, 27, 69, 1);
  margin-left: 0.5rem;

  &:hover {
    border: 1px solid rgba(25, 27, 69, 1);
    color: rgba(25, 27, 69, 1);
    background-color: white;
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    border: 1px solid grey;
    background-color: grey;
    color: white;
    cursor: not-allowed;
  }
}
</style>
