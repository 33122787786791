<template>
  <div v-if="loading" class="p-6 gap-6 rounded-3xl bg-[#f4f6f9]">
    <local-loader></local-loader>
  </div>
  <!-- aggregated view -->
  <div v-else-if="aggregatedView">
    <twoXCard
      v-if="title === '2X Certification'"
      :status2xCertification="status2xCertification"
      :investeeId="investeeId"
      :user="user"
      :frequency="frequency"
      :aggregatedView="aggregatedView"
      :aggregatedUrl="aggregatedUrl"
    />
    <genderRoi
      v-else-if="title === 'Gender ROI™'"
      :user="user"
      :investeeId="investeeId"
      :frequency="frequency"
      :aggregatedView="aggregatedView"
      :aggregatedUrl="aggregatedUrl"
    />
    <financeCard
      v-else-if="title === 'Finance'"
      :user="user"
      :investeeId="investeeId"
      :frequency="frequency"
      :aggregatedView="aggregatedView"
      :aggregatedUrl="aggregatedUrl"
    />
    <div
      v-else
      class="p-6 gap-6 rounded-[2em] bg-[#f4f6f9] relative text-[#2c2e35]"
    >
      <div class="flex justify-between">
        <div class="font-bold text-xl">{{ title }}</div>
        <div>{{ createdAt?.split("T")[0] || null }}</div>
        <FrequencyLabel :frequency="frequency" />
      </div>

      <component :is="returnCardContent()"></component>
      <!--Links-->
      <div
        class="mt-8 flex gap-12 justify-end absolute bottom-0 pb-5 pr-12 w-full"
      >
        <!--  <div
          class="flex justify-left items-end gap-4 w-1/2 text-[#2177C1] font-bold text-sm"
        >
          <div>&lt; &gt; API integration</div>
          <div>
            <img src="@/assets/images/dashboard/info.svg" />
            Methodology
          </div>
        </div> -->
        <div class="flex justify-end gap-4">
          <router-link
            class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#4dc9be] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
            :to="aggregatedUrl"
            >View dashboard</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <!-- company data view-->
  <div v-else>
    <twoXCard
      v-if="title === '2X Certification'"
      :status2xCertification="status2xCertification"
      :investeeId="investeeId"
      :user="user"
      :frequency="frequency"
    />
    <genderRoi
      v-else-if="title === 'Gender ROI™'"
      :user="user"
      :investeeId="investeeId"
      :frequency="frequency"
    />
    <financeCard
      v-else-if="title === 'Finance'"
      :user="user"
      :investeeId="investeeId"
      :frequency="frequency"
    />
    <div
      v-else
      class="p-6 gap-6 rounded-[2em] bg-[#f4f6f9] relative text-[#2c2e35] h-full"
    >
      <div class="flex justify-between">
        <div class="font-bold text-xl">{{ title }}</div>
        <div>{{ createdAt?.split("T")[0] || null }}</div>
        <FrequencyLabel :frequency="frequency" />
      </div>
      <!--Completed-->
      <div v-if="status === 'completed'" class="mb-20">
        <component :is="returnCardContent()"></component>
      </div>
      <!--In Progress-->
      <div
        v-else-if="status === 'in-progress'"
        class="flex items-center justify-between mt-5 pb-5"
      >
        <div class="w-3/5">
          <div class="text-right mb-1">
            <span class="text-[#4DC9BE] font-bold"> {{ percentage }}% </span>
            Completed
          </div>
          <k-progress
            style="width: 110%"
            :percent="percentage"
            color="#4DC9BE"
            :line-height="16"
            :show-text="false"
          ></k-progress>
        </div>
        <router-link
          v-if="!investeeId"
          class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
          :to="`/imm/surveys/${this.surveyId}`"
          >Continue</router-link
        >
      </div>
      <div v-else class="w-full mt-10 h-52">
        {{ description }}
      </div>
      <!--Links-->
      <div class="absolute bottom-0 pb-5 pr-12 w-full">
        <!--  <div
          class="flex justify-left items-end gap-4 w-1/2 text-[#2177C1] font-bold text-sm"
        >
          <div>&lt; &gt; API integration</div>
          <div>
            <img src="@/assets/images/dashboard/info.svg" />
            Methodology
          </div>
        </div> -->
        <div v-if="status === 'completed'" class="flex justify-end gap-4">
          <button
            v-if="!user.featureFlags?.includes('sweef') && !investeeId"
            :class="[
              'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-white text-[#191b45] font-bold min-w-[140px] font-arial text-base leading-6 text-center',
              { 'opacity-50 cursor-not-allowed': investeeId },
            ]"
            :disabled="investeeId"
            @click="checkRepeatSurvey"
          >
            Repeat
          </button>
          <router-link
            class="flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#4dc9be] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center"
            :to="`/imm/dashboard/${
              this.surveyId
            }?frequency=${this.frequency?.toLowerCase()}`"
            >View results</router-link
          >
        </div>
        <div v-else-if="status === 'in-progress'"></div>
        <div v-else class="flex justify-end gap-4 items-end mt-40">
          <router-link
            v-if="!investeeId"
            :class="[
              'flex justify-center items-center px-4 py-2 gap-2 rounded-lg bg-[#191b45] text-white font-bold min-w-[140px] font-arial text-base leading-6 text-center',
              { 'opacity-50 cursor-not-allowed': investeeId },
            ]"
            :to="`/imm/surveys/${this.surveyId}`"
            :disabled="investeeId"
          >
            Launch
          </router-link>
          <span v-else class="text-gray-500">
            This user has no {{ title }} data yet.
          </span>
        </div>
      </div>
    </div>
    <GenericModal
      :isOpen="isModalOpen"
      @close="isModalOpen = false"
      :title="`${title} survey`"
    >
      <div class="my-2">
        Based on your preferences, your
        <span class="font-bold mb-4 underline"
          >{{ frequency?.toLowerCase() }} {{ title }}
        </span>
        survey has already been completed.
        <br />
        <span class="my-4 block">
          Would you like to repeat it for this
          {{ translateFrequency(frequency?.toLowerCase()) }}?
        </span>
        <div class="flex justify-end gap-4 mt-5">
          <button
            class="bg-[#191b45] text-white px-4 py-2 rounded-lg font-bold"
            @click="$router.push(`/imm/surveys/${surveyId}`)"
          >
            Repeat
          </button>
          <button
            class="bg-red-500 text-white px-4 py-2 rounded-lg font-bold"
            @click="isModalOpen = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </GenericModal>
  </div>
</template>

<script>
import localLoader from "@/components/local-loader.vue";
import {
  getSurveyData,
  validateRepeatSurvey,
} from "@/components/imm-components/IMMhelpers.js";
import twoXCard from "./2XCard.vue";
import genderRoi from "./GenderROICard.vue";
import financeCard from "@/components/imm-components/dashboard/FinancialCard.vue";
import FrequencyLabel from "./FrequencyLabel.vue";
import GenericModal from "@/components/imm-components/GenericModal.vue";
import ClimateModuleCardContent from "./module-cards-content/ClimateModuleCardContent.vue";
import ESGModuleCardContent from "./module-cards-content/ESGModuleCardContent.vue";
import ImpactModuleCardContent from "./module-cards-content/ImpactModuleCardContent.vue";

export default {
  name: "ModuleCard",
  components: {
    localLoader,
    twoXCard,
    genderRoi,
    financeCard,
    FrequencyLabel,
    GenericModal,
    ClimateModuleCardContent,
    ESGModuleCardContent,
    ImpactModuleCardContent,
  },
  props: {
    user: {
      type: Object,
    },
    investeeId: {
      type: String || null,
    },
    surveyId: {
      type: String || null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    status2xCertification: {
      type: String || null,
    },
    frequency: {
      type: String,
    },
    aggregatedView: {
      type: Boolean,
    },
    aggregatedUrl: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      percentage: 0,
      status: "not-started",
      score: 0,
      createdAt: "",
      surveyResponse: [],
      totalQuestions: 0,
      isModalOpen: false,
    };
  },
  async mounted() {
    this.loading = true;

    // skip roi and 2x modules
    if (this.title === "Gender ROI™" || this.title === "2X Certification") {
      this.loading = false;
      return;
    }

    const { surveyResponse, isComplete, progressPercentage, totalQuestions } =
      await getSurveyData(this.surveyId, this.investeeId);
    this.surveyResponse = surveyResponse;

    this.status = isComplete
      ? "completed"
      : surveyResponse.length
      ? "in-progress"
      : "not-started";
    this.percentage = progressPercentage;
    this.totalQuestions = totalQuestions;

    this.loading = false;
  },
  methods: {
    async checkRepeatSurvey() {
      const result = await validateRepeatSurvey(
        this.surveyId,
        this.frequency?.toLowerCase()
      );
      result
        ? this.$router.push(`/imm/surveys/${this.surveyId}`)
        : (this.isModalOpen = true);
    },
    translateFrequency(frequency) {
      switch (frequency) {
        case "monthly":
          return "month";
        case "quarterly":
          return "quarter";
        case "annually":
          return "year";
        default:
          return frequency;
      }
    },
    returnCardContent() {
      switch (this.title) {
        case "Climate":
          return "ClimateModuleCardContent";
        case "Environmental and Social Risks and Impacts":
          return "ESGModuleCardContent";
        case "Impact":
          return "ImpactModuleCardContent";
        case "ESG":
          return "ESGModuleCardContent";
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped></style>
