<template>
  <div class="flex flex-col gap-4 justify-between my-5">
    <div class="flex flex-col gap-2">
      <div>
        <span class="text-2xl font-bold">{{ impactRating || 25 }}</span
        >/50
      </div>
      <span class="text-sm font-semibold text-gray-500">Impact Rating</span>
    </div>
    <div class="flex flex-col gap-2">
      <div>
        <span class="text-2xl font-bold">{{ energyEmission || 381.96 }}</span
        >kg CO2e
      </div>
      <span class="text-sm font-semibold text-gray-500"
        >Energy Emission Calculations</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ClimateModuleCardContent",
  props: {
    impactRating: Number,
    energyEmission: Number,
  },
  mounted() {
    // TODO: Add logic to calculate impact rating and energy emission
  },
};
</script>
