import { roiSurveyName } from "@/helpers/roiMapping";
import * as XLSX from "xlsx-js-style";

export const onExport = (csvData, roiData, TYPE) => {
  console.log(csvData);
  // function to apply styles to a cell
  const applyStyles = (cell, styles) => {
    cell.s = styles;
  };

  // define styles
  const headerStyle = {
    font: { bold: true, color: { rgb: "FFFFFF" } },
    fill: { fgColor: { rgb: "4F81BD" } },
    alignment: { horizontal: "center", vertical: "center", wrapText: true },
  };

  const dataStyle = {
    alignment: { horizontal: "left", vertical: "center", wrapText: true },
  };

  // function to create a styled sheet
  const createStyledSheet = (data) => {
    // check if data is empty or invalid
    if (!data || !data.length || !data[0].length) {
      return null;
    }

    const ws = XLSX.utils.aoa_to_sheet(data);
    const range = XLSX.utils.decode_range(ws["!ref"]);

    // apply styles to data rows
    for (let R = range.s.r; R <= range.e.r; R++) {
      for (let C = range.s.c; C <= range.e.c; C++) {
        const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        if (cell) {
          applyStyles(cell, dataStyle);
          if (["Inclusion", "Opportunities", "Resilience"].includes(cell.v)) {
            applyStyles(cell, headerStyle);
          }
        }
      }
    }

    // set column widths
    ws["!cols"] = data[0].map(() => ({ wch: 75 }));

    return ws;
  };

  const wb = XLSX.utils.book_new();

  // this is for survey module data
  if (csvData.roiSurveyData) {
    const roiSurveyArray = Object.values(csvData.roiSurveyData)
      .filter(Boolean)
      .filter(
        ([key]) =>
          ![
            "Created Time",
            "Equilo Email ID",
            "Submission Date",
            "Created",
            "User Email ID",
          ].includes(key)
      );

    const otherSector = roiSurveyArray.find(([key]) => key === "Other Sector");
    const otherSectorIndex = roiSurveyArray.findIndex(
      ([key]) => key === "Other Sector"
    );

    if (otherSectorIndex !== -1) {
      roiSurveyArray.splice(otherSectorIndex, 1);
    }

    roiSurveyArray.forEach((data) => {
      if (data[0] === "Sector" && data.includes("Other")) {
        const otherIndex = data.indexOf("Other");
        data[otherIndex] =
          roiData.typeFlag === "funds"
            ? "Financial Services"
            : otherSector?.[1] ?? "N/A";
      }
      for (let i = 1; i < data.length; i++) {
        if ((!isNaN(data[i]) && data[i]) || data[i] === 0) {
          data[i] = parseFloat(data[i]).toFixed(2);
        } else if (!data[i]) {
          data[i] = "N/A";
        }
        if (data[0]?.includes("How mature")) {
          data[i] =
            !isNaN(data[i]) && data[i] ? String(parseInt(data[i])) : "0";
        }
        if (data[0]?.includes("%")) {
          const percentage = parseFloat(data[i]);
          data[i] = percentage < 1 ? percentage * 100 : percentage;
        }
      }
    });

    // grouping data by module
    const groupedData = roiSurveyArray.reduce((acc, data) => {
      const groupKey =
        typeof data[data.length - 1] === "string" &&
        !isNaN(data[data.length - 1]) &&
        Number(data[data.length - 1]) < 20 &&
        data[data.length - 1] !== ""
          ? parseInt(data[data.length - 1])
          : 1;
      if (!acc[groupKey]) acc[groupKey] = [];
      acc[groupKey].push([
        data[0],
        ...(data.slice(1, data.length - 1) ?? "N/A"),
      ]);
      return acc;
    }, {});

    let financialReportingYear = roiSurveyArray.find(
      (data) => data[0] === "Financial Reporting Year"
    );

    // add financialReportingYear to groupedData
    Object.keys(groupedData)
      .slice(1)
      .forEach((key) => {
        groupedData[key].unshift(financialReportingYear);
      });

    Object.entries(groupedData)
      .sort(([a], [b]) => a - b)
      .forEach(([key, sheetData]) => {
        const sheet = createStyledSheet(sheetData);
        const title = (
          Object.values(roiSurveyName).find(
            (item) => String(item.roiScoringSurveyMod) === key
          )?.title || `Module ${key}`
        ).slice(0, 31);
        XLSX.utils.book_append_sheet(wb, sheet, title);
      });
  }

  // this is for scoring Data
  if (csvData.scoringData) {
    const scoringArray = Object.values(csvData.scoringData)
      .filter(Boolean)
      .slice(5)
      .map((data) =>
        data.map((item, i) => {
          return [
            "Inclusion Percentage",
            "Opportunity Percentage",
            "Resilience Percentage",
          ].includes(data[0]) && i > 0
            ? Number(item).toFixed(2) + "%"
            : item > 0 && !isNaN(item)
            ? Number(item).toFixed(2)
            : !item
            ? "N/A"
            : item;
        })
      );

    const scoring = createStyledSheet(scoringArray);

    deleteRow(scoring, 0);
    deleteRow(scoring, 1);
    XLSX.utils.book_append_sheet(wb, scoring, "My Scores");
  }

  if (csvData.portfolioData?.length) {
    const portfolioArray = Object.values(csvData.portfolioData).filter(Boolean);
    const portfolio = createStyledSheet(portfolioArray);
    deleteRow(portfolio, 0);
    XLSX.utils.book_append_sheet(wb, portfolio, "Portfolio");
  }

  if (csvData.portfolioScoreData?.length) {
    const portfolioScoreArray = Object.values(
      csvData.portfolioScoreData
    ).filter(Boolean);
    const portfolioScore = createStyledSheet(portfolioScoreArray);
    deleteRow(portfolioScore, 0);
    XLSX.utils.book_append_sheet(wb, portfolioScore, "Portfolio Scores");
  }

  if (wb.SheetNames?.length) {
    wb.SheetNames = [
      "My Scores",
      ...wb.SheetNames.filter((name) => name !== "My Scores"),
    ];
    XLSX.writeFile(wb, `${roiData.organizationName} ${TYPE}.xlsx`);
  } else {
    console.error("workbook not created successfully");
  }
};

export const deleteRow = (ws, row_index) => {
  var variable = XLSX.utils.decode_range(ws["!ref"]);
  for (var R = row_index; R < variable.e.r; ++R) {
    for (var C = variable.s.c; C <= variable.e.c; ++C) {
      ws[ec(R, C)] = ws[ec(R + 1, C)];
    }
  }
  variable.e.r--;
  ws["!ref"] = XLSX.utils.encode_range(variable.s, variable.e);
};

export const ec = (r, c) => {
  return XLSX.utils.encode_cell({ r: r, c: c });
};
