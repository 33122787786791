export const checkWhiteLabel = (featureFlags = []) => {
  const immFeatureFlag = featureFlags.find((flag) => flag.startsWith("imm-"));
  if (!immFeatureFlag) {
    return companies.default;
  }
  const company = immFeatureFlag.split("-")[1];
  if (company) {
    return companies[company];
  }
  return companies.default;
};

// `displayComment`: This should be implemented to display the comment column on the Portfolio Company Dashboard
const companies = {
  default: {
    title: "Impact Compass",
    logo: null,
    companyLandingSurvey: true,
    displayComment: true,
    portfolioSurveys: [
      "company-profile",
      "e&s",
      "exclusions",
      "climate-dd",
      "energy-ghg",
      "impact", // TODO: change to impact once the survey is live
      "finance",
      "e&s-rating",
      "climate-rating",
      "gender-roi",
      "2x",
    ],
    deals: {
      dealsTitle: "Impact Deal Pipeline",
      dealsSurveyId: "deal-pipeline",
    },
  },
  sweef: {
    title: "IMM",
    logo: "sweef",
    companyLandingSurvey: false,
    displayComment: false,
    portfolioSurveys: [
      "company-profile",
      "e&s",
      "exclusions",
      "climate-dd",
      "energy-ghg",
      "sweef-impact",
      "finance",
      "e&s-rating",
      "climate-rating",
      "gender-roi",
      "2x",
    ],
    deals: {
      dealsTitle: "Sweef Deal Pipeline",
      dealsSurveyId: "sweef-deal-pipeline",
    },
  },
};

export const surveysMapping = {
  "e&s": "Environmental and Social Risks and Impacts",
  impact: "Impact",
  "company-profile": "Company Profile",
  "e&s-rating": "E&S Rating",
  "climate-rating": "Climate Rating",
  exclusions: "Environmental & Social Exclusions",
  finance: "Finance",
  "sweef-impact": "Impact",
  "climate-dd": "Climate",
  "energy-ghg": "Energy Consumption & GHG Emissions",
  "gender-roi": "Gender ROI",
  "2x": "2X",
};
