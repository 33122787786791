<template>
  <div
    v-if="frequency"
    class="text-sm bg-[#191B45] text-white px-2 py-1 rounded-lg h-8"
  >
    {{ frequency }}
  </div>
</template>

<script>
export default {
  props: {
    frequency: String,
  },
};
</script>
