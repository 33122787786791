<script>
import ClipLoader from "vue-spinner/src/ClipLoader";

import PlotBarChart from "@/components/imm-components/charts/Plot-BarChart.vue";
import PlotDonutChart from "@/components/imm-components/charts/Plot-DonutChart.vue";
import PlotPieChart from "@/components/imm-components/charts/Plot-PieChart.vue";
import ImmAPI from "@/api/ImmAPI";
import env from "@/../env.js";

export default {
  name: "AggregatedDealsCharts",
  components: { ClipLoader, PlotPieChart, PlotBarChart, PlotDonutChart },
  setup() {
    const immAPI = new ImmAPI();
    return { immAPI };
  },
  data() {
    return {
      env,

      // Main Section
      pipelineByStatus: {
        keys: ["Active", "Passed", "Rejected", "Watch List"],
        values: [12, 6, 98, 7],
        indicator: "Pipeline by Status",
        uniqueId: "pipelineByStatus"
      },
      pipelineByStages: {
        keys: ["Origination", "Screened", "Monitoring", "Pre-IC"],
        values: [80, 15, 11, 7],
        indicator: "Pipeline by Stage",
        uniqueId: "pipelineByStages"
      },
      pipelineByTransactionLead: {
        keys: ["Phuong Pham", "Samantha Tedjosugondo", "Ilman Dzikri", "Rowena Reyes"],
        values: [21, 25, 31, 47],
        indicator: "Pipeline by Transaction Lead",
        uniqueId: "pipelineByTransactionLead"
      },
      pipelineByCountry: {
        keys: ["Vietnam", "Indonesia", "Philippines", "Others"],
        values: [45, 50, 15, 15],
        indicator: "Pipeline by Country",
        uniqueId: "pipelineByCountry"
      },
      pipelineBySector: {
        keys: ["Education", "Healthcare", "Sustainable Foods", "Climate Resillience", "Others"],
        values: [13, 7, 25, 50, 32],
        indicator: "Pipeline by Sector",
        uniqueId: "pipelineBySector"
      },

      // Company Information Section
      pipelineByAnnualRevenue: {
        keys: ["US$0-1MM", "US$1-5MM", "US$5-10MM", "Above $10MM"],
        values: [32, 82, 7, 7],
        indicator: "Pipeline by Annual Revenue",
        uniqueId: "pipelineByAnnualRevenue"
      },
      pipelineByAnnualEBITDA: {
        keys: ["US$0-1MM", "US$1-5MM", "US$5-10MM", "Above $10MM"],
        values: [63, 38, 19, 7],
        indicator: "Pipeline by Annual EBITDA",
        uniqueId: "pipelineByAnnualEBITDA"
      },
      pipelineByFundraisingAmount: {
        keys: ["US$0-1MM", "US$1-5MM", "US$5-10MM", "Above $10MM"],
        values: [88, 17, 22, 13],
        indicator: "Pipeline by Fundraising Amount",
        uniqueId: "pipelineByFundraisingAmount"
      }, // TODO: not data yet

      // Impact Information Section
      pipelineByGenderImpactTarget: {
        keys: ["Pillar 1", "Pillar 2", "Pillar 3", "Pillar 4"],
        values: [44, 28, 35, 18],
        indicator: "Pipeline by Gender Impact Target",
        uniqueId: "pipelineByGenderImpactTarget"
      },
      pipelineBySDGCategory: {
        keys: ["SDG 1", "SDG 2", "SDG 3", "SDG 4", "SDG 5", "SDG 6", "SDG 7", "SDG 8", "SDG 9", "SDG 10", "SDG 11", "SDG 12", "SDG 13", "SDG 14", "SDG 15", "SDG 16", "SDG 17"],
        values: [11, 13, 12, 5, 11, 6, 5, 4, 7, 6, 5, 8, 5, 7, 1, 6, 12],
        indicator: "Pipeline by SDG's Category",
        uniqueId: "pipelineBySDGCategory"
      },
      pipelineByWomenOwnership: {
        keys: ["Women-owned", "Women-led", "Not both"],
        values: [48, 56, 21],
        indicator: "Pipeline by Women Ownership / Leadership",
        uniqueId: "pipelineByWomenOwnership"
      },

      // Source Information Section
      pipelineBySource: {
        keys: ["Inbound", "Outbound"],
        values: [75, 50],
        indicator: "Pipeline by Source",
        uniqueId: "pipelineBySource"
      },
      pipelineBySourceType: {
        keys: ["Proprietary", "Investment Bank / Advisor", "PE/VC firm", "Incubator / Accelerator"],
        values: [44, 28, 35, 18],
        indicator: "Pipeline by Source Type",
        uniqueId: "pipelineBySourceType"
      },

      // TODO: remove these charts if they are not needed
      pipelineByCountryMultiYear: {
        values: [
          { period: "2021", stack: "Others", value: 11 },
          { period: "2021", stack: "Philippines", value: 11 },
          { period: "2021", stack: "Indonesia", value: 36 },
          { period: "2021", stack: "Vietnam", value: 32 },
          { period: "2022", stack: "Others", value: 11 },
          { period: "2022", stack: "Philippines", value: 11 },
          { period: "2022", stack: "Indonesia", value: 38 },
          { period: "2022", stack: "Vietnam", value: 34 },
          { period: "2023", stack: "Others", value: 14 },
          { period: "2023", stack: "Philippines", value: 14 },
          { period: "2023", stack: "Indonesia", value: 46 },
          { period: "2023", stack: "Vietnam", value: 41 },
          { period: "2024", stack: "Others", value: 15 },
          { period: "2024", stack: "Philippines", value: 15 },
          { period: "2024", stack: "Indonesia", value: 50 },
          { period: "2024", stack: "Vietnam", value: 45 },
          { period: "2025", stack: "Others", value: 18 },
          { period: "2025", stack: "Philippines", value: 18 },
          { period: "2025", stack: "Indonesia", value: 60 },
          { period: "2025", stack: "Vietnam", value: 54 }
        ],
        indicator: "Pipeline by Country - Multi-Year",
        uniqueId: "pipelineByCountryMultiYear"
      },
      pipelineBySectorPerMonth: {
        values: [
          { period: "Jan-24", stack: "Others", value: 1 },
          { period: "Jan-24", stack: "Climate Resillience", value: 1 },
          { period: "Jan-24", stack: "Sustainable Foods", value: 2 },
          { period: "Jan-24", stack: "Healthcare", value: 1 },
          { period: "Jan-24", stack: "Education", value: 2 },
          { period: "Feb-24", stack: "Others", value: 2 },
          { period: "Feb-24", stack: "Climate Resillience", value: 2 },
          { period: "Feb-24", stack: "Sustainable Foods", value: 1 },
          { period: "Mar-24", stack: "Others", value: 1 },
          { period: "Mar-24", stack: "Climate Resillience", value: 2 },
          { period: "Mar-24", stack: "Sustainable Foods", value: 2 },
          { period: "Mar-24", stack: "Education", value: 1 },
          { period: "Apr-24", stack: "Sustainable Foods", value: 1 },
          { period: "Apr-24", stack: "Healthcare", value: 1 },
          { period: "Apr-24", stack: "Education", value: 2 },
          { period: "May-24", stack: "Others", value: 1 },
          { period: "May-24", stack: "Healthcare", value: 2 },
          { period: "May-24", stack: "Education", value: 2 }
        ],
        indicator: "Pipeline by Sector - Per Month",
        uniqueId: "pipelineBySectorPerMonth"
      },
      pipelineByCountryAndSector: {
        values: [
          { period: "Other Countries", stack: "Others", value: 1 },
          { period: "Other Countries", stack: "Climate Resillience", value: 2 },
          { period: "Other Countries", stack: "Sustainable Foods", value: 3 },
          { period: "Other Countries", stack: "Healthcare", value: 4 },
          { period: "Other Countries", stack: "Education", value: 5 },

          { period: "Philippines", stack: "Others", value: 1 },
          { period: "Philippines", stack: "Climate Resillience", value: 2 },
          { period: "Philippines", stack: "Sustainable Foods", value: 4 },
          { period: "Philippines", stack: "Healthcare", value: 2 },
          { period: "Philippines", stack: "Education", value: 5 },

          { period: "Indonesia", stack: "Others", value: 5 },
          { period: "Indonesia", stack: "Climate Resillience", value: 12 },
          { period: "Indonesia", stack: "Sustainable Foods", value: 10 },
          { period: "Indonesia", stack: "Healthcare", value: 13 },
          { period: "Indonesia", stack: "Education", value: 11 },

          { period: "Vietnam", stack: "Others", value: 2 },
          { period: "Vietnam", stack: "Climate Resillience", value: 12 },
          { period: "Vietnam", stack: "Sustainable Foods", value: 12 },
          { period: "Vietnam", stack: "Healthcare", value: 8 },
          { period: "Vietnam", stack: "Education", value: 10 },
        ],
        indicator: "Pipeline by Country and Sector",
        uniqueId: "pipelineByCountryAndSector"
      },

      colorSchema: ["#add82c", "#2d7d51", "#153a67", "#2a76fa"],
      isLoading: false,
    };
  },
  methods: {
    transformKeyValues(data) {
      if (!data) return { keys: [], values: [] };
      return {
        keys: [...Object.keys(data)] || [],
        values: [...Object.values(data)] || []
      }
    },
    async getDealsAggregatedInfoByInvestorId() {
      this.isLoading = true;
      try {
        const response = await this.immAPI.getDealsAggregatedInfoByInvestorId();
        const { data } = response;
        this.pipelineByStatus = {...this.pipelineByStatus, ...this.transformKeyValues(data.status)};
        this.pipelineByStages = {...this.pipelineByStages, ...this.transformKeyValues(data.stage)};
        this.pipelineByTransactionLead = {...this.pipelineByTransactionLead, ...this.transformKeyValues(data.transactionLead)};
        this.pipelineByCountry = {...this.pipelineByCountry, ...this.transformKeyValues(data.country)};
        this.pipelineBySector = {...this.pipelineBySector, ...this.transformKeyValues(data.sector)};
        this.pipelineByAnnualRevenue = {...this.pipelineByAnnualRevenue, ...this.transformKeyValues(data.annualRevenue)};
        this.pipelineByAnnualEBITDA = {...this.pipelineByAnnualEBITDA, ...this.transformKeyValues(data.annualEBITDA)};
        this.pipelineByGenderImpactTarget = {...this.pipelineByGenderImpactTarget, ...this.transformKeyValues(data.genderImpact)};
        this.pipelineBySDGCategory = {...this.pipelineBySDGCategory, ...this.transformKeyValues(data.sdgsImpact)};
        this.pipelineByWomenOwnership = {...this.pipelineByWomenOwnership, ...this.transformKeyValues(data.womenOwnershipLead)};
        this.pipelineBySource = {...this.pipelineBySource, ...this.transformKeyValues(data.source)};
        this.pipelineBySourceType = {...this.pipelineBySourceType, ...this.transformKeyValues(data.sourceType)};
      } catch (error) {
        console.error("Error fetching deals aggregated info:", error);
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    await this.getDealsAggregatedInfoByInvestorId();
  }
};
</script>

<template>
  <div>
    <div
      v-if="isLoading"
      class="flex items-center justify-center h-full mt-24"
    >
      <clip-loader
        :color="env.brandingColors.teal"
        :size="'60px'"
        class=""
        style="transform: translate(-50%, -50%)"
      ></clip-loader>
    </div>
    <template v-else>
      <div class="charts-container">
        <PlotDonutChart :data="this.pipelineByStages" :color-schema="this.colorSchema" :title="this.pipelineByStages.indicator" />
        <PlotDonutChart :data="this.pipelineByStatus" :color-schema="this.colorSchema" :title="this.pipelineByStatus.indicator" />
        <PlotPieChart :data="this.pipelineByTransactionLead" :color-schema="this.colorSchema" :title="this.pipelineByTransactionLead.indicator" />
        <PlotPieChart :data="this.pipelineByCountry" :color-schema="this.colorSchema" :title="this.pipelineByCountry.indicator" />
        <PlotPieChart :data="this.pipelineBySector" :color-schema="this.colorSchema" :title="this.pipelineBySector.indicator" />
      </div>

      <div class="divider"></div>
      <h3 class="title-section">Company Information</h3>
      <div class="charts-container">
        <PlotDonutChart :data="this.pipelineByAnnualRevenue" :color-schema="this.colorSchema" :title="this.pipelineByAnnualRevenue.indicator"/>
        <PlotBarChart :data="this.pipelineByAnnualEBITDA" :color-schema="this.colorSchema" :title="this.pipelineByAnnualEBITDA.indicator" />
        <PlotBarChart :data="this.pipelineByFundraisingAmount" :color-schema="this.colorSchema" orientation="horizontal" :title="this.pipelineByFundraisingAmount.indicator" />
      </div>

      <div class="divider"></div>
      <h3 class="title-section">Impact Information</h3>
      <div class="charts-container">
        <PlotDonutChart :data="this.pipelineByGenderImpactTarget" :color-schema="this.colorSchema" :title="this.pipelineByGenderImpactTarget.indicator" />
        <PlotDonutChart :data="this.pipelineBySDGCategory" :color-schema="this.colorSchema" :title="this.pipelineBySDGCategory.indicator" />
        <PlotPieChart :data="this.pipelineByWomenOwnership" :color-schema="this.colorSchema" :title="this.pipelineByWomenOwnership.indicator" />
      </div>

      <div class="divider"></div>
      <h3 class="title-section">Source Information</h3>
      <div class="charts-container">
        <PlotDonutChart :data="this.pipelineBySource" :color-schema="this.colorSchema" :title="this.pipelineBySource.indicator" />
        <PlotDonutChart :data="this.pipelineBySourceType" :color-schema="this.colorSchema" :title="this.pipelineBySourceType.indicator" />
    </div>
    </template>
  </div>
</template>

<style scoped>
.title-section {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #21191D;
}

.charts-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;

  .chart-container {
    width: 325px;
    height: 300px;
  }
}

.divider {
  border: none;
  height: 2px;
  background-color: rgba(229, 231, 235, 1);
  margin: 2rem 0;
  width: 100%;
}
</style>
