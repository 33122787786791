<template>
  <div class="flex flex-row w-full bg-gray-100">
    <sidebar></sidebar>
    <!--TABLE-->
    <div class="flex flex-col px-10 py-10 w-full" style="padding-top: 100px">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div class="bg-white shadow overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 pb-5 border-b border-gray-200 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Your team
              </h3>
              <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                Team details.
              </p>
            </div>
            <div class="mt-5">
              <dl>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Organization name
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="Team Equilo"
                      v-model="organization.name"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Department/unit
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="Department"
                      v-model="organization.department"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Country
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <v-select
                      v-model="organization.countryId"
                      :options="countryList"
                      :reduce="(countryList) => countryList._id"
                      class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm w-full font-medium focus:outline-none focus:shadow-outline"
                      placeholder="Select Country"
                      label="name"
                    ></v-select>
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Street
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="Equality road"
                      v-model="organization.street"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Street number
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="1"
                      v-model="organization.houseNumber"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Postal code
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="WC2N 5DU"
                      v-model="organization.zipcode"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    City name
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="London"
                      v-model="organization.city"
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                  Your Entity
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      type="text"
                      class="border-2 border-gray-200 bg-gray-100 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      placeholder="London"
                      v-model="userEntityType"
                      disabled
                    />
                  </dd>
                </div>
                <div
                  class="bg-gray-50 px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt
                    class="text-sm leading-5 font-medium text-gray-500 team-label"
                  >
                    Your Subscription
                  </dt>
                  <dd
                    class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                  >
                    <input
                      v-if="subscriptionInfo?.productArr?.length == 0"
                      type="text"
                      class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                      value="Equilo Open"
                      readonly
                      style="color: black"
                    />

                    <span
                      v-else
                      v-for="subscriptionInfo in subscriptionInfo?.productArr"
                      v-bind:key="subscriptionInfo._id"
                    >
                      <input
                        type="text"
                        class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm w-full"
                        v-model="subscriptionInfo.subscription"
                        readonly
                        style="color: black"
                      />
                    </span>
                    <button
                      v-if="
                        user.admin &&
                        user.organization.stripeCustomerId &&
                        user.organization.subscriptionStatus == 'Subscribed'
                      "
                      @click="redirectforcustomerportal()"
                      style="
                        background-color: #fe5000;
                        color: white !important;
                        cursor: pointer;
                      "
                      class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4 ml-4"
                    >
                      Subscriptions
                    </button>
                    <button
                      v-else-if="
                        user.admin &&
                        !user.organization.stripeCustomerId &&
                        user.organization.subscriptionStatus != 'Subscribed'
                      "
                      @click="redirecttoupgrade()"
                      style="background-color: #fe5000; color: white !important"
                      class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4"
                    >
                      <span>Upgrade</span>
                    </button>
                    <button
                      v-else-if="
                        user.admin &&
                        !user.organization.stripeCustomerId &&
                        user.organization.subscriptionStatus == 'Subscribed'
                      "
                      @click="redirectToEquilo()"
                      style="background-color: #fe5000; color: white !important"
                      class="text-white px-3 py-2 text-xs gap-1 font-bold rounded-lg text-white transition duration-200 shadow flex flex-row items-center justify-center mr-4"
                    >
                      <span>Upgrade/Manage Billing</span>
                    </button>
                  </dd>
                </div>
              </dl>
              <button
                @click="update"
                class="text-white my-5 mr-5 py-2 px-4 rounded float-right"
                style="background-color: rgb(32, 23, 71)"
              >
                Update team
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from "@/components/sidebar.vue";
import auth from "@/helpers/auth";
const axios = require("axios");
import Cookies from "@/helpers/cookies";
import env from "@/../env.js";
import Countries from "@/helpers/countries";
import { ORGANIZATION_TYPES } from "@/helpers/constants";

export default {
  name: "Overview",
  data: function () {
    return {
      user: {},
      organization: {},
      countries: [],
      countryList: [],
      subscriptionInfo: [],
      organizationTypeList: ORGANIZATION_TYPES,
    };
  },
  components: {
    sidebar,
  },
  methods: {
    update: function () {
      const _self = this;
      _self.$swal.fire({
        html: '<div class="flex flex-col item-center justify-center" style="align-items:center;"><div class="loader"></div><h1  class="text-2xl tracking-tighter font-semibold text-center mt-10"  style=" color: #2BD4C4 !important;"> Just a moment... </h1> <h1 class="text-md tracking-tighter font-medium text-center"  style=" color: #2BD4C4 !important;" > We are hacking equality. </h1> </div>',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      let countryName = "";
      if (this.organization.countryId) {
        let filter_country = this.countryList.filter(
          (item) => item._id == this.organization.countryId
        );
        if (filter_country && filter_country.length > 0) {
          countryName = filter_country[0].name;
        }
      }

      let update_data = {};
      update_data.name = this.organization.name;
      update_data.countryName = countryName;
      update_data.subscription = this.organization.subscription;
      update_data.department = this.organization.department;
      update_data.street = this.organization.street;
      update_data.houseNumber = this.organization.houseNumber;
      update_data.zipcode = this.organization.zipcode;
      update_data.city = this.organization.city;
      update_data.countryId = this.organization.countryId;

      axios
        .put(
          env.apiUrl + "/organizations/" + this.organization._id,
          update_data,
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(async function (res) {
          if (res.data.error) {
            _self.$swal.fire({
              icon: "error",
              text: "Orgnization already exist with this name.",
              showConfirmButton: true,
            });
          } else {
            _self.$swal.fire({
              icon: "success",
              text: "Your organization has been saved.",
              showConfirmButton: true,
            });
            document.location.reload();
          }
        });
    },
    getCountry() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/countries", {
            headers: {
              Authorization: env.staticToken,
            },
          })
          .then(function (res) {
            const uniqueCountry = [
              ...new Map(res.data.data.map((m) => [m.id, m])).values(),
            ];
            resolve(uniqueCountry);
          });
      });
    },
    getSubscriptions() {
      return new Promise(function (resolve) {
        axios
          .get(env.apiUrl + "/subscriptions/get-subscription-info", {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          })
          .then(function (res) {
            resolve(res.data.data);
          });
      });
    },
    redirecttoupgrade() {
      this.$router.push({ path: "/upgrade" });
    },
    redirectforcustomerportal() {
      axios
        .post(
          env.apiUrl + "/payments/create-customer-portal-session",
          { stripeCustomerId: this.user.organization.stripeCustomerId },
          {
            headers: {
              Authorization: "Bearer " + Cookies.get("session"),
            },
          }
        )
        .then(function (res) {
          window.location.href = res.data.data.url;
        });
    },
    redirectToEquilo() {
      window.open("https://www.equilo.io/general-contact", "_blank");
    },
  },
  async created() {
    this.user = await auth.me().catch(() => {});
    this.organization = this.user.organization;
    this.subscriptionInfo = await this.getSubscriptions();

    this.$store.commit("downloadable", false);
    this.$store.commit("isFreeState", true);
    this.countries = Countries.countries;
    this.$store.commit("setBreadcrumbs", [
      { name: "Settings", url: "/settings/account" },
      { name: this.organization.name, url: "/settings/team" },
    ]);
  },
  async mounted() {
    this.countryList = await this.getCountry();
  },
  computed: {
    userEntityType() {
      return this.organizationTypeList.find(
        (type) => type.code === this.user.interest
      )?.name || "";
    }
  },
};
</script>
<style>
.change_plan {
  padding-top: 0.5rem;
  font-weight: bold;
  color: #290047;
}
.team-label {
  padding-top: 20px;
}
.session-button {
  font-weight: bold;
  border: 2px solid #290047;
  width: 250px;
  float: left;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 2px;
}
</style>
