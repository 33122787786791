import axios from "axios";
import Cookies from "@/helpers/cookies";
import env from "@/../env";


const commonHeaders = () => {
  return {
    headers: {
      Authorization: "Bearer " + Cookies.get("session"),
      "Content-Type": "application/json"
    }
  };
};

const newMessage = async (userMessage, isNewConversation = false) => {
  let context = null;
  if (window.location.href.includes("/gbv-risk/")) {
    context = document.getElementById("principal-route-view").innerText;
  }

  return await axios.post(
    `${env.apiUrl}/chat/newmessage`,
    { message: userMessage, context: context, isNewConversation: isNewConversation },
    commonHeaders()
  );
};

const getChatHistoryTitles = async () => {
  return await axios.get(`${env.apiUrl}/chat/history-title`, commonHeaders());
};

const getChatHistoryDetails = async (conversationID) => {
  return await axios.get(`${env.apiUrl}/chat/history-details/${conversationID}`, commonHeaders());
};

const uploadFiles = async (files, isNewConversation) => {
  let endpoint = "/chat/upload-files";
  let form = new FormData();

  Array.from(files).forEach((file, index) => {
    form.append(`files[${index}]`, file);
  });

  form.append("isNewConversation", isNewConversation);

  return axios.post(`${env.apiUrl}${endpoint}`, form, {
    headers: {
      Authorization: `Bearer ${Cookies.get("session")}`,
      "Content-Type": "multipart/form-data"
    }
  });
};

const listFiles = async (conversationID) => {
  return axios.get(
    `${env.apiUrl}/chat/list-files/${conversationID}`,
    commonHeaders()
  );
};

export default {
  newMessage,
  getChatHistoryTitles,
  getChatHistoryDetails,
  uploadFiles,
  listFiles,
};
