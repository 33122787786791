<template>
  <div class="static">
    <div class="main-container mb-20">
      <BreadCrumbs class="breadcrumbs-main-container"></BreadCrumbs>
      <div class="page-title-section flex justify-between mt-6">
        <div class="page-title">Finance Insights</div>
        <div class="[ page-figures ][ flex flex-row items-center gap-2 ]">
          <span>Currency</span>
          <div class="imm-select">
            <select
              id="period"
              name="period"
              v-model="selectedCurrency"
            >
              <option
                v-for="currency in currencyOptions"
                :key="currency"
                :value="currency"
              >
                {{ currency }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="periods.length > 0" class="[ imm-select ][ mt-4 ]">
        <select id="period" name="period" v-model="selectedPeriod">
          <option v-for="period in periods" :key="period.label" :value="period">
            {{ period.label }}
          </option>
        </select>
      </div>
      <div class="sales-table-section mt-6">
        <sales-table-updated
          :ytdData="ytdData"
          :monthlyData="monthlyData"
          :year="selectedPeriod?.label?.split(' ')?.[0] ?? ''"
          :month="selectedPeriod?.label?.split(' ')?.[1] ?? ''"
          :isInvestor="false"
          :display-comment="false"
        />
      </div>

      <div class="statement-table-section mt-10">
        <div class="time-filter-section flex justify-between">
          <div class="filter-options-section flex">
            <div class="filter-options flex">
              <div
                class="cursor-pointer"
                :class="{ active: groupBy === 'Month' }"
                @click="handleOnChangeGroupBy('Month')"
              >
                By months
              </div>
              <div
                class="cursor-pointer"
                :class="{ active: groupBy === 'Quarter' }"
                @click="handleOnChangeGroupBy('Quarter')"
              >
                By quarters
              </div>
              <div
                class="cursor-pointer"
                :class="{ active: groupBy === 'Year' }"
                @click="handleOnChangeGroupBy('Year')"
              >
                By year
              </div>
              <div
                class="cursor-pointer"
                :class="{ active: groupBy === 'All time' }"
                @click="handleOnChangeGroupBy('All time')"
              >
                By All Times
              </div>
            </div>
            <div v-if="groupBy !== 'All time'" class="imm-select">
              <select id="year" name="year" v-model="year">
                <option
                  v-for="option in yearOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="download-button">
            <button class="flex">
              Customize and download the report
              <img src="@/assets/images/2x_certify/download-navy-blue.svg" />
            </button>
          </div>
        </div>
        <div class="statement-content-section mt-6">
          <div class="mt-11">
            <div
              class="statement-heading flex justify-between"
              :class="{ 'is-fixed fixed-header': fixedComprehensiveSection }"
            >
              Statement of comprehensive income
              <img
                v-if="isComprehensiveTableVisible"
                @click="
                  isComprehensiveTableVisible = !isComprehensiveTableVisible
                "
                src="@/assets/images/arrow-up.svg"
              />
              <img
                v-if="!isComprehensiveTableVisible"
                @click="
                  isComprehensiveTableVisible = !isComprehensiveTableVisible
                "
                src="@/assets/images/arrow-down.svg"
              />
            </div>
            <div ref="comprehensiveSection">
              <StatementTable
                v-show="isComprehensiveTableVisible"
                class="mt-4"
                :heading="comprehensiveIncomeTableData.heading ?? []"
                :tableData="comprehensiveIncomeTableData.tableData ?? []"
                :isInvestor="false"
                :display-comment="false"
                :table-type="'IS - Actual'"
              />
            </div>
          </div>
          <div class="mt-11">
            <div
              class="statement-heading flex justify-between"
              :class="{ 'is-fixed fixed-header': fixedFinancialSection }"
            >
              Statement of financial position
              <img
                v-if="isFinancialTableVisible"
                @click="isFinancialTableVisible = !isFinancialTableVisible"
                src="@/assets/images/arrow-up.svg"
              />
              <img
                v-if="!isFinancialTableVisible"
                @click="isFinancialTableVisible = !isFinancialTableVisible"
                src="@/assets/images/arrow-down.svg"
              />
            </div>
            <div ref="financialSection">
              <StatementTable
                v-show="isFinancialTableVisible"
                class="mt-4"
                :heading="financialPositionTableData.heading ?? []"
                :tableData="financialPositionTableData.tableData ?? []"
                :isInvestor="false"
                :display-comment="false"
                :table-type="'BS - Actual'"
              />
            </div>
          </div>
          <div class="mt-11">
            <div
              class="statement-heading flex justify-between"
              :class="{ 'is-fixed fixed-header': fixedCashFlowSection }"
            >
              Statement of cash flow
              <img
                v-if="isCashFlowTableVisible"
                @click="isCashFlowTableVisible = !isCashFlowTableVisible"
                src="@/assets/images/arrow-up.svg"
              />
              <img
                v-if="!isCashFlowTableVisible"
                @click="isCashFlowTableVisible = !isCashFlowTableVisible"
                src="@/assets/images/arrow-down.svg"
              />
            </div>
            <div ref="cashFlowSection">
              <StatementTable
                v-show="isCashFlowTableVisible"
                class="mt-4"
                :heading="cashFlowTableData.heading ?? []"
                :tableData="cashFlowTableData.tableData ?? []"
                :isInvestor="false"
                :display-comment="false"
                :table-type="'CF - Actual'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumbs from "@/components/new-breadcrumbs.vue";
import StatementTable from "@/components/imm-components/tables/statement-table.vue";
import moment from "moment/moment";
import SalesTableUpdated from "@/components/imm-components/tables/sales-table-updated.vue";
import { useUserStore } from "@/stores/user";
import ImmAPI from "@/api/ImmAPI";

export default {
  name: "AggregatedPortfolioFinance",
  setup() {
    const userStore = useUserStore();
    const immAPI = new ImmAPI();
    return { immAPI, userStore };
  },
  data() {
    return {
      isComprehensiveTableVisible: true,
      isFinancialTableVisible: true,
      isCashFlowTableVisible: true,
      isMetricesSectionVisible: true,
      isProjectionSectionVisible: true,
      fixedComprehensiveSection: false,
      fixedFinancialSection: false,
      fixedCashFlowSection: false,
      fixedMetricesSection: false,
      fixedProjectionSection: false,
      ytdData: {},
      monthlyData: {},
      year: null,
      yearOptions: [],
      groupBy: "Quarter",
      periods: [],
      selectedPeriod: null,
      comprehensiveIncomeTableData: [],
      financialPositionTableData: [],
      cashFlowTableData: [],
      currencyOptions: ["USD"],
      selectedCurrency: "USD",
    };
  },
  components: {
    // SalesTable,
    BreadCrumbs,
    StatementTable,
    SalesTableUpdated,
  },
  async mounted() {
    this.user = this.userStore.user;
    if (!this.user) {
      await this.userStore.fetchUser();
      this.user = this.userStore.user;
    }

    // Populate periods
    try {
      const periodsResponse = await this.immAPI.getIMMFinancePeriods(
        this.user?.organization?._id
      );
      this.periods = periodsResponse.data;
      this.selectedPeriod = this.periods[0] ?? null;
    } catch (error) {
      console.error("Error fetching finance periods:", error);
    }

    // Populate currency options
    const team = this.user?.organization ?? {};
    const teamCurrency = team?.currencyPreference ?? "USD";
    if (teamCurrency !== "USD") {
      this.currencyOptions.push(teamCurrency);
      this.selectedCurrency = teamCurrency;
    }

    // Populate year options - from 2019 to current year (descending)
    for (let i = new Date().getFullYear(); i >= 2019; i--) {
      this.yearOptions.push({
        value: i,
        text: i,
      });
    }
    this.year = this.yearOptions[0].value;

    window.addEventListener("scroll", this.handleScroll);
    // TODO: is this ok?
    this.$store.commit("setNewBreadcrumbs", [
      { name: "Dashboard", url: "/imm/landing-page" },
      { name: "Aggregated portfolio", url: "/imm/aggregate-portfolio" },
      { name: "Finance", url: "/imm/aggregate-portfolio/finance" },
    ]);

    await this.handleOnChangeGroupBy(this.groupBy);
  },
  methods: {
    handleScroll() {
      if (this.$refs.comprehensiveSection.getBoundingClientRect()?.top <= 122) {
        this.fixedComprehensiveSection = true;
        this.fixedFinancialSection = false;
        this.fixedCashFlowSection = false;
        this.fixedMetricesSection = false;
        this.fixedProjectionSection = false;
      } else {
        this.fixedComprehensiveSection = false;
      }
      if (this.$refs.financialSection.getBoundingClientRect()?.top <= 122) {
        this.fixedComprehensiveSection = false;
        this.fixedFinancialSection = true;
        this.fixedCashFlowSection = false;
        this.fixedMetricesSection = false;
        this.fixedProjectionSection = false;
      } else {
        this.fixedFinancialSection = false;
      }
      if (this.$refs.cashFlowSection.getBoundingClientRect()?.top <= 122) {
        this.fixedComprehensiveSection = false;
        this.fixedFinancialSection = false;
        this.fixedCashFlowSection = true;
        this.fixedMetricesSection = false;
        this.fixedProjectionSection = false;
      } else {
        this.fixedCashFlowSection = false;
      }
    },
    processActualData(data, itemsHeader = "") {
      let result = {
        heading: [],
        tableData: [],
      };

      // Getting the row headings
      const rowHeadings = [];
      const firstItem = data?.data?.[0]?.data ?? {};
      for (const key in firstItem) {
        const name = firstItem[key]?.title ?? key;
        rowHeadings.push({
          key: key,
          name: name,
        });
      }

      // Getting the column headings
      const columnHeading = [itemsHeader];
      const items = data?.data ?? [];
      const tableData = [];
      for (const item of items) {
        console.log('item header', item);
        if (this.groupBy === "Month") {
          columnHeading.push(
            moment(item.endPeriod, "DD/MM/YYYY").format("MMMM").substring(0, 3)
          );
        } else {
          columnHeading.push(item?.period || item?.endPeriod);
        }

        const periodData = item.data;
        for (const rowHeading of rowHeadings) {
          const data = periodData[rowHeading.key];
          const row = tableData.find(
            (item) => item.rowHeading === rowHeading.name
          );

          let value = "";
          let diffPercent = "";
          let diffAmount = "";
          let className = "";

          if (data) {
            value = Number.isNaN(+data.value) ? "" : +data.value;
            diffPercent = Number.isNaN(+data.percentage) ? "" : +data.percentage;
            diffAmount = Number.isNaN(+data.difference) ? "" : +data.difference;
            className = typeof value !== "string" && value < 0 ? "red" : "";
          }

          if (row) {
            row.data.push({
              value: value,
              diffPercent: diffPercent,
              diffAmount: diffAmount,
              isVisible: false,
              class: className,
            });
          } else {
            tableData.push({
              rowHeading: rowHeading.name,
              data: [{
                value: value,
                diffPercent: diffPercent,
                diffAmount: diffAmount,
                isVisible: false,
                class: className
              }]
            });
          }
        }
      }

      // Adding information to results
      result.heading = columnHeading;
      result.tableData = tableData;

      return result;
    },
    async getActualData(year, groupBy, currency) {
      try {
        const response = await this.immAPI.getActualData(year, groupBy, this.user?.organization?._id, currency);
        const { data } = response.data;
        const comprehensiveIncomeTableData = data.find(
          (item) => item.sheetName === "IS - Actual"
        );
        const financialPositionTableData = data.find(
          (item) => item.sheetName === "BS - Actual"
        );
        const cashFlowTableData = data.find(
          (item) => item.sheetName === "CF - Actual"
        );

        this.comprehensiveIncomeTableData = this.processActualData(
          comprehensiveIncomeTableData
        );
        this.financialPositionTableData = this.processActualData(
          financialPositionTableData
        );
        this.cashFlowTableData = this.processActualData(cashFlowTableData);
      } catch (error) {
        console.error(error);
      }
    },
    async getFinanceTableData(year, month, currency) {
      try {
       const response = await this.immAPI.getImmFinanceTableData(year, month, this.user?.organization?._id, currency);
        const { data } = response.data;
        this.ytdData = data?.YTD ?? {};
        this.monthlyData = data?.Monthly ?? {};
      } catch (error) {
        console.error(error);
      }
    },
    async handleOnChangeGroupBy(groupBy) {
      this.groupBy = groupBy;
      await this.getActualData(this.year, groupBy, this.selectedCurrency);
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    async selectedPeriod(newVal) {
      if (newVal && newVal.year && newVal.month) {
        await this.getFinanceTableData(newVal.year, newVal.month, this.selectedCurrency);
      }
    },
    async year(newVal) {
      await this.getActualData(newVal, this.groupBy, this.selectedCurrency);
    },
    async selectedCurrency(newVal) {
      await this.getFinanceTableData(this.selectedPeriod.year, this.selectedPeriod.month, newVal);
      await this.getActualData(this.year, this.groupBy, newVal);
    }
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  .fixed-header {
    position: fixed;
    width: 91.6%;
    margin-top: 64px;
    padding: 20px 0;
    z-index: 1000;
    transition: top 0.3s ease;
    display: flex;
    justify-content: space-between;
    height: 90px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 68%,
      rgba(255, 255, 255, 0) 83%
    );
  }

  .is-fixed {
    top: 0;
  }

  margin-left: 80px;
  margin-right: 80px;

  .breadcrumbs-main-container {
    margin-top: 100px;
  }

  .page-title-section {
    height: 45px;

    .page-title {
      font-family: Montserrat;
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      color: #2c2e35;
    }

    .page-figures {
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: #717686;
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  .sales-table-section {
  }

  .statement-table-section {
    .time-filter-section {
      .filter-options-section {
        gap: 32px;

        .filter-options {
          height: 32px;
          background: #f4f6f9;
          border-radius: 8px;
          font-family: Arial;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #9ea4ba;

          div {
            padding: 6px 12px 6px 12px;
          }

          .active {
            background: #2177c1;
            border-radius: 8px;
            color: #ffffff;
          }
        }

        .select-year {
          select {
            height: 32px;
            padding: 0 12px;
            border-radius: 8px;
            border: 1px solid #e1e3e9;
            gap: 8px;
            color: #2c2e35;
          }
        }
      }

      .download-button {
        button {
          padding: 8px 16px 8px 16px;
          border-radius: 8px;
          border: 1px solid #191b45;
          gap: 6px;
          font-family: Arial;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          color: #191b45;
        }
      }
    }

    .statement-content-section {
      .statement-heading {
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #2c2e35;

        img {
          width: 32px;
          height: 32px;
          cursor: pointer;
        }
      }

      .metrices-section {
        .charts-section {
          .line-chart-section {
            border: 1px solid #e1e3e9;
            border-radius: 16px;

            .line-chart-heading {
              //styleName: Body2 Bold;
              font-family: Arial;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: #2c2e35;
            }
          }
        }
      }
    }
  }

  .imm-select {
    select {
      height: 32px;
      width: fit-content;
      padding-right: 25px;
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
      border-radius: 8px;
      border: 1px solid #e1e3e9;
      gap: 8px;
      color: #2c2e35;

      font-family: Arial, serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0;
    }
  }
}
</style>
