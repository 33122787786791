var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('UnderConstructionBanner',{attrs:{"is-visible":true}}),_c('div',{staticClass:"mt-8"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"flex gap-8 h-10"},[_c('div',{staticClass:"flex bg-[#f4f6f9] rounded-lg cursor-pointer"},[_c('div',{class:[
              'px-3 py-[6px] text-sm font-arial',
              {
                'bg-[#2177c1] rounded-lg text-white font-bold':
                  _vm.selectedFrequency === 'All',
              },
            ],on:{"click":function($event){_vm.selectedFrequency = 'All'}}},[_vm._v(" All ")]),_c('div',{class:[
              'px-3 py-[6px] text-sm font-arial   ',
              {
                'bg-[#2177c1] rounded-lg text-white font-bold':
                  _vm.selectedFrequency === 'Quarterly',
              },
            ],on:{"click":function($event){_vm.selectedFrequency = 'Quarterly'}}},[_vm._v(" Quarters ")]),_c('div',{class:[
              'px-3 py-[6px] text-sm font-arial ',
              {
                'bg-[#2177c1] rounded-lg text-white font-bold':
                  _vm.selectedFrequency === 'Annually',
              },
            ],on:{"click":function($event){_vm.selectedFrequency = 'Annually'}}},[_vm._v(" Annual ")])]),_vm._m(0)])])]),_c('div',{staticClass:"bg-gray-100 rounded-xl overflow-hidden p-4 flex justify-between mt-4"},[_c('div',{staticClass:"flex flex-col gap-2"},[_c('h2',[_vm._v(_vm._s(_vm.portfolioName))]),_c('p',{staticClass:"target-goal"},[_vm._v("Portfolio description")])]),_vm._m(1)]),_c('div',{staticClass:"grid grid-cols-2 gap-6 mt-10"},_vm._l((_vm.modules),function(card,index){return _c('ModuleCard',_vm._b({key:index,attrs:{"frequency":card.frequency,"title":card.title,"description":card.description,"aggregatedView":true,"aggregatedUrl":card.aggregatedUrl}},'ModuleCard',card,false))}),1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center"},[_c('select',{staticClass:"px-3 py-[6px] bg-[#f4f6f9] rounded-lg text-sm font-arial cursor-pointer focus:outline-none w-20",attrs:{"name":"year","id":"year"}},[_c('option',{attrs:{"value":"2023"}},[_vm._v("2024")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"},[_vm._v(" Performance ")]),_c('button',{staticClass:"focus:outline-none mx-2 my-2 bg-[#191B45] transition duration-150 ease-in-out font-bold hover:opacity-75 rounded text-white px-6 py-3 text-base rounded-lg opacity-50 cursor-not-allowed"},[_vm._v(" Edit preferences ")])])
}]

export { render, staticRenderFns }