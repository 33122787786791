<template>
  <div class="flex flex-col items-start min-h-screen p-10">
    <div class="w-[250px] flex justify-start items-center">
      <img src="@/assets/images/logo-navy.png" alt="background-image" />
    </div>
    <div class="flex flex-col items-center justify-center w-full h-[400px]">
      <div v-if="loading" class="text-lg font-semibold">Loading...</div>
      <div v-else class="text-center">
        <div v-if="error" class="text-red-500">
          Your token is invalid. Please contact support
        </div>
        <div v-else-if="!accepted">
          <p class="text-gray-700 text-xl mb-10">
            Accept your invite clicking in the button below
          </p>
          <button
            class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
            @click="acceptInvite"
          >Accept invite</button>
        </div>
        <div v-else>
          <p class="text-gray-700 text-xl mb-10">Your invite has been accepted</p>
          <p class="text-gray-700 text-xl mb-10">
            Redirecting to the signup page...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImmPreferenceAPI from "@/api/ImmPreferenceAPI";

export default {
  name: "IMMInvite",
  setup() {
    const immPreferenceAPI = new ImmPreferenceAPI();
    return { immPreferenceAPI };
  },
  data() {
    return {
      accepted: false,
      loading: true,
      error: false,
      redirectURL: '/#/signup'
    };
  },
  async mounted() {
    await this.fetchData();
    if (this.accepted) {
      setTimeout(() => {
        window.location.href = this.redirectURL;
      }, 3000);
    }
  },
  methods: {
    async fetchData() {
      const token = this.$route.params.token;
      try {
        const response = await this.immPreferenceAPI.getInvesteeInvitesVerifyToken(token);
        const { data, status } = response;
        if (status === 200) {
          this.accepted = data.data.accepted;
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    async acceptInvite() {
      const token = this.$route.params.token;
      try {
        const response = await this.immPreferenceAPI.putAcceptInvite(token);
        const { status } = response;

        if (status === 200) {
          this.accepted = true;
          const self = this;
          setTimeout(() => {
            window.location.href = self.redirectURL;
          }, 3000);
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped></style>
