<script>
import { createPieChart } from "@/components/D3-Charts/generateCharts";

export default {
  name: "plot-pie-chart",
  props: {
    data: {
      type: Object,
      required: true,
    },
    colorSchema: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      id: this.data.uniqueId,
    };
  },
  mounted() {
    const transformedData = this.transformData();
    createPieChart(`#chart-${this.id}`, transformedData, 300, 200, this.colorSchema, this.title);
  },
  methods: {
    transformData() {
      return this.data.keys.map((period, index) => ({
        value: this.data.values[index],
        label: period,
      }));
    },
  },
};
</script>

<template>
  <div :id="`chart-${this.id}`" class="w-[300px]"></div>
</template>

<style scoped>

</style>
